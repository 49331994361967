import { CarritoService } from './../../servicios/carrito.service';
import { Component, OnInit } from '@angular/core';
import { Libro } from './../../modulos/libro.model';
import { ColeccionService } from 'src/app/servicios/coleccion.service';
import { Coleccion } from 'src/app/modulos/coleccion.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Categoria } from 'src/app/modulos/categoria.model';
import { kitService } from 'src/app/servicios/kit.service';
import { Servicio } from 'src/app/modulos/servicio.model';
import { Service } from 'aws-sdk';

@Component({
  selector: 'app-kit-recu',
  templateUrl: './kit-recu.component.html',
  styleUrls: ['./kit-recu.component.scss']
})
export class KitRecuComponent implements OnInit {

  constructor(private kitService: kitService,
    private route: ActivatedRoute,
    private router: Router,
    private carrito: CarritoService
    ) { }

  servicios: Servicio[] = [];
  servicio: Servicio;
  errorMessage = '';
  carritoLleno: Servicio[] = [];

  ngOnInit(): void {
    this.getkits();
  }

  getkits() {
    this.kitService.getAllkits().subscribe({
      next: servicio => {
        this.servicios = servicio;
        // console.log(this.servicios);
      },
      error: err => this.errorMessage = err
    });
  }

  agregarAlCarrito(idkit: number){
    // this.book = libro;
    this.kitService.getkit(idkit).subscribe({
      next: product => {
        if (product !== undefined){
          this.servicio = product;
          this.carritoLleno.push(this.servicio);
          this.carrito.sendKitCarrito(this.carritoLleno);
          //console.log(this.book);
        }
      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    });
    //this.carrito.addItem(libro, tipo);
  }
}
