import { Component, OnInit } from '@angular/core';
import { Coleccion } from 'src/app/modulos/coleccion.model';
import { ColeccionService } from 'src/app/servicios/coleccion.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  colecciones: Coleccion [];

  constructor(private coleccionServicio: ColeccionService) { }

  ngOnInit(): void {
    this.coleccionServicio.getAllColeccion().subscribe(
      res => {
        this.colecciones = res;
      },
      err => console.log(err)
    );
  }

}
