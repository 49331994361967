
<!--<app-side-bar></app-side-bar>-->
<div class="row">
   <app-side-bar></app-side-bar>
    <div class="col-sm-9">
        <h1 style="text-align: center;">Listado de libros</h1>
        <div class="row">
            <div class="col-md-11 col-md-offset-11">
                <a routerLink="/saled/newbook"><button type="button" class="btn btn-md btn-primary">Añadir</button></a>
                &nbsp;&nbsp;&nbsp;<a><button type="button" class="btn btn-md btn-success" (click)="refrescararchivos()">Refrescar</button></a>
            </div>
            <div class="col-md-4">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Buscar..." [(ngModel)]="listFilter">
                <span class="input-group-btn"></span>
              </div>
            </div><!--Boton de buscar-->
        </div>

        <br>
        <div class="table-responsive">
            <table class="table" >
                <tr>
                    <td>
                        Título
                    </td>
                    <td>
                        Autor(es)
                    </td>
                    <td>
                        Categotría
                    </td>
                    <td>
                        Colección
                    </td>
                    <td>
                        Precio
                    </td>
                    <td>
                        Tipo
                    </td>
                    <td>
                        Rango de edad
                    </td>
                    <td>
                        Editar/Eliminar
                    </td>
                </tr>
                <tr *ngFor = 'let lib of libsFiltered'>
                    <td>{{lib.titulo}}</td>
                    <td>{{lib.autores}}</td>
                    <td>{{lib.cat}}</td>
                    <td>{{lib.col}}</td>
                    <td>{{lib.precio}}</td>
                    <td>{{lib.tipo}}</td>
                    <td>{{lib.rangoedad}}</td>
                    <td><a><img src="/assets/img/delete.png" [routerLink]="['/newbook',lib.idlibro]"></a> <a><img src="/assets/img/edit.png" (click)="eliminarBook(lib)"> </a></td>
                 </tr>
            </table>
        </div>
    </div><!--/.main-->
</div>
