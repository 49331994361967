import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Servicio } from 'src/app/modulos/servicio.model';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistroService } from './../../../servicios/registro.service';
import { AdministuserService } from 'src/app/servicios/administuser.service';
import { Usuario } from 'src/app/modulos/user.model';
import { Institucion } from './../../../modulos/institucion.model';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { Libro } from 'src/app/modulos/libro.model';
import { MisRecursosService } from 'src/app/servicios/mis-recursos.service';
import { log } from 'console';


@Component({
  selector: 'app-newuser',
  templateUrl: './newuser.component.html',
  styleUrls: ['./newuser.component.scss']
})
export class NewuserComponent implements OnInit {

  id: 0;
  usuario: Usuario;
  idusuario = 0;
  errorMessage = '';

  servicios: Servicio[] = [];

  existeUsuario = true;
  books: Libro [] = [];


  institucion: Institucion;
  ingresado = false;
  modificado = false;
  eliminado = false;

  /*modifiedIstitucion: Institucion =
  {
    Idinstitucion: 0,
    Nombre: '',
    Provincia: '',
    Canton: '',
    Parroquia: ''
  };*/

  modifiedUsuario: Usuario =
  {
    idusuario: 0,
    nombres: '',
    apellidos: '',
    correo: '',
    contrasena: '',
    direccion: '',
    telefono: '',
    fechanacimiento: '',
    tipo: '',
    habilitado: false,
    listaservicios: '',
    nivel: '',
    idinstitucion: 0
  };

  usuarioaux: Usuario =
  {
    idusuario: 0,
    nombres: '',
    apellidos: '',
    correo: '',
    contrasena: '',
    direccion: '',
    telefono: '',
    fechanacimiento: '',
    tipo: '',
    habilitado: false,
    listaservicios: '',
    nivel: '',
    idinstitucion: 0
  };

  constructor(private registroService: RegistroService,
              private route: ActivatedRoute,
              private administuserServicio: AdministuserService,
              private formBuilder: FormBuilder,
              private misrecursos: MisRecursosService
              ) { }

  ngOnInit(): void {
    const param = this.route.snapshot.paramMap.get('id');
    if (param) {
      const id = +param;
      this.getUser(id);
      this.idusuario = +param;
      this.usuarioaux.idusuario = this.idusuario;
      this.getLibros();
    }
  }

  getLibros() {
    this.misrecursos.getLibrosAutorizadosxUsuario(this.usuarioaux).subscribe({
        next: t=>{
            this.books = t;
            console.log(this.books);
            
        }
        ,
        error: t=>console.log(t)
    })
}

  getUser(id: number) {
    this.administuserServicio.getUser(id).subscribe({
      next: usuario => this.usuario = usuario,
      error: err => this.errorMessage = err
    });
    //this.existecoleccion = true;
    console.log(this.usuario);
  }
  guardar(){
    if((<HTMLInputElement> document.getElementById('nombres')).value !== '' &&
    (<HTMLInputElement> document.getElementById('apellidos')).value !== '' &&
    (<HTMLInputElement> document.getElementById('correo')).value !== '' &&
    (<HTMLInputElement> document.getElementById('direccion')).value !== '' &&
    (<HTMLInputElement> document.getElementById('telefonouser')).value !== '' &&
    (<HTMLInputElement> document.getElementById('birthdateuser')).value !== '' &&
    (<HTMLSelectElement> document.getElementById('tipouser')).value !== '' &&
    (<HTMLSelectElement> document.getElementById('niveluser')).value !== ''){
      this.modifiedUsuario.nombres = (<HTMLInputElement> document.getElementById('nombres')).value;
      this.modifiedUsuario.apellidos = (<HTMLInputElement> document.getElementById('apellidos')).value;
      this.modifiedUsuario.correo = (<HTMLInputElement> document.getElementById('correo')).value;
      this.modifiedUsuario.direccion = (<HTMLInputElement> document.getElementById('direccion')).value;
      this.modifiedUsuario.telefono = (<HTMLInputElement> document.getElementById('telefonouser')).value;
      this.modifiedUsuario.fechanacimiento = (<HTMLInputElement> document.getElementById('birthdateuser')).value;
      this.modifiedUsuario.tipo = (<HTMLInputElement> document.getElementById('tipouser')).value;
      this.modifiedUsuario.nivel = (<HTMLInputElement> document.getElementById('niveluser')).value;
      console.log(this.modifiedUsuario);

      if(this.existeUsuario){
        this.modifiedUsuario.idusuario = this.idusuario;
        this.modificarUsuario(this.modifiedUsuario);


      }else{
        //this.ingresarnueva(this.modifiedcoleccion);


      }
    }else{
        alert("Por favor ingrese los valores de la nueva colección");
    }
  }

    modificarUsuario(usuario: Usuario){
      this.registroService.modificarUsuario(usuario).subscribe(
        res => {
         if(res[0].json != undefined){
           this.modificado = res[0].json;
           if(this.modificado === true){
            alert("Se ha modificado la coleccion con éxito.");
            this.modificado = false;
          }else{
            alert("No se ha modificado la colección");
          }
         }
        }
      )
     }
  }




