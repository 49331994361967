<div class="container">
  <div class="row">
    <h1>Detalles de compra</h1>
  </div>
  <div class="row">
    <div class="limiter">
      <div class="container-table100">
        <div class="wrap-table100">
          <div class="table100">
            <table>
              <thead>
                <tr class="table100-head">
                  <th class="column1">Imagen</th>
                  <th class="column3">Titulo</th>
                  <th class="column4">Precio</th>
                  <th class="column5">Cantidad</th>
                  <th class="column6">Total</th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let item of this.pedido">
                    <td class="column1"><img [src]="item?.Urlimagen" [alt]="item?.Titulo"></td>
                    <td class="column3">{{item?.Titulo}}</td>
                    <td class="column4">{{item?.Precio}}</td>
                    <td class="column5">1</td>
                    <td class="column6">{{item?.Precio}}</td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <div class="row" #paypal>
  </div>
</div>
