<div class="container">
    <div *ngIf="categoria">
        <p><strong><h1 style="text-align: center; font-family: Comic Sans MS, Comic Sans, cursive;">{{categoria?.nombre}}</h1></strong></p>
        <img [src]="categoria?.urlimagen2" alt="{{categoria?.nombre}}" style="width: 100%; height: 70%;">
        <p style="font-size: x-large; text-align: justify; font-family: Comic Sans MS, Comic Sans, cursive; margin-top: 35px; margin-bottom: 35px;">{{categoria?.descripcion}}<br><br><h2 style="font-family: Comic Sans MS, Comic Sans, cursive;"><strong>Conoce nuestros libros!</strong></h2></p>
    </div>
    <div class="col-md-4 col-sm-4" style="min-height: 530px;" *ngFor="let book of categoria?.libros; let i = index">
        <div class="thumbnail">
          <div style="text-align: center;">
            <h4><strong>{{book.titulo}}</strong></h4>
          </div>
          <img [src]="book.urlimagen" alt="{{book.titulo}}" width="200px" class="card-image" style="max-height: 135px;">
          <div class="caption">
            <p><strong>Autores: </strong>{{book.autores}}</p>
            <p><strong>Tipo: </strong>{{book.tipo}}</p>
            <p><strong>Rango Edad: </strong>{{book.rangoedad}}</p>
            
          </div>
          <div class="buttons">
            <a [routerLink]="['/product',book?.idlibro]" class="btn btn-warning" role="button">Detalles</a>
            <h5>Añadir al carrito:</h5>
            <button class="btn btn-primary" type="button" (click)="agregarAlCarrito(book?.idlibro, 'Fisico')">Fisico</button>
            <button class="btn btn-success" type="button" (click)="agregarAlCarrito(book?.idlibro, 'Digital')">Digital</button>
          </div>
        </div>
    </div>
</div>
<div class="red">
  <a class="fa fa-whatsapp" href="https://api.whatsapp.com/send/?phone=593967776678" target="_blank"></a>
</div>


