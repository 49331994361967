<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="myModalLabel">¿Quieres comprar nuestros libros?</h4>
      </div>
      <form [formGroup]="userCredentials" >
      <div class="modal-body">
        <label for="inputHelpBlock">Inicia sesión para poder acceder al contenido</label>
          <div *ngIf="(email.invalid && email.touched) || email.dirty">
            <small *ngIf="email.errors?.required" class="text-danger">Este campo es requerido</small>
            <!--question mark(?) is a safe navigation operator-->
            <small *ngIf="email.errors?.pattern" class="text-danger">Por favor, ingresa un correo válido</small>
          </div>
          <div class="form-group">
            <input [class.is-invalid] = "userCredentials.get('email').invalid && userCredentials.get('email').touched" type="email" class="form-control" name="email" formControlName="email" id="exampleInputEmail1" placeholder="Correo Electónico">
          </div>
          <div class="form-group">
            <input type="password" class="form-control" name="password" formControlName="password" id="exampleInputPassword1" placeholder="Contraseña">
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" #botonCerrar>Cerrar</button>
        <button type="button" class="btn btn-primary" type="submit" style="cursor: pointer;" (click)="login()">Ingresar</button>
      </div>
    </form>
    </div>
  </div>
</div>
