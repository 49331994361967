<!--hero-->
<section id="hero">
    <div class="hero-container" data-aos="zoom-in" data-aos-delay="100">
    </div>
  </section>
<!--carrousel-->
<app-carousel></app-carousel>
<br><br><br>
<!--magicas-->
<section  style="background-color: rgba(25, 134, 235, 0.089);">
  <!-- Container -->
  <div class="container">
      <!-- row -->
      <div class="row justify-content-between align-items-center">
          <div class="col-md-6">
                  <br><br><br><br><br>
                  <img src="assets/img/mg1.png" style="width: 100%; height: 100%; border-radius: 10px;"><br><br>
                  <div class="button" routerLink="/catalogo/1" id="btnmagicas">Ver mas...</div>
          </div>
          <div class="col-md-6"><br><br><br>
              <h2 style="font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;"><strong>Colección Mágicas Travesuras</strong></h2>
              
              <p style="text-align: justify; font-size: medium; font-weight: 450; font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;">Mágicas Travesuras es una herramienta pedagógica que sirve para potenciar procesos de aprendizaje mediante estrategias metodológicas lúdicas correspondientes a las características propias de los niños que están en los niveles Inicial y Preparatoria. Esta colección busca satisfacer necesidades e intereses de los niños, docentes y padres de familia, con procesos de integralidad en la formación y educación de los párvulos. Los textos están elaborados, diseñados e ilustrados considerando las herramientas y estrategias de los niveles Inicial y Preparatoria para fortalecer las acciones del desempeño docente en el aula, fundamentadas en el diseño curricular vigente. ¡Acompáñanos y descubre nuestros libros!</p>
              
          </div>
      </div><br><br><br><br><br>
      <!-- End of Row -->
  </div>  
  <!-- End of Container -->
</section><!-- End of Section -->   
  <!--libros destacados-->
<div style="margin-top: 3%;">
    <h1 style="text-align: center; font-family: Comic Sans MS, Comic Sans, cursive;">
        Libros Destacados
    </h1><br>
</div>
<div class="row" style= "margin-right: 8px; margin-left: 8px; margin-top: 1%; margin-bottom: 3%;">
    <div class="col-md-3">
        <img src="/assets/img/bolundun3.png" class="img-responsive" alt="Responsive image" style="width: 100%; height: 85%; padding-left: 30px;">
        <div>
            <h4 style="text-align: center; font-family: Comic Sans MS, Comic Sans, cursive;">
              Bolundun 
            </h4>
        </div>
    </div>
    <div class="col-md-3">
        <img src="/assets/img/MT 3-4 años.png" class="img-responsive" alt="Responsive image">
        <div>
            <h4 style="text-align: center; font-family: Comic Sans MS, Comic Sans, cursive;"><br>
                Mágicas Travesuras 3-4 años
            </h4>
        </div>
    </div>
    <div class="col-md-3">
        <img src="/assets/img/misterio de la casa de al lado.png" class="img-responsive" alt="Responsive image" style="width: 75%; height: 86%; padding-left: 50px; padding-top: 30px;">
        <div>
            <h4 style="text-align: center; font-family: Comic Sans MS, Comic Sans, cursive;"><br>
              El misterio de la casa de al lado
            </h4>
        </div>
    </div>
    <div class="col-md-3">
        <img src="/assets/img/MT 4-5 años.png" class="img-responsive" alt="Responsive image" style="width: 100%; height: 45%;">
        <div>
            <h4 style="text-align: center; font-family: Comic Sans MS, Comic Sans, cursive;"><br>
              Mágicas Travesuras 4-5 años
            </h4>
        </div>
    </div>
</div>
<!--leo-->
<section  style="background-color: rgba(46, 85, 156, 0.144);">
  <!-- Container -->
  <div class="container">
      <!-- row -->
      <div class="row justify-content-between align-items-center">
          <div class="col-md-6"><br><br><br>
            <h2 style="font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;"><strong>Colección Leo León</strong></h2>
            <p style="text-align: justify; font-size: medium; font-weight: 450; font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;">En Salazar Editores concebimos a la lectura como un proceso de construcción de significados que debe ser iniciado lo más temprano posible en los seres humanos, por parte de padres, maestros y otros mediadores. Propugnamos una lectura gozosa de textos verbales y no verbales, fundamentada en las más altas calidades literarias, gráficas y editoriales. Al contar en la actualidad con un plan implementado desde el Ministerio de Cultura, traemos a ustedes la colección literaria Leo León, destinada a lectores de todas las edades, con obras de excelencia, autores nacionales e internacionales y una calidad gráfica que rompe esquemas. Estamos seguros que nuestros libros pueden constituir sin ningún problema un Plan Lector adecuado a las necesidades de cualquier nivel.</p>
          </div>
          <div class="col-md-6">
            <br><br><br><br><br>
            <img src="assets/img/ll1.png" style="width: 100%; height: 100%; border-radius: 10px;"><br><br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<div class="button" routerLink="/catalogo/2" id="btnmagicas">Ver mas...</div>
          </div>
      </div><br><br><br><br><br>
      <!-- End of Row -->
  </div>  
  <!-- End of Container -->
</section><!-- End of Section -->
<!--/leo-->

<section id="reproductor">
  <div class="container">
    <div class="col-md-3">

    </div>
    <div class="col-md-3">
      <br><br><br><br>
      <video width="560" height="320" controls>
        <source src="/assets/img/Leo León antiguo_Trim.mp4" type="video/mp4">
    </video>
    </div>
    <div class="col-md-3">
      
    </div>
  </div>
</section>
  <!--Autores-->
  <section id="team">
    <div class="container" data-aos="fade-up">
      <div class="section-header">
        <h1 class="section-title" style="text-align: center; font-family: Comic Sans MS, Comic Sans, cursive;"><strong>Nuestros Autores</strong></h1>
        <h4 class="section-description" style="text-align: center; color: gray; font-family: Comic Sans MS, Comic Sans, cursive;">Algunos de nuestros mas destacados autores</h4><br>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="member" data-aos="fade-up" data-aos-delay="100">
            <div class="pic"><img src="/assets/img/rosalia.jpeg" alt=""></div>
            <h4 style="font-family: Comic Sans MS, Comic Sans, cursive;">Rosalía Arteaga</h4>
            <span style="font-family: Comic Sans MS, Comic Sans, cursive;">Licenciada en Ciencias Políticas, Doctora en Derecho y Máster en Educación Básica y Rescate de valores Culturales.</span>
            <div class="social">
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="member" data-aos="fade-up" data-aos-delay="200">
            <div class="pic"><img src="assets/img/henry.jpg" alt="" style="height: 265px;"></div>
            <h4 style="font-family: Comic Sans MS, Comic Sans, cursive;">Henry Bax</h4>
            <span style="font-family: Comic Sans MS, Comic Sans, cursive;">Es el pseudónimo de Galo Silva, quien nació en Quito, en 1966, y fue desde niño un lector voraz.</span>
            <div class="social">
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="member" data-aos="fade-up" data-aos-delay="300">
            <div class="pic"><img src="assets/img/oswaldo.jpg" style="height: 265px;" alt=""></div>
            <h4 style="font-family: Comic Sans MS, Comic Sans, cursive;">Oswaldo Encalada</h4>
            <span style="font-family: Comic Sans MS, Comic Sans, cursive;">Narrador, ensayista, crítico literario e investigador. Es Doctor en Filología y Especialista en Docencia Universitaria.</span>
            <div class="social">
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="member" data-aos="fade-up" data-aos-delay="400">
            <div class="pic"><img src="assets/img/ana.jpg" alt=""></div>
            <h4 style="font-family: Comic Sans MS, Comic Sans, cursive;">Ana Carlota González</h4>
            <span style="font-family: Comic Sans MS, Comic Sans, cursive;">Filóloga y escritora de libros para niños y jóvenes, becaria de la Biblioteca Internacional de la Juventud en Munich.</span>
            <div class="social">
            </div>
          </div>
        </div>
        
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="member" data-aos="fade-up" data-aos-delay="100">
            <div class="pic"><img src="/assets/img/edna1.jpg" alt=""></div>
            <h4 style="font-family: Comic Sans MS, Comic Sans, cursive;">Edna Iturralde</h4>
            <span style="font-family: Comic Sans MS, Comic Sans, cursive;">Es no solo una de las más destacadas escritoras del país, sino la pionera de la etnohistoria narrativa en la literatura infantil y juvenil del Ecuador.</span>
            <div class="social">
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="member" data-aos="fade-up" data-aos-delay="200">
            <div class="pic"><img src="assets/img/jorge.jpg" style="height: 256px;" alt=""></div>
            <h4 style="font-family: Comic Sans MS, Comic Sans, cursive;">Jorge Dávila Vázquez</h4>
            <span style="font-family: Comic Sans MS, Comic Sans, cursive;">Es uno de los más notables escritores contemporáneos del Ecuador. Ha ganado los premos literarios más importantes de su país.</span>
            <div class="social">
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="member" data-aos="fade-up" data-aos-delay="300">
            <div class="pic"><img src="assets/img/franc1.jpg" alt=""></div>
            <h4 style="font-family: Comic Sans MS, Comic Sans, cursive;">Francisco Delgado</h4>
            <span style="font-family: Comic Sans MS, Comic Sans, cursive;">Escritor ecuatoriano y promotor de la literatura infantil, con más de 50 títulos publicados. Creó el Sistema Nacional de Bibliotecas Públicas del Ecuador.</span>
            <div class="social">
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="member" data-aos="fade-up" data-aos-delay="400">
            <div class="pic"><img src="assets/img/leonor1.jpg" alt=""></div>
            <h4 style="font-family: Comic Sans MS, Comic Sans, cursive;">Leonor Bravo Velásquez</h4>
            <span style="font-family: Comic Sans MS, Comic Sans, cursive;">Es una de las figuras mas notables de la literatura ecuatoriana para niños y jóvenes. Estudió Bellas Artes y se convirtió en una ilustradora sobresaliente.</span>
            <div class="social">
            </div>
          </div>
        </div>

        
      </div>
    </div>
  </section>
  <div class="red">
    <a class="fa fa-whatsapp" href="https://api.whatsapp.com/send/?phone=593967776678" target="_blank"></a>
  </div>
  

  