<div class="container">
    <br><br><br>
    <div class="row">
        <div class="col-md-6 col-sm-6">
            <div class="box">
                <img src="/assets/img/ilustracion_magicas.jpg" alt="Magicas travesuras">
                <div class="box-overlay"></div>
                <div class="box-content">
                    <div class="inner-content">
                        <h3 class="title">Colección Mágicas Travesuras</h3>
                        <ul class="icon">
                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-6">
            <p style="text-align: justify; font-size: large; font-family: Comic Sans MS, Comic Sans, cursive;">
                Mágicas Travesuras es una herramienta pedagógica que sirve para potenciar procesos de aprendizaje mediante estrategias metodológicas lúdicas correspondientes a las características propias de los niños que están en los niveles Inicial y Preparatoria. Esta colección busca satisfacer necesidades e intereses de los niños, docentes y padres de familia, con procesos de integralidad en la formación y educación de los párvulos. Los textos están elaborados, diseñados e ilustrados considerando las herramientas y estrategias de los niveles Inicial y Preparatoria para fortalecer las acciones del desempeño docente en el aula, fundamentadas en el diseño curricular vigente. ¡Acompáñanos y descubre nuestros libros!
            </p>
        </div>
    </div>
    <br><br><br><div class="row">
        <div class="col-md-6 col-sm-6">
            <p style="text-align: justify; font-size: large; font-family: Comic Sans MS, Comic Sans, cursive;">
                En Salazar Editores concebimos a la lectura como un proceso de construcción de significados que debe ser iniciado lo más temprano posible en los seres humanos, por parte de padres, maestros y otros mediadores. Propugnamos una lectura gozosa de textos verbales y no verbales, fundamentada en las más altas calidades literarias, gráficas y editoriales. Al contar en la actualidad con un plan implementado desde el Ministerio de Cultura, traemos a ustedes la colección literaria Leo León, destinada a lectores de todas las edades, con obras de excelencia, autores nacionales e internacionales y una calidad gráfica que rompe esquemas. Estamos seguros que nuestros libros pueden constituir sin ningún problema un Plan Lector adecuado a las necesidades de cualquier nivel.
            </p>
        </div>
        <div class="col-md-6 col-sm-6">
            <div class="box">
                <img src="/assets/img/ilustracion_leo_leon.jpg" alt="Magicas travesuras">
                <div class="box-overlay"></div>
                <div class="box-content">
                    <div class="inner-content">
                        <h3 class="title">Colección Leo León</h3>
                        <ul class="icon">
                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>