import { Component, OnInit } from '@angular/core';
import { Libro } from './../../modulos/libro.model';
import { ProductService } from 'src/app/servicios/product.service';
import { ColeccionService } from 'src/app/servicios/coleccion.service';
import { Coleccion } from 'src/app/modulos/coleccion.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Categoria } from 'src/app/modulos/categoria.model';

@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoComponent implements OnInit {

  coleccion: Coleccion | undefined;
  id: number;
  param = '';
  errorMessage = '';
  page: number = 1;
  totalPages = 3;
  isLoaded: boolean = false;


  books: Libro[] = [];
  //errorMessage = '';
  indice: number;
  book: Libro = {
    idlibro: 0,
    titulo: '',
    autores: '',
    sinopsis: '',
    tipo: '',
    precio: 0,
    urlimagen: '',
    urllibropdf: '',
    destacado: false,
    rangoedad: '',
    categoria: 0,
    coleccion: 0
    }

  categorias: Categoria[];

  constructor(private productService: ProductService,
    public coleccionService: ColeccionService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.param = this.route.snapshot.paramMap.get('id');
    if (this.param) {
      const id = +this.param;
      this.getColeccion(id);
      this.getCategoria(id);
      //this.getLibros(id)
    }
  }

  getColeccion(id: number) {
    this.coleccionService.getColeccion(id).subscribe({
      next: coleccion => this.coleccion = coleccion,
      error: err => this.errorMessage = err
    });
    console.log(this.coleccion?.categorias);
  }

  getCategoria(idcoleccion: number) {
    this.categorias = this.coleccion?.categorias;
    console.log(this.categorias);
  }

  getLibros(idcategoria: number) {
    this.books = this.categorias[idcategoria]?.libros;
  }

}
