import { Component, OnInit } from '@angular/core';
import { Video } from 'src/app/modulos/video.model';
import { VideoService } from 'src/app/servicios/videos.service';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {

  videos: Video[] = [];
  eliminado = false;
  videosFiltered: Video[] = [];

  _listFilter: string;
  get listFilter(): string {
    return this._listFilter;
  }
  set listFilter(value: string) {
    this._listFilter = value;
    this.videosFiltered = this.listFilter ? this.performFilter(this.listFilter) : this.videos;
  }
  constructor(private videosServicio: VideoService) { }

  ngOnInit(): void {
    this.getVideos();
  }

  performFilter(filterBy: string): Video[] {
    filterBy = filterBy.toLocaleLowerCase();
    return this.videos.filter((video: Video) =>
          video.nombrelibro.toLocaleLowerCase().indexOf(filterBy) !== -1);
  }

  refrescararchivos(){
    this.getVideos();
  }

  getVideos(){
    this.videosServicio.getVideos().subscribe(
      res => {
        if(res[0].json != undefined){
          this.videos = res[0].json;
          this.videosFiltered =this.videos;
        }
      },
      err => console.log(err)
    )
  }

  eliminarVideo(video: Video){
    this.videosServicio.eliminarVideo(video).subscribe(
      res => {
        if(res[0].json != undefined){
          this.eliminado = res[0].json;
          if(this.eliminado === true){
            alert("Video eliminado con éxito.");
            this.eliminado = false;
            this.getVideos();
          }else{
            alert("No se ha podido eliminar");
          }
        }
      }
    )
  }

  }

