import { CarritoService } from './../../servicios/carrito.service';
import { Component, OnInit } from '@angular/core';
import { Libro } from './../../modulos/libro.model';
import { ProductService } from 'src/app/servicios/product.service';
import { ColeccionService } from 'src/app/servicios/coleccion.service';
import { Coleccion } from 'src/app/modulos/coleccion.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Categoria } from 'src/app/modulos/categoria.model';


@Component({
  selector: 'app-catag',
  templateUrl: './catag.component.html',
  styleUrls: ['./catag.component.scss']
})
export class CatagComponent implements OnInit {

  books: Libro[] = [];
  categoria: Categoria;
  id: number;
  errorMessage = '';
  carritoLleno: Libro[] = [];
  private book: Libro = {
    idlibro: 0,
    titulo: '',
    autores: '',
    sinopsis: '',
    tipo: '',
    precio: 0,
    urlimagen: '',
    urllibropdf: '',
    destacado: false,
    rangoedad: '',
    categoria: 0,
    coleccion: 0
  };

  constructor(private productService: ProductService,
    public coleccionService: ColeccionService,
    private route: ActivatedRoute,
    private router: Router,
    private carrito: CarritoService) { }

  ngOnInit() {
    const param = this.route.snapshot.paramMap.get('id');
    if (param) {
      const id = +param;
      this.getCategoria(id);
      //this.getLibros(id);
    }

  }

  getCategoria(id: number) {
    this.coleccionService.getCategoria(id).subscribe({
      next: categoria => {
        this.categoria = categoria;
        //console.log(this.categoria?.libros);
      },
      error: err => this.errorMessage = err
    });
  }

  agregarAlCarrito(idlibro: number, tipo: string){
    // this.book = libro;
    this.productService.getBook(idlibro).subscribe({
      next: product => {
        if (product !== undefined){
          this.book = product;
          this.book.tipo = tipo;
          this.carritoLleno.push(this.book);
          this.carrito.sendLibroCarrito(this.carritoLleno);
          //console.log(this.book);
        }
      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    });
    //this.carrito.addItem(libro, tipo);
  }

}
