import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Archivo } from 'src/app/modulos/archivo.model';
import { ArchivoService } from 'src/app/servicios/archivo.service';

@Component({
  selector: 'app-archivos',
  templateUrl: './archivos.component.html',
  styleUrls: ['./archivos.component.scss']
})
export class ArchivosComponent implements OnInit {

  archivos: Archivo[] = [];
  eliminado = false;
  archivosFiltered: Archivo[] = [];

  _listFilter: string;
  get listFilter(): string {
    return this._listFilter;
  }
  set listFilter(value: string) {
    this._listFilter = value;
    this.archivosFiltered = this.listFilter ? this.performFilter(this.listFilter) : this.archivos;
  }

  constructor(private archivoServicio: ArchivoService) { }

  ngOnInit(): void {
    this.getArchivos();
  }

  performFilter(filterBy: string): Archivo[] {
    filterBy = filterBy.toLocaleLowerCase();
    return this.archivos.filter((archivo: Archivo) =>
          archivo.nombre.toLocaleLowerCase().indexOf(filterBy) !== -1);
  }

  refrescararchivos(){
    this.getArchivos();
  }
  getArchivos(){
    this.archivoServicio.getArchivos().subscribe(
      res => {
        if(res[0].json != undefined){
          this.archivos = res[0].json;
          this.archivosFiltered = this.archivos;
        }
      },
      err => console.log(err)
    )
  }

  eliminarArchivo(archivo: Archivo){
    this.archivoServicio.eliminarArchivo(archivo).subscribe(
      res => {
        if(res[0].json != undefined){
          this.eliminado = res[0].json;
          if(this.eliminado === true){
            alert("Archivo eliminado con éxito.");
            this.eliminado = false;
            this.getArchivos();
          }else{
            alert("No se ha podido eliminar");
          }
        }
      }
    )
  }
}
