<section id="details" *ngIf='book' style="padding-bottom: 3%;">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="panel panel-primary"  style="margin: 0;font-family: 'Times New Roman', Times, serif; opacity: 0.9;">
          <div class="panel-heading" (click)="onBack()" style="cursor: pointer;">
            <h3 class="panel-title"><span class="glyphicon glyphicon-arrow-left"></span> {{book.titulo}}</h3>
          </div>
          <div class="panel-body" style="border-style: outset; border-color: rgba(78, 70, 80, 0.466);">
            <div class="thumbnail" style="margin: 0;font-family: Comic Sans MS, Comic Sans, cursive; opacity: 0.9;  border-color: rgb(37, 72, 170);">
              <img [src]="book.urlimagen" [alt]="book.titulo">
            </div>
            <br>
            <h3 style="text-align: center;"><strong>Autor(es): </strong>{{book.autores}}</h3><br>
            <h4 style="text-align: center;"><strong>Tipo: </strong>{{book.tipo}}</h4><br>
            <h4 style="text-align: center;"><strong>Rango Edad: </strong>{{book.rangoedad}}</h4><br>
            <h4><strong style="font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; text-decoration: underline;">Sinopsis:</strong></h4><div class="container" style="background-color: rgb(231, 231, 231); width: 95%;"><h3 style="color: rgb(0, 0, 0); text-align: justify; padding-right: 20px; padding-left: 20px;">{{book.sinopsis}}</h3></div>
            <br><br>
            <!-- 
            <h4><strong>Vista preliminar</strong></h4>
            <pdf-viewer [src]="book.urllibropdf" [page]="page" [show-all]="false" [zoom-scale]="page-fit" [original-size]="false" (after-load-complete)="afterLoadComplete($event)"></pdf-viewer>
            <div *ngIf="!isLoaded" class="row text-primary" style="text-align: center;">
              <span>Cargando vista previa...</span>
            </div>
            <div *ngIf="isLoaded" style="text-align: center;">
              <button class="btn" (click)="prevPage()" [disabled]="page === 1">Prev</button>
              <span>{{ page }} / {{ totalPages }}</span>
              <button class="btn" (click)="nextPage()" [disabled]="page === totalPages">Next</button>
            </div> -->
            <!--
            <ngx-extended-pdf-viewer 
              [src]="book.urllibropdf"
              [height]="'80vh'"
              [useBrowserLocale]="true"
              [textLayer]="false"
              [showHandToolButton]="true"
              [showDownloadButton]="false"
              [showPrintButton]="false"
              [showOpenFileButton]="false"
              [showPresentationModeButton]="true"
              (pagesLoaded)="afterLoadComplete($event)"
              [page]="page"
              [showPagingButtons]="false"
              (pageChange)="nextPage()"
            >
            </ngx-extended-pdf-viewer>
          -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="red">
  <a class="fa fa-whatsapp" href="https://api.whatsapp.com/send/?phone=593967776678" target="_blank"></a>
</div>