import { Institucion } from './../../modulos/institucion.model';
import { Usuario } from './../../modulos/user.model';
import { RegistroService } from './../../servicios/registro.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ConfirmedValidator } from '../confirmed.validator';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  usuario: Usuario;
  institucion: Institucion;
  userForm: FormGroup;
  erorMessage: string;

  @ViewChild("botonCerrar", { static: false}) botonCerrar: ElementRef;

  constructor(private registroService: RegistroService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      nombres: new FormControl('', [Validators.required]),
      apellidos: new FormControl('', [Validators.required]),
      tipo: new FormControl('', [Validators.required]),
      nivel: new FormControl('', [Validators.required]),
      correo: new FormControl('', [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      telefono: new FormControl('', [Validators.required]),
      fechanacimiento: new FormControl('', [Validators.required]),
      direccion: new FormControl('', [Validators.required]),
      contrasena: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl('', [Validators.required])
    }, {
      validator: ConfirmedValidator('contrasena', 'confirmPassword')
    });
  }

  get Correo(){
    return this.userForm.get('correo');
  }

  get Nombres(){
    return this.userForm.get('nombres');
  }

  get Apellidos(){
    return this.userForm.get('apellidos');
  }

  get Tipo(){
    return this.userForm.get('tipo');
  }

  get Nivel(){
    return this.userForm.get('nivel');
  }

  get Telefono(){
    return this.userForm.get('telefono');
  }

  get Fechanacimiento(){
    return this.userForm.get('fechanacimiento');
  }

  get Direccion(){
    return this.userForm.get('direccion');
  }

  get Contrasena(){
    return this.userForm.get('contrasena');
  }

  get confirmPassword(){
    return this.userForm.get('confirmPassword');
  }

  get f() { return this.userForm.controls; }

  registrar(){
    this.usuario = this.userForm.getRawValue() as Usuario;
    this.registroService.setInstitucion(this.institucion).subscribe({
      next: response => {
          this.institucion = response[0]?.json;
          //this.institucion = institucion;
          console.log(this.institucion);
          if (this.institucion !== undefined ){
            this.usuario.idinstitucion = this.institucion.idinstitucion;
            //console.log(this.usuario);
            this.registroService.setUser(this.usuario).subscribe(
              response => {
                if (response !== undefined){
                  if (response[0]?.status === 302){
                    this.erorMessage = 'El correo ya se encuentra registrado';
                    alert(this.erorMessage);
                  }else if (response[0]?.status === 200){
                    console.log('usuario registrado' + response?.status);
                    this.erorMessage = 'Registro exitoso, por favor inicia sesión con el botón Ingresar!!';
                    alert(this.erorMessage);
                  }
                }
              },
              err => console.log(err)
            );
            this.cerrarModal();

          }
      },
      error: err => {
        this.erorMessage = err;
        console.log(this.erorMessage);
        //return 0;
      }
    });
  }

  getInstitucion(institucion: Institucion){
    this.institucion = institucion;
    /*this.registroService.setInstitucion(institucion).subscribe({
      next: response => {
        institucion = response[0].json;
        this.institucion = institucion;
        console.log(institucion);
      },
      error: err => this.erorMessage = err
    });*/
  }

  /*addInstitucion() {
    //let institucion: Institucion;
    this.registroService.setInstitucion(this.institucion).subscribe(
      response => {
          this.institucion = response[0]?.json;
          //this.institucion = institucion;
          console.log(this.institucion);
          //return institucion;
      },
      err => {
        this.erorMessage = err;
        console.log(this.erorMessage);
        //return 0;
      }
    );
  }*/
  private cerrarModal() {
    this.botonCerrar.nativeElement.click();
  }
}
