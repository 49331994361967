import { Admin } from './../../modulos/admin.model';
import { Router } from '@angular/router';
import { Usuario } from './../../modulos/user.model';
import { LoginService } from './../../servicios/login.service';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  correo: string;
  contrasena: string;
  @Output() usuario = new EventEmitter<Usuario>();
  @Output() admin = new EventEmitter<Admin>();
  datosUsuario: Usuario;
  datosAdmin: Admin;
  token: string;
  errorMessage: '';
  userCredentials = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    password: new FormControl('')
  });

  @ViewChild("botonCerrar", { static: false}) botonCerrar: ElementRef;

  constructor(private loginService: LoginService, private router: Router) { }

  ngOnInit(): void {
  }

  get email(): any{
    return this.userCredentials.get('email');
  }

  get password(): any {
    return this.userCredentials.get('password');
  }

  /*login(form: NgForm){
    const email: form.value.email;
    const password: form.value.password;
  }*/

  login() {
    let data = this.userCredentials.getRawValue();
    //console.log(data);
    this.correo = data.email;
    this.contrasena = data.password;
    this.loginService.getAuth(this.correo, this.contrasena).subscribe({
      next: data => {
        if ( data !== undefined){
          this.datosUsuario = data?.datosusuario;
          this.datosAdmin = data?.datosadmin;
          this.token = data?.token;
          //console.log(this.datosUsuario);
          //console.log(this.datosAdmin);
          //console.log(this.token);
          this.router.navigate(['/tienda']);
          this.cerrarModal();
          if(this.datosUsuario){
            this.usuario.emit(this.datosUsuario);
          }else if(this.datosAdmin){
            this.admin.emit(this.datosAdmin);
          }
          if(!data){
            alert("Usted a iniciado sesion en otro dispositivo");
          }else{
            alert("Ingreso exitoso");
          }
        }
      },
      error: err => {
        this.errorMessage = err;
        alert("Usuario o Contraseña incorrectos");
        console.log(this.errorMessage);
    }});
  }

  private cerrarModal(){
    this.botonCerrar.nativeElement.click();
  }
}
