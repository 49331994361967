import { RegistroService } from './../../servicios/registro.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Institucion } from './../../modulos/institucion.model';
import { ReadingJsonFilesService } from './../../servicios/reading-json-file.service';
import { Component, EventEmitter, OnInit, Output, SimpleChanges } from '@angular/core';


@Component({
  selector: 'app-intituciones',
  templateUrl: './intituciones.component.html',
  styleUrls: ['./intituciones.component.scss']
})
export class IntitucionesComponent implements OnInit {

  provincias: string[];
  cantones: string[];
  parroquias: string[];
  provinciaSeleccionada: string;
  cantonSeleccionado: string;
  parroquiaSeleccionada: string;
  erorMessage = '';
  idInstitucion = {
    "idInstitucion" : 0
  };
  @Output() institucion = new EventEmitter<Institucion>();
  institucionForm = new FormGroup({
    nombre: new FormControl(''),
    provincia: new FormControl(''),
    canton: new FormControl(''),
    parroquia: new FormControl('')
  });

  constructor(private data: ReadingJsonFilesService, private registroService: RegistroService) { }

  ngOnInit(): void {

  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
  //   //Add '${implements OnChanges}' to the class.
  //   this.addInstitucion();
  // }

  unique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  getProvincias(){
    this.provincias = this.data.getProvincias().filter(this.unique).sort();
  }

  getCantones(){
    this.cantones = this.data.getCantones(this.provinciaSeleccionada).filter(this.unique).sort();
  }

  getParroquias(){
    this.parroquias = this.data.getParroquias(this.cantonSeleccionado, this.provinciaSeleccionada).filter(this.unique).sort();
  }

  addInstitucion(){
    let institucion = this.institucionForm.getRawValue() as Institucion;
    //console.log(institucion);
    // this.registroService.setInstitucion(institucion).subscribe({
    //   next: response => {
    //     institucion = response[0].json;
    //     this.institucion.emit(institucion);
    //     console.log(institucion);
    //   },
    //   error: err => this.erorMessage = err
    // });
    this.institucion.emit(institucion);
  }

}
