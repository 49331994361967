import { Account } from './../modulos/account.model';
import { Usuario} from './../modulos/user.model';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseURL } from './baseUrl';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  correo: string;
  pass: string;
  headers: HttpHeaders;
  usuario: Usuario;
  public account: Observable<Account>;
  private accountSubject: BehaviorSubject<Account>;
  token: any;
  clientId: any;
  clientSecret: any;
  refreshedToken: any;

  constructor(private http: HttpClient, private router: Router) {
    this.accountSubject = new BehaviorSubject<Account>(JSON.parse(sessionStorage.getItem('user')));
    this.account = this.accountSubject.asObservable();
  }

  public get accountValue(): Account {
    return this.accountSubject.value;
  }

  getAuth(correo: string, pass: string): Observable<any> {
    /*let body = new HttpParams()
      .set('correo', correo)
      .set('contrasena', pass);*/
    //console.log(body);
    let body = {
      Correo: correo,
      contrasena: pass
    };
    this.headers = new HttpHeaders({
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'Content-Type': 'application/json',
    });
    return this.http.post<any>(baseURL + 'Login/loginUsuario', body, {headers: this.headers}).
      pipe(
        map(
          account => {
            //this.usuario = data as Usuario;
            sessionStorage.setItem('user', JSON.stringify(account));
            this.accountSubject.next(account);
            //this.startRefreshTokenTimer();
            return account;
          }
          ),
        catchError(this.handleError)
      );
  }

  logout(){
    const username = JSON.parse(sessionStorage.getItem('user'))?.datosusuario;
    //const adminUsername = JSON.parse(sessionStorage.getItem('user'))?.datosadmin;
    let body: any;

    if (username !== null){
      body = username;
      this.logoutUser(body).subscribe(
        response => console.log(response),
        err => console.log(err)
      );
    }
    this.accountSubject.next(null);
    sessionStorage.removeItem('user');
    this.router.navigate(['/']);
  }

  logoutUser(body: any): Observable<any> {
    this.headers = new HttpHeaders ({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });

    return this.http.post<any>(baseURL + 'usuario/cerrarsesion', body, {headers: this.headers})
      .pipe(
        tap(
          response => console.log('confirmacion: ' + response)
        ),
        catchError(this.handleError)
      );
  }

  /*refreshToken() {
    return this.http.post<any>(`${baseURL}/Login/LoginUsuario`, {}, { withCredentials: true })
        .pipe(map((account) => {
            this.accountSubject.next(account);
            this.startRefreshTokenTimer();
            return account;
        }));
  }*/

  // helper methods

  //private refreshTokenTimeout;

  /*private startRefreshTokenTimer() {
      // parse json object from base64 encoded jwt token
      const jwtToken = JSON.parse(atob(this.accountValue?.token.split('.')[1]));
      console.log(jwtToken);
      // set a timeout to refresh the token a minute before it expires
      const expires = new Date(jwtToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - (60 * 1000);
      this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }*/

  /*private stopRefreshTokenTimer() {
      clearTimeout(this.refreshTokenTimeout);
  }*/

  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
