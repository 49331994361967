<div class="row">
    <app-side-bar></app-side-bar>
    <div class="col-sm-9">
		<h1 style="text-align: center;">Ingresar/Modificar Informacion del Video</h1>

		<div class="panel panel-default">
			<div class="panel-heading">Formulario de información
			</div>
			<div class="panel-body">
				<div class="col-md-9">
					<form>
						<hr>
						<div>

                            <label>Seleccione una Colección</label>
							<select class="form-control" (change)="onChangecoleccion($event.target.value)">
                                <option hidden selected>Seleccione una Colección</option>
                                <option *ngFor = 'let coleccion of colecciones' [value]="coleccion?.idcolecion">{{coleccion.nombre}}</option>
							</select>
							<br>
							<label>Seleccione una Categoría</label>
							<select class="form-control" (change)="onChangecategoria($event.target.value)" placeholder="Selecione una Categoria">
                                <option *ngFor = 'let categoria of categorias' [value]="categoria?.idcategoria">{{categoria.nombre}}</option>
								<option hidden selected> Elija una categoría</option>
								<!--<option value="0"> Nueva categoría</option>-->
							</select>
							<label>Seleccione un Libro</label>
							<select class="form-control" (change)="onChangelibro($event.target.value)" placeholder="Selecione un Libro">
                                <option *ngFor = 'let libro of libros' [value]="libro?.titulo">{{libro.titulo}}</option>
								<option hidden selected> Elija un Libro</option>
								<!--<option value="0"> Nueva categoría</option>-->
							</select>
							<div class="form-group">
								<label>Nombre</label>
								<input *ngIf="existevideo" class="form-control" id="nuevonombrevid" placeholder="Ingrese el nombre del video" [value]="video?.nombre">
								<input *ngIf="!existevideo" class="form-control" id="nuevonombrevid" placeholder="Ingrese el nombre del video">
								<label>URL video</label>
								<input *ngIf="existevideo" class="form-control" id="nuevaurl" placeholder="Ingrese la URL del video" [value]="video?.urlvideo">
								<input *ngIf="!existevideo" class="form-control" id="nuevaurl" placeholder="Ingrese la URL del video">
								<label>Tipo de recurso</label>
								<input *ngIf="existevideo" class="form-control" id="nuevotiporec" placeholder="Ingrese el tipo de recurso (Ejem: Poesía, Adivinanza,etc)" [value]="video?.tiporecurso">
								<input *ngIf="!existevideo" class="form-control" id="nuevotiporec" placeholder="Ingrese el tipo de recurso (Ejem: Poesía, Adivinanza,etc)">
								<label>Página a la que pertenece el video</label>
								<input *ngIf="existevideo" class="form-control" id="nuevapag" placeholder="Ingrese el número de página a la pertenece el video" [value]="video?.pagina">
								<input *ngIf="!existevideo" class="form-control" id="nuevapag" placeholder="Ingrese el número de página a la pertenece el video">

								<br>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3 col-md-offset-3"></div>
							<div class="col-md-3 col-md-offset-3"><button type="button" class="btn btn-lg btn-danger" (click)="guardarCambios()" routerLink="/saled/videos">Guardar cambios</button></div>
						 </div>

					</form>
				</div>
			</div>
		</div>
	</div>

</div>
