<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/js/bootstrap.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!------ Include the above in your HEAD tag ---------->

<div class="container contact-form">
    <div class="contact-image" id="contact-image1">
        <img src="https://image.ibb.co/kUagtU/rocket_contact.png" alt="rocket_contact"/>
    </div>
    <form [formGroup]="userForm">
        <h2 style="font-family: cursive;">Si tienes alguna inquietud, déjanos un mensaje y solventaremos tus dudas!</h2>
       <div class="row">
            <div class="col-md-6">
                <div *ngIf="(Nombreremitente.invalid && Nombreremitente.touched) || Nombreremitente.dirty">
                    <small *ngIf="Nombreremitente.errors?.required" class="text-danger">Este campo es requerido</small>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" id="names" placeholder="Nombre *" formControlName="Nombreremitente" required="true"/>
                </div>
                <div *ngIf="(CorreoRemitente.invalid && CorreoRemitente.touched) || CorreoRemitente.dirty">
                    <small *ngIf="CorreoRemitente.errors?.required" class="text-danger">Este campo es requerido</small>
                    <!--question mark(?) is a safe navigation operator-->
                    <small *ngIf="CorreoRemitente.errors?.pattern" class="text-danger">Por favor, ingresa un correo válido</small>
                  </div>
                <div class="form-group">
                    <input [class.is-invalid] = "userForm.get('Correoremitente').invalid && userForm.get('Correoremitente').touched" type="email" class="form-control" name="email" formControlName="Correoremitente" id="email" placeholder="Email *"/>
                </div>
                <button type="button" class="btn btn-success" type="submit" style="cursor: pointer;" (click)='enviar()'>Enviar</button>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <textarea class="form-control" formControlName="Mensajebody" placeholder="Mensaje *" style="width: 100%; height: 130px;"></textarea>
                </div>
            </div>
        </div>
    </form>
    <div class="contact-image">
        <img src="/assets/img/logobiengrand.png" class="img-responsive" alt="Responsive image" style="width: 50%; height: 35%; display: block; margin: auto;">
    </div>
</div>
<br><br><br><br><br><br><br>
<h2 style="text-align: center; font-family: cursive;">Nuestra ubicación y contactos</h2>
<div class="row">
    <div class="col-sm-6" style="padding-left: 5%;"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.768107271363!2d-78.53972258569905!3d-0.2874550354320468!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d5989bd9dc60d5%3A0x77147b4a0d3c315!2sSalazar%20Editores!5e0!3m2!1ses!2sec!4v1676217647289!5m2!1ses!2sec" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
    <div class="col-sm-6"><ul class="list-unstyled mb-0">
        <br><br><br><br><br>     
        <li><a class="nav-link" style="text-decoration: none; font-family: cursive;" href="https://www.google.com.ec/maps/place/LIBRER%C3%8DA+Y+PAPELER%C3%8DA+MUNDO+EDUCATIVO/@-0.2881744,-78.5376176,18.86z/data=!4m5!3m4!1s0x91d5989bd8e69e1d:0x1aab393329acfb33!8m2!3d-0.2874696!4d-78.537542?hl=es" target="blank"><i class="fa fa-location-arrow fa-3x">&nbsp;</i>Av. Pedro Vicente Maldonado S29-19 y entrada a San Cristobal, Quito 170605</a></li>
        <br>
        <li><a class="nav-link" style="text-decoration: none; font-family: cursive;" href="https://api.whatsapp.com/send/?phone=593994176258" target="_blank"><i class="fa fa-whatsapp fa-3x">&nbsp;</i>099 417 6258</a></li>
        <br>
        <li><a class="nav-link" style="text-decoration: none; font-family: cursive;" href="https://api.whatsapp.com/send/?phone=593967776678" target="_blank"><i class="fa fa-whatsapp fa-3x">&nbsp;</i>096 777 6678</a></li>
        <br>
        <li><a class="nav-link" style="text-decoration: none; font-family: cursive;" href="#"><i class="fa fa-envelope fa-3x">&nbsp;</i>info@salazareditores.com</a></li>
    </ul></div>
  </div>
  <br>



  <i class="fa-light fa-comment-sms"></i>

  