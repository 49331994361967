import { Component, OnInit } from '@angular/core';
import { Pedido } from 'src/app/modulos/pedido.model';
import { AdministpedidoService } from 'src/app/servicios/administpedido.service';

@Component({
  templateUrl: './ordenes.component.html',
  styleUrls: ['./ordenes.component.scss']
})
export class OrdenesComponent implements OnInit {

  pedidos: Pedido[] = [];
  pedidosFiltered: Pedido[] = [];

  _listFilter: string;
  get listFilter(): string {
    return this._listFilter;
  }
  set listFilter(value: string) {
    this._listFilter = value;
    this.pedidosFiltered = this.listFilter ? this.performFilter(this.listFilter) : this.pedidos;
  }
  constructor(private administpedidoServicio: AdministpedidoService) { }

  entregado= false;

  ngOnInit(): void {
    this.listarpedidos();
  }

  performFilter(filterBy: string): Pedido[] {
    filterBy = filterBy.toLocaleLowerCase();
    return this.pedidos.filter((pedido: Pedido) =>
      pedido.numerocomprobante.toLocaleLowerCase().indexOf(filterBy) !== -1);
  }

  listarpedidos(){
    this.administpedidoServicio.pedirpedido().subscribe(
      res => {
        if(res[0].json != undefined){
          this.pedidos = res[0].json;
          this.pedidosFiltered = this.pedidos;
        }
        //console.log(JSON.stringify(this.libs));

      },
      err => console.log(err)
    )
  }

  entregarpedido(pedido: Pedido){
    console.log(pedido);
    this.administpedidoServicio.entregarpedido(pedido).subscribe(
      res => {
        if(res[0].json != undefined){
          this.entregado = res[0].json;


          if(this.entregado == true){
            alert("El pedido se ha registrado como entregado.");
            this.listarpedidos();
          }else if(this.entregado == false){
            alert("El pedido no se ha registrado como entregado.");
          }
        }
        //console.log(JSON.stringify(this.libs));

      },
      err => console.log(err)
    );
  }

}
