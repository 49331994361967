import { CarritoService } from './../../servicios/carrito.service';
import { ProductService } from 'src/app/servicios/product.service';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Libro } from 'src/app/modulos/libro.model';
import { ColeccionService } from 'src/app/servicios/coleccion.service';
import { Coleccion } from 'src/app/modulos/coleccion.model';
import { Categoria } from 'src/app/modulos/categoria.model';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  book: Libro | undefined;
  id: number;
  errorMessage = '';
  page: number = 1;
  totalPages = 20;
  isLoaded: boolean = false;
  coleccion: Coleccion | undefined;
  categorias: Categoria[];
  

  constructor(private productService: ProductService,
              public coleccionService: ColeccionService,
              private route: ActivatedRoute,
              private router: Router,
              private carritoService: CarritoService) { }

  ngOnInit(): void {
    const param = this.route.snapshot.paramMap.get('id');
    if (param) {
      const id = +param;
      this.getProduct(id);
      this.getColeccion(id);
      this.getCategoria(id);
    }
  }

  getProduct(id: number) {
    this.productService.getBook(id).subscribe({
      next: product => this.book = product,
      error: err => this.errorMessage = err
    });
    //console.log(this.book);

  }

  getColeccion(id: number) {
    this.coleccionService.getColeccion(id).subscribe({
      next: coleccion => this.coleccion = coleccion,
      error: err => this.errorMessage = err
    });
    console.log(this.coleccion?.categorias);
  }

  getCategoria(idcoleccion: number) {
    this.categorias = this.coleccion?.categorias;
    console.log(this.categorias);
  }

  get bookTitle() { return (this.book && this.book.idlibro) ? this.book.idlibro : null ;}

  afterLoadComplete(pdfData: any) {
    //this.totalPages = pdfData.pagesCount;
    //pdfData.pagesCount = this.totalPages;
    this.isLoaded = true;
  }

  nextPage() {
    //if(this.page < this.totalPages)
      this.page++;
    //else
    //  this.page = this.totalPages
  }

  prevPage() {
    if(this.page != 10){
      this.page--;
    }
  }

  onBack(): void{
    this.router.navigate(['/tienda']);
  }
}
