import { tap, catchError, map } from 'rxjs/operators';
import { baseURL } from './baseUrl';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { Servicio } from '../modulos/servicio.model';
import { Asignar } from '../modulos/asignar.model';

@Injectable({
    providedIn: 'root'
  })

  export class AdministkitService {

    headers: HttpHeaders;


  constructor(private http: HttpClient) { }
  /*Obtener los kits del back*/

  pedirkits(): Observable<any>{
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.get<Servicio[]>(baseURL + 'administracion/listarKits', {headers: this.headers, observe: 'response'}).
      pipe(
        map((res: HttpResponse<any>) => {
          let jsonObject: any;
          if (res.status === 204) {
            //codigo invalido
            jsonObject = false;
          } else if (res.status === 200){
            jsonObject = res.body;
          }
          return [{ status: res.status, json: jsonObject}];
        }),
        catchError(this.handleError)
      );
  }
  ingresarkit(servicio: Servicio): Observable<any>{
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<Boolean>(baseURL + 'AgregarServicios/ingresarservicio', servicio, {headers: this.headers, observe: 'response'}).
      pipe(
        map((res: HttpResponse<any>) => {
          let jsonObject: any;
          if (res.status === 204) {
            //codigo invalido
            jsonObject = false;
          } else if (res.status === 200){
            jsonObject = res.body;
          }
          return [{ status: res.status, json: jsonObject}];
        }),
        catchError(this.handleError)
      );
  }
//metodo para asignar nuevo
  asignar(recasig: Asignar): Observable<any>{
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<Boolean>(baseURL + 'usuario/asignarecursos', recasig, {headers: this.headers, observe: 'response'}).
      pipe(
        map((res: HttpResponse<any>) => {
          let jsonObject: any;
          if (res.status === 204) {
            //codigo invalido
            jsonObject = false;
          } else if (res.status === 200){
            jsonObject = res.body;
            alert("se guardaron los datos exitosamente")
          }
          return [{ status: res.status, json: jsonObject}];
        }),
        catchError(this.handleError)
      );
  }

getvideosid(nombrelibro: String): Observable<number[]> {
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<number[]>(baseURL + `CRUDVideo/listaridvideosxlibro?nombrelibro=${nombrelibro.toString()}`, null, {headers: this.headers})
    .pipe(
      //tap(data => console.log("All:" + JSON.stringify(data))),
      catchError(this.handleError));

  }

  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  eliminarKit(kit: Servicio)
  {
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<any>(baseURL + 'AgregarServicios/deshabilitarservicio', kit, {headers: this.headers, observe: 'response'}).
    pipe(
      map((res: HttpResponse<any>) => {
        let jsonObject: any;
        if (res.status === 204) {
          //codigo invalido
          jsonObject = false;
        } else if (res.status === 200){
          jsonObject = res.body;
        }
        return [{ status: res.status, json: jsonObject}];
      }),
      catchError(this.handleError)
    );
  }

  }
