<div class="row">
    <app-side-bar></app-side-bar>
    <div class="col-sm-9">
        <h1 style="text-align: center;">Listado de Kits</h1>
        <div class="row">
            <div class="col-md-11 col-md-offset-11">
                <a routerLink="/saled/newkit"><button type="button" class="btn btn-md btn-primary">Añadir</button></a>
                &nbsp;&nbsp;&nbsp;<a><button type="button" class="btn btn-md btn-success" (click)="refrescararchivos()">Refrescar</button></a>

            </div>
        </div>
        <div *ngIf="servicios" class="table-responsive">
            <table class="table">
                <tr>
                    <td>
                        Nombre
                    </td>
                    <td>
                        Precio
                    </td>
                    <td>
                        Tipo
                    </td>
                    <td>
                        Eliminar
                    </td>
                </tr>
                <tr *ngFor = 'let servicio of servicios'>
                    <td>{{servicio.nombre}}</td>
                    <td>{{servicio.precio}}</td>
                    <td>{{servicio.tiposervicio}}</td>
                    <td><a><img src="/assets/img/edit.png" (click)="quitarkit(servicio)"> </a></td>
                 </tr>
            </table>
        </div>
        <div *ngIf="!servicios">
          <div>
            <div class="alert alert-info">
              <strong>¡No se encontraron servicios!</strong> Aun no se han registrado servicios en la base de datos.
            </div>
          </div>
        </div>
    </div><!--/.main-->
</div>
