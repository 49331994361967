<div class="row">
	<app-side-bar></app-side-bar>

    <div class="col-sm-9">
        <h1 style="text-align: center;">Asignación de libros y kits a usuarios</h1>
		<div class="panel panel-default">
			<div class="panel-heading">Formulario de información</div>
			<div class="panel-body">
				<div class="col-md-9">
					<form role="form">
						<div class="form-group">
							<h3>Elejir los Usuarios para asignar los recursos y libros</h3>
                  <select *ngIf="usuarios" class="form-control"  placeholder="Selecione un Usurio">
                    <option *ngFor = 'let usuario of usuarios;' [value]="usuario?.idusuario" (click)="selecionusuario(usuario)">{{usuario?.correo}}</option>
                    <option hidden selected> Elija un Usuario</option>
                  </select>
            </div>
            <hr>
            <h3>Elegir los libros para asignar:</h3>
						<br>
						<label>Seleccione una Colección</label>
							<select *ngIf="colecciones" class="form-control" (change)="onChangecoleccion($event.target.value)">
                <option hidden selected>Seleccione una Colección</option>
                <option *ngFor = 'let coleccion of colecciones;' [value]="coleccion?.idcolecion">{{coleccion?.nombre}}</option>
							</select>
							<br>
							<label>Seleccione una Categoría</label>
							<select *ngIf="categorias" class="form-control" (change)="onChangecategoria($event.target.value)" placeholder="Selecione una Categoria">
                <option *ngFor = 'let categoria of categorias;' [value]="categoria?.idcategoria">{{categoria?.nombre}}</option>
								<option hidden selected> Elija una categoría</option>
							</select>
						<br>

						<label>Seleccione los libros</label>
							<select *ngIf="libs" class="form-control"  placeholder="Selecione un Libro">
                <option *ngFor = 'let lib of libs;' [value]="lib?.idlibro" (click)="selecionlibro(lib)">{{lib?.titulo}}</option>
								<option hidden selected> Elija un Libro</option>
							</select>
						<hr>
            <h3>Elegir los kits para asignar:</h3>
						<br>
							<select *ngIf="servicios" class="form-control" placeholder="Selecione un Kit">
                <option *ngFor = 'let servicio of servicios;' [value]="servicio?.idservicio" (click)="selecionkit(servicio)">{{servicio?.nombre}}</option>
								<option hidden selected> Elija un kit</option>
							</select>
						<br>
						<h3>Tabla de usuarios para asignar los recursos</h3>
						<div *ngIf="usuariosseleccionados" class="table-responsive">
							<table class="table" >
								<tr>
									<td>
										Nombre
									</td>
									<td>
										Correo
									</td>
									<td>
										Quitar
									</td>
								</tr>
								<tr *ngFor = 'let usuario of usuariosseleccionados; let ind = index;'>
									<td>{{usuario?.nombres}} {{usuario?.apellidos}}</td>
									<td>{{usuario?.correo}}</td>
									<td><a><img src="/assets/img/edit.png" style="cursor: pointer;" (click)="quitarusuario(ind)"> </a></td>
								 </tr>
							</table>
            </div>
            <div *ngIf="!usuariosseleccionados">
              <div class="alert alert-info">
                <strong>¡Selecciona usuarios para asignar!</strong> Aun no se han registrado usuarios en la base de datos.
              </div>
            </div>
            <hr>
            <br>
						<h3>Tabla de libros y kits para asignar</h3>
						<div *ngIf="librosseleccionados" class="table-responsive">
							<table class="table" >
								<tr>
									<td>
										Libro/Kit
									</td>
									<td>
										Quitar
									</td>
								</tr>
								<tr *ngFor = 'let lib of librosseleccionados; let ind = index;'>
									<td>{{lib?.titulo}}</td>
									<td><a><img src="/assets/img/edit.png" style="cursor: pointer;" (click)="quitarlibro(ind)"> </a></td>
								</tr>
								<tr *ngFor = 'let servicio of kitsSeleccionados; let ind = index;'>
									<td>{{servicio?.nombre}}</td>
									<td><a><img src="/assets/img/edit.png" style="cursor: pointer;" (click)="quitarKit(ind)"> </a></td>
								</tr>
							</table>
            </div>
            <div *ngIf="!librosseleccionados">
              <div class="alert alert-info">
                <strong>¡Selecciona recursos para asignar!</strong> Aun no se han registrado recursos en la base de datos.
              </div>
            </div>
					<div class="row">
						<div class="col-md-3 col-md-offset-3"></div>
						<div class="col-md-3 col-md-offset-3"><button type="button" class="btn btn-lg btn-danger" (click)="guardarCambios()">Confirmar Asignación</button></div>
					 </div>
					</form>
				</div>
			</div>
        </div>
    </div>
</div>
