<!-- Modal -->
<div class="modal fade" id="registerModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="myModalLabel">¿Quieres comprar nuestros libros?</h4>
      </div>
      <form [formGroup]="userForm" >
      <div class="modal-body">
          <label for="inputHelpBlock">Registrate para poder acceder al contenido</label>
          <app-intituciones (institucion)='getInstitucion($event)'></app-intituciones>
          <div *ngIf="(Nombres.invalid && Nombres.touched) || Nombres.dirty">
            <small *ngIf="Nombres.errors?.required" class="text-danger">Este campo es requerido</small>
          </div>
          <div class="form-group">
            <label for="names">Nombres</label>
            <input type="text" class="form-control" id="names" placeholder="Ej: Juan Fernando" formControlName="nombres" required="true">
          </div>
          <div *ngIf="(Apellidos.invalid && Apellidos.touched) || Apellidos.dirty">
            <small *ngIf="Apellidos.errors?.required" class="text-danger">Este campo es requerido</small>
          </div>
          <div class="form-group">
            <label for="last-names">Apellidos</label>
            <input type="text" class="form-control" id="last-names" placeholder="Ej: Zalazar Altamirano" formControlName="apellidos" required="true">
          </div>
          <div class="form-group">
            <label for="tipo-usuario">Tipo de Usuario</label>
            <select class="form-control" id="tipo-usuario" formControlName="tipo" required="true">
              <option>Estudiante</option>
              <option>Docente</option>
              <option>Particular</option>
            </select>
          </div>
          <div class="form-group">
            <label for="nivel">Nivel de Instrucción</label>
            <select class="form-control" id="nivel" formControlName="nivel" required="true">
              <option>Inicial (2 a 3 años)</option>
              <option>Inicial (3 a 4 años)</option>
              <option>Inicial (4 a 5 años)</option>
              <option>Preparatoria</option>
              <option>General básica</option>
              <option>Bachillerato</option>
            </select>
          </div>
          <div *ngIf="(Telefono.invalid && Telefono.touched) || Telefono.dirty">
            <small *ngIf="Telefono.errors?.required" class="text-danger">Este campo es requerido</small>
          </div>
          <div class="form-group">
            <label for="telefono">Número de teléfono</label>
            <input type="tel" class="form-control" id="telefono" formControlName="telefono" placeholder="Ej: 09xxxxxxxx" required="true">
          </div>
          <div *ngIf="(Fechanacimiento.invalid && Fechanacimiento.touched) || Fechanacimiento.dirty">
            <small *ngIf="Fechanacimiento.errors?.required" class="text-danger">Este campo es requerido</small>
          </div>
          <div class="form-group">
            <label for="birthdate">Fecha de nacimiento</label>
            <input type="date" class="form-control" id="birthdate" formControlName="fechanacimiento" required="true">
          </div>
          <div *ngIf="(Correo.invalid && Correo.touched) || Correo.dirty">
            <small *ngIf="Correo.errors?.required" class="text-danger">Este campo es requerido</small>
            <!--question mark(?) is a safe navigation operator-->
            <small *ngIf="Correo.errors?.pattern" class="text-danger">Por favor, ingresa un correo válido</small>
          </div>
          <div class="form-group">
            <label for="email">Correo Electrónico</label>
            <input [class.is-invalid] = "userForm.get('correo').invalid && userForm.get('correo').touched" type="email" class="form-control" name="email" formControlName="correo" id="email" placeholder="Ej: correo@email.com">
          </div>
          <div *ngIf="(Direccion.invalid && Direccion.touched) || Direccion.dirty">
            <small *ngIf="Direccion.errors?.required" class="text-danger">Este campo es requerido</small>
          </div>
          <div class="form-group">
            <label for="addres">Dirección</label>
            <input type="text" class="form-control" name="addres" id="addres" formControlName="direccion" placeholder="Av. 10 de Agosto y Republica">
          </div>
          <div *ngIf="(Contrasena.invalid && Contrasena.touched) || Contrasena.dirty" class="invalid-feedback">
            <small *ngIf="Contrasena.errors?.required">Este campo es requerido</small>
            <small *ngIf="Contrasena.errors?.minlegth">Debe contener al menos 8 caracteres</small>
          </div>
          <div class="form-group">
            <label for="new-password">Contraseña</label>
            <input type="password" class="form-control" name="new-password" formControlName="contrasena" id="new-password" placeholder="Contraseña">
          </div>
          <div *ngIf="(confirmPassword.invalid && confirmPassword.touched) || confirmPassword.dirty" class="invalid-feedback">
            <small *ngIf="confirmPassword.errors?.required">Este campo es requerido</small>
            <small *ngIf="confirmPassword.errors?.mustMatch">Contraseñas deben coinsidir</small>
        </div>
          <div class="form-group">
            <label for="password">Confirmar Contraseña</label>
            <input type="password" class="form-control" name="confirmPassword" formControlName="confirmPassword" id="confirmPassword" placeholder="Contraseña">
          </div>
          <div class="alert alert-warning" role="alert"><i class="fa fa-exclamation-triangle"></i> Nota: Solo se registra un correo por cada usuario!!</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" #botonCerrar>Cerrar</button>
        <button type="button" class="btn btn-primary" type="submit" style="cursor: pointer;" (click)='registrar()'>Registrarse</button>
      </div>
    </form>
    </div>
  </div>
</div>
