<div class="row">
	<app-side-bar></app-side-bar>
    <div class="col-sm-9">
		<h1 style="text-align: center;">Listado de Usuarios</h1>
		<h1 style="text-align: center;">Total Usuarios: {{usuarios.length}}</h1>
		<div class="row">
			<div class="col-md-11 col-md-offset-11">
				<!--<button routerLink="/register" type="button" class="btn btn-md btn-primary">Añadir</button>-->
				<button type="button" class="btn btn-primary navbar-btn" data-toggle="modal" data-target="#registerModal">Añadir</button>
				&nbsp;&nbsp;&nbsp;<a><button type="button" class="btn btn-md btn-success" (click)="refrescararchivos()">Refrescar</button></a>

				<!--<app-register></app-register>-->
			</div>
      <div class="col-md-4">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Buscar..." [(ngModel)]="listFilter">
          <span class="input-group-btn"></span>
        </div>
      </div><!--Boton de buscar-->
    </div>
    <br>
		<div *ngIf="usuarios" class="table-responsive">
			<table class="table">
				<tr>
					<td>
						Nombres
					</td>
					<td>
						Apellidos
					</td>
					<td>
						Correo
					</td>
					<td>
						Nivel
					</td>
					<td>
						Teléfono
					</td>
					<td>
						Tipo
					</td>
					<td>
						Institución
					</td>
					<td>
						Edit/Eliminar
					</td>
				</tr>
				<tr *ngFor = 'let usuario of usuariosFiltered; let i = index'>
					<td>{{usuario?.nombres}}</td>
					<td>{{usuario?.apellidos}}</td>
					<td>{{usuario?.correo}}</td>
					<td>{{usuario?.nivel}}</td>
					<td>{{usuario?.telefono}}</td>
					<td>{{usuario?.tipo}}</td>
					<td>{{usuario?.institucion}}</td>
					<td><a><img src="/assets/img/delete.png" [routerLink]="['/newuser',usuario?.idusuario]" > </a><a><img src="/assets/img/edit.png" (click)="eliminarUser(usuario)"></a></td>
				 </tr>
			</table>
      </div>
      <div *ngIf="!usuarios">
        <div>
          <div class="alert alert-info">
            <strong>¡No se encontraron usuarios!</strong> Aun no se han registrado usuarios en la base de datos.
          </div>
        </div>
      </div>
	</div><!--/.main-->
</div>
