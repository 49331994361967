import { tap, catchError, map } from 'rxjs/operators';
import { baseURL } from './baseUrl';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { Pedido } from '../modulos/pedido.model';

@Injectable({
  providedIn: 'root'
})

export class AdministpedidoService {

  headers: HttpHeaders;
  

constructor(private http: HttpClient) { }
/*Obtener los pedidos del back*/

pedirpedido(): Observable<any>{
  this.headers = new HttpHeaders ({
    "Content-Type": "application/json",
    Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
  });
  return this.http.get<Pedido[]>(baseURL + 'administracion/listarpedidos', {headers: this.headers, observe: 'response'}).
    pipe(
      map((res: HttpResponse<any>) => {
        let jsonObject: any;
        if (res.status === 204) {
          //codigo invalido
          jsonObject = false;
        } else if (res.status === 200){
          jsonObject = res.body;
        }
        return [{ status: res.status, json: jsonObject}];
      }),
      catchError(this.handleError)
    );
}

entregarpedido(pedido: Pedido){
  this.headers = new HttpHeaders ({
    "Content-Type": "application/json",
    Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
  });
  return this.http.post<boolean>(baseURL + 'administracion/entregarorden', pedido,{headers: this.headers, observe: 'response'}).
    pipe(
      map((res: HttpResponse<any>) => {
        let jsonObject: any;
        if (res.status === 204) {
          //codigo invalido
          jsonObject = false;
        } else if (res.status === 200){
          jsonObject = res.body;
        }
        return [{ status: res.status, json: jsonObject}];
      }),
      catchError(this.handleError)
    );
}


private handleError(err: HttpErrorResponse) {
  // in a real world app, we may send the server to some remote logging infrastructure
  // instead of just logging it to the console
  let errorMessage = '';
  if (err.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    errorMessage = `An error occurred: ${err.error.message}`;
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
  }
  console.error(errorMessage);
  return throwError(errorMessage);
}
}