import { Component, OnInit, Output } from '@angular/core';
import { Coleccion } from 'src/app/modulos/coleccion.model';
import { ColeccionService } from 'src/app/servicios/coleccion.service';
import { ProductService } from 'src/app/servicios/product.service';
import { Categoria } from 'src/app/modulos/categoria.model';
import { EventEmitter, SimpleChanges } from '@angular/core';
import { Libro } from 'src/app/modulos/libro.model';
import { ActivatedRoute } from '@angular/router';
import { AdministlibService } from 'src/app/servicios/administlib.service';
import { VideoService } from 'src/app/servicios/videos.service';
import { Video } from 'src/app/modulos/video.model';
import { Libroingreso } from 'src/app/modulos/libroingreso.model';


@Component({
  selector: 'app-newvideo',
  templateUrl: './newvideo.component.html',
  styleUrls: ['./newvideo.component.scss']
})
export class NewvideoComponent implements OnInit {

  colecciones: Coleccion [];
  libros: Libroingreso [];
  categorias: Categoria [];
  video: Video;
  idcoleccionSeleccionada = 0;
  idcategoriaSeleccionada = 0;
  nombrelibroSeleccionado = '';
  tipoSeleccionado: number;
  errorMessage = '';
  idColeccion = 0;
  IdCategoria = 0;
  idLibro = 0;
  idVideo = 0;
  existevideo = false;
  eliminado = false;

  constructor(private coleccionServicio: ColeccionService,
    private productServicio: ProductService,
    private route: ActivatedRoute,
    private administlibServicio: AdministlibService,
    private videoServicio: VideoService) { }

    modifiedvid: Video = {
      idvideo: 0,
      nombre: '',
      nombrelibro:'',
      urlvideo:'',
      tiporecurso:'',
      pagina: 0,
      habilitado: true
      }

  ngOnInit(): void {
    const param = this.route.snapshot.paramMap.get('id');
    if (param) {
      const id = +param;
      this.getVideo(id);
      this.idVideo = +param;
      this.existevideo = true;
    }
    this.obtenerColecciones();
  }

  getVideo(id: number) {
    this.videoServicio.getVideo(id).subscribe({
      next: video => this.video = video,
      error: err => this.errorMessage = err
    });
  }

  obtenerColecciones(){
    this.coleccionServicio.getAllColeccion().subscribe(
      res => {
        this.colecciones = res;
      },
      err => console.log(err)
    );
  }

  obtenerCategorias(id: number){
    this.coleccionServicio.getCategorias(id).subscribe(
      res => {
        this.categorias = res;
      },
      err => console.log(err)
    );
  }
  obtenerLibros(id: number){
    this.administlibServicio.getBooksCat(id).subscribe(
      res => {
        this.libros = res;
      },
      err => console.log(err)
    );
  }

  onChangecoleccion(id){
    this.idcoleccionSeleccionada = id;
    this.obtenerCategorias(id);
  }

  onChangecategoria(id){
    if(id != 0 ){
      this.idcategoriaSeleccionada = id;
      this.obtenerLibros(id);
    }else if(id == 0){
      console.log(id);
    }
  }

  onChangelibro(id){
    if(id != 0 ){
      this.nombrelibroSeleccionado = id;
    }else if(id == 0){
      console.log(id);
    }
  }

  modificarvideo(video: Video){
    this.videoServicio.modificarVideo(video).subscribe(
      res => {
        if(res[0].json != undefined){
          this.eliminado = res[0].json;
          if(this.eliminado === true){
            alert("Video modificado con éxito.");
            this.eliminado = false;
          }else{
            alert("No se ha podido modificar");
          }
        }
      }
    )
  }

  ingresarvideo(video: Video){
    this.videoServicio.ingresarVideo(video).subscribe(
      res => {
        if(res[0].json != undefined){
          this.eliminado = res[0].json;
          if(this.eliminado === true){
            alert("Video ingresado con éxito.");
            this.eliminado = false;
          }else{
            alert("No se ha podido ingresar el video");
          }
        }
      }
    )
  }

  guardarCambios(){
    if(this.nombrelibroSeleccionado == ''){
      alert("Por favor seleccione un libro.");
    }else{
      if((<HTMLInputElement> document.getElementById('nuevonombrevid')).value !== '' &&
      (<HTMLInputElement> document.getElementById('nuevaurl')).value !== '' &&
    (<HTMLInputElement> document.getElementById('nuevotiporec')).value !== '' &&
    (<HTMLInputElement> document.getElementById('nuevapag')).value !== '' ){
    this.modifiedvid.nombre = (<HTMLInputElement> document.getElementById('nuevonombrevid')).value;
    this.modifiedvid.urlvideo = (<HTMLInputElement> document.getElementById('nuevaurl')).value;
    this.modifiedvid.tiporecurso = (<HTMLInputElement> document.getElementById('nuevotiporec')).value;
    this.modifiedvid.pagina = (Number)((<HTMLInputElement> document.getElementById('nuevapag')).value);
    this.modifiedvid.nombrelibro = this.nombrelibroSeleccionado;
        if(this.idVideo != 0){
          this.modifiedvid.idvideo = this.idVideo;
          this.modificarvideo(this.modifiedvid);
          console.log(this.modifiedvid);

        }else{
          //Llamar al servicio de ingresar nueva coleccion
          console.log(this.modifiedvid);
          this.ingresarvideo(this.modifiedvid);
        }
      }else{
        alert("Por favor ingrese los campos del libro");
      }
    }
  }

}
