import { Libro } from './../modulos/libro.model';
import { baseURL } from './baseUrl';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap} from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  /*private SERVER_URL = environment.SERVER_URL;*/
  constructor(private http: HttpClient) { }
    /*Obtener los libros/productos del back*/
    getAllBooks(): Observable<Libro[]>{
      return this.http.get<Libro[]>(baseURL + 'ServicioGenerales/listarlibros', httpOptions)
      .pipe(
        tap(data =>
          //console.log("All:" + JSON.stringify(data))
          console.log("todos los datos llegaron")
        ),
        catchError(this.handleError)
      );
    }

    getBook(id: number): Observable<Libro> {
      return this.http.post<Libro>(baseURL + `ServicioGenerales/buscarlibro?idlibro=${id.toString()}`, null);
      /*return this.getAllBooks()
        .pipe(
          map((books: Libro[]) => books.find(p => p.Idibro === id))
        );*/
    }

    private handleError(err: HttpErrorResponse) {
      // in a real world app, we may send the server to some remote logging infrastructure
      // instead of just logging it to the console
      let errorMessage = '';
      if (err.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        errorMessage = `An error occurred: ${err.error.message}`;
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
      }
      console.error(errorMessage);
      return throwError(errorMessage);
    }
}
