<!--Encabezado -->
<section id="cta" class="cta">
    <div class="container" data-aos="zoom-in">

      <div class="row">
        <div class="col-lg-9 text-center text-lg-start" style="font-family: Comic Sans MS, Comic Sans, cursive;">
          <h3>Recursos digitales</h3>
          <p>¡Nota! La adquisición de un Kit educativo también incluye 2 capacitaciones.</p>
        </div>
        <div class="col-lg-3 cta-btn-container text-center">
          <a class="cta-btn align-middle" href="https://api.whatsapp.com/send/?phone=593994176258" target="blank">Mas información</a>
        </div>
      </div>

    </div>
</section>

<!--kits-->
<section class="dark" style="font-family: Comic Sans MS, Comic Sans, cursive;">
	<div class="container py-4"><br><br>
	<!--<article class="postcard dark blue">
			<a class="postcard__img_link">
				<img class="postcard__img" src="/assets/img/img kit1 png.png" alt="Image Title" />
			</a>
			<div class="postcard__text" style="color: black;">
				<h1 class="postcard__title blue"><a>&nbsp;&nbsp;&nbsp;&nbsp;KIT DE RECURSOS DIGITALES SUB-NIVEL II (3-4 AÑOS)</a></h1>
				<div class="postcard__subtitle small">
					<time datetime="2020-05-25 12:00:00">
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contiene:
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">
                    <ul>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;Mágicas Travesuras Sub-Nivel I (3-4 años) (Digital).</li>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;Videos interactivos con procesos de aprendizaje.</li>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;Microplanificaciones con experiencias de aprendizaje.</li>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;Plan curricular anual.</li>
                    </ul>
                    <h3 style="text-align: right;"><strong>Precio oferta: $38.90</strong></h3>
                </div>
			</div>
		</article>
		<article class="postcard dark red" style="color: black;">
			<a class="postcard__img_link">
				<img class="postcard__img" src="/assets/img/img kit2 png.png" alt="Image Title" />	
			</a>
			<div class="postcard__text">
				<h1 class="postcard__title red"><a>KIT DE RECURSOS DIGITALES SUB-NIVEL II (4-5 AÑOS)</a></h1>
				<div class="postcard__subtitle small">
					<time datetime="2020-05-25 12:00:00">
						Contiene:
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">
                    <ul>
                        <li>>&nbsp; Mágicas Travesuras Sub-Nivel II (4-5 años) (Digital).</li>
                        <li>>&nbsp; Videos interactivos con procesos de aprendizaje.</li>
                        <li>>&nbsp; Microplanificaciones con experiencias de aprendizaje.</li>
                        <li>>&nbsp; Plan curricular anual.</li>
                    </ul>
                    <h3 style="text-align: left;"><strong>Precio oferta: $38.90</strong></h3>
                </div>
			</div>
		</article>
		<article class="postcard dark green" style="color: black;">
			<a class="postcard__img_link">
				<img class="postcard__img" src="/assets/img/img kit3 png.png" alt="Image Title" />
			</a>
			<div class="postcard__text">
				<h1 class="postcard__title green"><a>&nbsp;&nbsp;&nbsp;&nbsp;KIT DE RECURSOS DIGITALES SUB-NIVEL PREPARATORIA</a></h1>
				<div class="postcard__subtitle small">
					<time datetime="2020-05-25 12:00:00">
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contiene:
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">
                    <ul>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;Mágicas Travesuras Preparatoria (Digital)</li>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;Videos interactivos con procesos de aprendizaje.</li>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;Microplanificaciones con experiencias de aprendizaje.</li>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;Plan curricular anual.</li>
                    </ul>
                    <h3 style="text-align: right;"><strong>Precio oferta: $48.90</strong></h3>
                </div>
			</div>
		</article>-->

		<article *ngFor="let item of servicios" class="postcard dark blue" style="color: black;">
			<a class="postcard__img_link">
				<img class="postcard__img" src="/assets/img/img kit3 png.png" alt="Image Title" />
			</a>
			<div class="postcard__text">
				<h1 class="postcard__title green"><a>&nbsp;&nbsp;&nbsp;&nbsp;{{item.nombre}}</a></h1>
				<div class="postcard__subtitle small">
					<time datetime="2020-05-25 12:00:00">
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contiene:
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">
                    <ul>
						<li *ngFor="let aux of item.descripcion.split(',',4)">&nbsp;&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;{{aux}}</li>
                    </ul>
                    <h3 style="text-align: center;"><strong>Precio oferta: ${{item.precio.toFixed(2)}}</strong></h3>
                </div>
			</div>
		</article>
	</div>
</section>
