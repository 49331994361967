import { CarritoService } from './../../servicios/carrito.service';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Libro } from 'src/app/modulos/libro.model';
import { Subscription } from 'rxjs';

declare let paypal;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  pedido: Libro[];
  subscription: Subscription;

  book: Libro;
  producto = {
    descripcion: 'prueba 1',
    precio: 10.50,
  };
  @ViewChild('paypal', {static: true}) paypalElement: ElementRef;

  constructor(private carritoServicio: CarritoService) {
    // this.subscription = this.carritoServicio.onMessage().subscribe(
    //   response => {
    //   if (response !== undefined){
    //     this.pedido = response;
    //   } else {
    //     this.pedido = [];
    //     console.log(" no se lleno el carrito");

    //   }
    //   console.log(this.pedido);
    // });
   }

  ngOnInit(): void {

  }


}
