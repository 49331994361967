import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Usuario } from 'src/app/modulos/user.model';
import { AdministuserService } from 'src/app/servicios/administuser.service';
import { LoginService } from 'src/app/servicios/login.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { RegistroService } from './../../../servicios/registro.service';

@Component({
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

  mostrarRegistro: boolean;
  permitirRegistro: boolean;

  /*isLoggedIn = false;
  isLoggedInAdmin = false;
  loggedInUser: Usuario;*/
  eliminado = false;


  usuarios: Usuario[] = [];
  usuariosFiltered: Usuario[] = [];

  _listFilter: string;
  get listFilter(): string {
    return this._listFilter;
  }
  set listFilter(value: string) {
    this._listFilter = value;
    this.usuariosFiltered = this.listFilter ? this.performFilter(this.listFilter) : this.usuarios;
  }

  constructor(private administuserServicio: AdministuserService,
              private loginService: LoginService,
              private registroService: RegistroService) { }

  ngOnInit(): void {
    /*const user = this.loginService.accountValue;
    if (user){
      this.isLoggedIn = true;
    }*/

    this.listarusuarios();

  }

  performFilter(filterBy: string): Usuario[] {
    filterBy = filterBy.toLocaleLowerCase();
    return this.usuarios.filter((usuario: Usuario) =>
          usuario.apellidos.toLocaleLowerCase().indexOf(filterBy) !== -1);
  }

  refrescararchivos(){
    this.listarusuarios();
  }

  listarusuarios(){
    this.administuserServicio.pedirusuario().subscribe(
      res => {
        if (res[0].json != undefined){
          this.usuarios = res[0].json;
          this.usuariosFiltered = this.usuarios;
        }
        //console.log(JSON.stringify(this.libs));

      },
      err => console.log(err)
    )
  }
//esto se  añadió
    eliminarUser(usuario: Usuario){
      console.log(usuario);

      this.registroService.eliminarUser(usuario).subscribe(
        res => {
          if(res[0].json !== undefined){
            this.eliminado = res[0].json;
            if(this.eliminado === true){
              alert("Usuario eliminado con éxito.");
              this.eliminado = false;
              this.listarusuarios();
            }else{
              alert("No se ha podido eliminar");
            }
          }
        }
      )
    }

}

//falta agregar el servicio en el registro.service.ts
