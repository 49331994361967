import { MisRecursosService } from './../../servicios/mis-recursos.service';
import { Archivo } from './../../modulos/archivo.model';
import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './mis-archivos.component.html',
  styleUrls: ['./mis-archivos.component.scss']
})
export class MisArchivosComponent implements OnInit {

  archivos: Archivo[] = [];
  pdfsrc = '';

  constructor(private misrecursos: MisRecursosService) { }

  ngOnInit(): void {
    this.getArchivos();
  }

  pasarUrlLibro(pdfsrc: string){
    this.pdfsrc = pdfsrc;
  }

  getArchivos(){
    this.misrecursos.getArchivosProfesor().subscribe({
      next: recursos => {
        if ( recursos !== undefined){
          this.archivos = recursos;
        }
      },
      error: err => console.log(err)
    });
  }

}
