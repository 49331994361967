import { Play } from './../../modulos/play.model';
import { Video } from './../../modulos/video.model';
import { MisRecursosService } from './../../servicios/mis-recursos.service';
import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './mis-videos.component.html',
  styleUrls: ['./mis-videos.component.scss']
})
export class MisVideosComponent implements OnInit {

  videos: Video[] = [];
  videosrc: string;
  booksNames: string[] = [];
  loadVideosClicked = false;

  playlist: Play[] = [];
  /*play: Play = {
    title: '',
    src: '',
    type: ''
  };*/

  currentIndex = 0;
  currentItem = this.playlist[this.currentIndex];
  api;

  constructor(private misrecursos: MisRecursosService) { }

  ngOnInit(): void {
    this.getVideos();
  }

  onPlayerReady(api) {
    this.api = api;

    this.api.getDefaultMedia().subscriptions.loadedMetadata.subscribe(this.playVideo.bind(this));
    this.api.getDefaultMedia().subscriptions.ended.subscribe(this.nextVideo.bind(this));
  }

  nextVideo() {
    this.currentIndex++;

    if (this.currentIndex === this.playlist.length) {
      this.currentIndex = 0;
    }

    this.currentItem = this.playlist[this.currentIndex];
  }

  playVideo() {
    this.api.play();
  }

  onClickPlaylistItem(item, index: number) {
    this.currentIndex = index;
    this.currentItem = item;
  }

  getVideos(){
    this.misrecursos.getVideosProfesor().subscribe({
      next: recursos => {
        if (recursos !== undefined){
          this.videos = recursos;
          this.getBookNames(this.videos);
          console.log(this.videos);
        }
      },
      error: err => console.log(err)
    });
  }

  getUrlVideos(videosrc: string){
    this.videosrc = videosrc;
  }

  unique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  getBookNames(videos: Video[]){
    this.booksNames = videos.map( video =>
      video.nombrelibro
    );
    //console.log(this.booksNames);
    this.booksNames = this.booksNames.filter(this.unique).sort();
    //console.log(this.booksNames);

  }

  loadVideos(bookName: string){
    this.llenarPlaylist(this.videos, bookName);
    if (this.videos !== []){
      this.loadVideosClicked = true;
    }
  }

  hideVideos(){
    this.loadVideosClicked = false;
  }

  llenarPlaylist(videos: Video[], bookName: string){
    //console.log(videos);
    this.playlist.length = 0;
    videos = videos.filter(video => video.nombrelibro === bookName);
    console.log(videos);

    videos.forEach( video => {
      //console.log(video);
      this.playlist.push({
        title: 'Pag. ' + video.pagina + ' - ' + video.nombre,
        src: video.urlvideo,
        type: 'video/mp4'
      });
    });
    //console.log(this.playlist);

  }
}
