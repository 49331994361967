<div>
    <div id="sidebar-collapse" class="col-sm-3 col-lg-2 sidebar" style="margin-left: 25px;">
        <div class="profile-sidebar">
            <div class="profile-usertitle">
                <div class="profile-usertitle-name"><h2 style="text-align: center;">Administrador</h2></div>
            </div>
            <div class="clear"></div>
        </div>
        <div class="divider"></div>
        <ul class="nav menu">
            <div style="text-align: center;">Menu</div>
            <li class="active"><a routerLink="/saled/dashboard"><em class="fa fa-desktop">&nbsp;</em> Dashboard</a></li>
            <li class="parent "><a data-toggle="collapse" href="#sub-item-1" (click)="refrescarcolecciones()">
                <em class="fa fa-navicon">&nbsp;</em> Colecciones <span data-toggle="collapse" href="#sub-item-1" class="icon pull-right" ><em class="fa fa-plus"></em></span>
                </a>
                <ul class="children collapse" id="sub-item-1">
                    <li *ngFor = 'let coleccion of colecciones'><a [routerLink]="['/saled/newcol',coleccion.idcolecion]">{{coleccion?.nombre}}</a></li>

                    <!--<li><a class="" href="#">
                        <span class="fa fa-arrow-right">&nbsp;</span> Mágicas travesuras
                    </a></li>
                    <li><a class="" href="#">
                        <span class="fa fa-arrow-right">&nbsp;</span> Leo León
                    </a></li>-->
                    <li><a routerLink="/saled/newcol">
                        <span class="fa fa-arrow-right">&nbsp;</span> Ingresar nueva
                    </a></li>
                </ul>
            </li>
            <li><a routerLink="/saled/newcat"><em class="fa fa-object-ungroup">&nbsp;</em> Categorías</a></li>
            <li><a routerLink="/saled/usuarios"><em class="fa fa-users">&nbsp;</em> Usuarios</a></li>
            <li><a routerLink="/saled/ordenes"><em class="fa fa-shopping-cart">&nbsp;</em> Órdenes</a></li>
            <li><a routerLink="/saled/books"><em class="fa fa-book">&nbsp;</em> Libros</a></li>
            <li><a routerLink="/saled/videos"><em class="fa fa-video-camera">&nbsp;</em> Videos</a></li>
            <li><a routerLink="/saled/archivos"><em class="fa fa-archive">&nbsp;</em> Archivos</a></li>
            <li><a routerLink="/saled/kits"><em class="fa fa-server">&nbsp;</em> Kits</a></li>
            <li><a routerLink="/saled/asignar"><em class="fa fa-upload">&nbsp;</em> Asignar libros y recursos</a></li>
        </ul>

    </div><!--/.sidebar-->
</div>
