<!--hero-->
<section id="hero" style="font-family: Comic Sans MS, Comic Sans, cursive;">
  <div class="hero-container" data-aos="zoom-in" data-aos-delay="100">
    
    <a href="https://api.whatsapp.com/send/?phone=593994176258" target="blank" class="btn-get-started">Contáctanos</a>
  </div>
</section>
<!--nosotros-->
<section id="facts" style="font-family: Comic Sans MS, Comic Sans, cursive;">
  <div class="container" data-aos="fade-up">
    <div class="section-header">
      <h1 class="section-title" style="text-align: center;"><strong>Nosotros</strong></h1>
      <h3 class="section-description" style="text-align: justify;">Salazar Editores nace el 15 de Marzo del 2003 con el afán de contribuir con la educación del Ecuador a través de una propuesta fundamentada en EDUCAR PARA LA VIDA, formando seres humanos líderes, críticos, independientes y capaces de elegir.</h3>
    </div>
  </div>
</section>
<!--Porpuesta-->
<section id="about" style="font-family: Comic Sans MS, Comic Sans, cursive;">
  <div class="container" data-aos="fade-up">
    <div class="row about-container">

      <div class="col-lg-6 content order-lg-1 order-2">
        <h2 class="title">Nuestra Propuesta</h2>
        <br>
        <h4 style="text-align: justify;">
          Al ser una empresa ecuatoriana conocedora de las múltiples necesidades educativas, queremos resaltar nuestra propuesta EDUCAR PARA LA VIDA, que se fundamenta en tres pilares fundamentales que son:
        </h4>
        <br>
        <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
          <div class="icon"><i class="fa fa-lightbulb-o fa-3x"></i></div>
          <h4 class="title"><a><strong>DESARROLLO DEL PENSAMIENTO</strong></a></h4>
          <p class="description" style="text-align: justify;">Cualidad que hará del niño un ser crítico, independiente y autónomo</p>
        </div>

        <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
          <div class="icon"><i class="fa fa-handshake-o fa-3x"></i></div>
          <h4 class="title"><a><strong>VALORES HUMANOS</strong></a></h4>
          <p class="description" style="text-align: justify;">Se procura el rescate de valores como el respeto, la cordialidad , la amabilidad, entre otros.</p>
        </div>

        <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
          <div class="icon"><i class="fa fa-book fa-3x"></i></div>
          <h4 class="title"><a><strong>LECTURA</strong></a></h4>
          <p class="description" style="text-align: justify;">Se ha tomado como eje vertebrador del conocimiento, leer es la base para iniciar las actividades en cada uno de nuestros textos en sus diversos géneros como: novela, cuento, poesía, ensayo, etc. Se ha recopilado obras de autores clásicos y contemporáneos de la literatura universal y ecuatoriana.</p>
        </div>

      </div>

      <div class="col-lg-6"><img src="/assets/img/Leo-león-Logo3.png" style="height: 100%; width: 65%; display: block; margin: auto;" alt=""></div>
    </div>

  </div>
</section>
<!--mision vision-->
<section class="dark" style="font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;">
	<div class="container py-4">
		<article class="postcard dark blue" style="background-color: rgb(216, 216, 216);">
			<a class="postcard__img_link">
				<img class="postcard__img" src="/assets/img/3.jpg" style="width: 15em; height: 22em;" alt="Image Title" />
			</a>
			<div class="postcard__text">
				<h1 class="postcard__title blue" style="color: rgb(0, 0, 0);"><a>&nbsp;&nbsp;&nbsp;&nbsp;Misión</a></h1>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt" style="padding-left: 3%; font-size: large; color: rgb(0, 0, 0);">Mágicas Travesuras es una herramienta pedagógica que sirve para potenciar procesos de aprendizaje mediante estrategias metodológicas lúdicas correspondientes a las características propias de los niños que están en los niveles Inicial y Preparatoria. Somos productores y comercializadores de productos y servicios educativos y culturales innovadores que satisfagan las diversas necesidades pedagógicas, superando los diferentes nichos de mercado y contando con personal profesional comprometido con la educación ecuatoriana.</div>
			</div>
		</article>
		<article class="postcard dark red" style="background-color: rgb(216, 216, 216);">
			<a class="postcard__img_link">
				<img class="postcard__img" src="/assets/img/9.jpg" alt="Image Title" style="width: 15em; height: 20em;"/>	
			</a>
			<div class="postcard__text">
				<h1 class="postcard__title red"><a style="color: rgb(0, 0, 0);">Visión</a></h1>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt" style="padding-right: 3%; font-size: large; color: rgb(0, 0, 0);">Consolidarnos como la empresa editorial ecuatoriana líder en el mercado, que contribuya al desarrollo de la educación y la cultura del país a través de la permanente innovación de productos y servicios, orientados al desarrollo de la educación. Todo esto contando con profesionales de alta calidad pedagógica y artística, comprometidos y motivados por su afán de servir a la comunidad educativa.</div>
			</div>
		</article>
	</div>
</section>

