import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BooksComponent } from './books/books.component';
import { OrdenesComponent } from './ordenes/ordenes.component';
import { KitsComponent } from './kits/kits.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { AdminComponent } from './admin.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { NewbookComponent } from './newbook/newbook.component';
import { NewkitComponent } from './newkit/newkit.component';
import { NewcolComponent } from './newcol/newcol.component';
import { NewcatComponent } from './newcat/newcat.component';
import { NewuserComponent } from './newuser/newuser.component';
import { VideosComponent } from './videos/videos.component';
import { ArchivosComponent } from './archivos/archivos.component';
import { NewarchivoComponent } from './newarchivo/newarchivo.component';
import { NewvideoComponent } from './newvideo/newvideo.component';
import { AsignarComponent } from './asignar/asignar.component';


@NgModule({
  declarations: [DashboardComponent,
    BooksComponent,
    OrdenesComponent,
    KitsComponent,
    UsuariosComponent,
    AdminComponent,
    SideBarComponent,
    NewbookComponent,
    NewkitComponent,
    NewcolComponent,
    NewcatComponent,
    NewuserComponent,
    VideosComponent,
    ArchivosComponent,
    NewarchivoComponent,
    NewvideoComponent,
    AsignarComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule
  ]
})
export class AdminModule { }
