import { UrlResolver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Categoria } from 'src/app/modulos/categoria.model';
import { Coleccion } from 'src/app/modulos/coleccion.model';
import { ColeccionService } from 'src/app/servicios/coleccion.service';
import { ProductService } from 'src/app/servicios/product.service';

@Component({
  selector: 'app-newcat',
  templateUrl: './newcat.component.html',
  styleUrls: ['./newcat.component.scss']
})
export class NewcatComponent implements OnInit {

  constructor(private productService: ProductService,
    public coleccionService: ColeccionService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router) { }

  id: 0;
  idcategoria = 0;
  idcoleccion = 0;
  errorMessage = '';
  colecciones: Coleccion[];
  categorias: Categoria[];
  categoria: Categoria ={
    idcategoria: 0,
    nombre: '',
    descripcion:'',
    urlimagen:'',
    urlimagen2:'',
    urlvideo: '',
    idcoleccion: 0,
    libros: [],
    color: ''
  }

  modifiedcat: Categoria = {
    idcategoria: 0,
    nombre: '',
    descripcion:'',
    urlimagen:'',
    urlimagen2:'',
    urlvideo: '',
    idcoleccion: 0,
    libros: [],
    color: ''
  };

  ingresado = false;
  modificado = false;
  eliminado = false;


  ngOnInit(): void {
    this.getColecciones();
  }
    getColecciones(){
      this.coleccionService.getAllColeccion().subscribe(
        res => {
          this.colecciones = res;
        },
        err => console.log(err)
      );
    }

    getCategoria(id: number){
      this.coleccionService.getCategoria(id).subscribe(
        res => {
          this.categoria = res;
          this.mostrarcategoria(this.categoria);
        },
        err => console.log(err)
      )
    }
    getCategorias(idcoleccion: number){
      this.coleccionService.getCategorias(idcoleccion).subscribe(
        res => {
          this.categorias = res;
        },
        err => console.log(err)
      )
    }

    guardarCambios(){
      if((<HTMLInputElement> document.getElementById('nuevonombre')).value !== '' &&
        (<HTMLInputElement> document.getElementById('nuevadescripcion')).value !== '' &&
      (<HTMLInputElement> document.getElementById('nuevaurlimagenicono')).value !== '' &&
      (<HTMLInputElement> document.getElementById('nuevaurlimagenportada')).value !== '' &&
      (<HTMLInputElement> document.getElementById('nuevocolor')).value !== ''){
      this.modifiedcat.nombre = (<HTMLInputElement> document.getElementById('nuevonombre')).value;
      this.modifiedcat.descripcion = (<HTMLInputElement> document.getElementById('nuevadescripcion')).value;
      this.modifiedcat.urlimagen = (<HTMLInputElement> document.getElementById('nuevaurlimagenicono')).value;
      this.modifiedcat.urlimagen2 = (<HTMLInputElement> document.getElementById('nuevaurlimagenportada')).value;
      this.modifiedcat.color = (<HTMLInputElement> document.getElementById('nuevocolor')).value;
      this.modifiedcat.idcoleccion=this.idcoleccion;
      if(this.idcategoria!=0){
        this.modifiedcat.idcategoria = this.idcategoria;

        this.modificarcat(this.modifiedcat);
        console.log(this.modifiedcat);
      }else{
        //Llamar al servicio de ingresar nueva coleccion
        this.ingresarnuevacat(this.modifiedcat);
      }
    }else{
      alert("Por favor ingrese los valores de la nueva colección");
    }

    }

    eliminarCategoria(){
      console.log(this.categoria);

      if(this.idcategoria == 0){
        alert("No se ha escogido una categoria a eliminar.");
      }else{
        this.coleccionService.eliminarCategoria(this.categoria).subscribe(
          res => {
            if(res){
              alert("Categoría eliminada con éxito");
            }else{
              alert("No se ha logrado eliminar la categoría.");
            }
          }
        )
      }
    }
    ingresarnuevacat(categoria: Categoria){
      this.coleccionService.ingresarCategoria(categoria).subscribe(
        res => {
          if(res){
            alert("Categoría ingresada con éxito");
          }else{
            alert("No se ha logrado ingresar la categoría.");
          }
        },
        err => console.log(err)
      )
      this.limpiarcampos();
      }
      modificarcat(categoria: Categoria){
        this.coleccionService.modificarCategoria(categoria).subscribe(
          res => {
            if(res){
              alert("Categoría modificada con éxito");
            }else{
              alert("No se ha logrado modificar la categoría.");
            }
          },
          err => console.log(err)
        )
        this.limpiarcampos();
        }

    onChangecoleccion(id){
      this.categorias = [];
      console.log(id);
      this.idcoleccion = id;
      this.getCategorias(id);
    }
    onChangecategoria(id){
      if(id != 0){
        this.idcategoria = id;
        this.getCategoria(id);
      }else if(id == 0){
        console.log(id);
        this.idcategoria = id;

        this.limpiarcampos();
      }
    }

    mostrarcategoria(categoria: Categoria){
      (<HTMLInputElement> document.getElementById('nuevonombre')).value = categoria.nombre;
      (<HTMLInputElement> document.getElementById('nuevadescripcion')).value = categoria.descripcion;
      (<HTMLInputElement> document.getElementById('nuevaurlimagenicono')).value = categoria.urlimagen;
      (<HTMLInputElement> document.getElementById('nuevaurlimagenportada')).value = categoria.urlimagen2;
      (<HTMLInputElement> document.getElementById('nuevocolor')).value = categoria.color;
    }
    limpiarcampos(){
      (<HTMLInputElement> document.getElementById('nuevonombre')).value = '';
      (<HTMLInputElement> document.getElementById('nuevadescripcion')).value = '';
      (<HTMLInputElement> document.getElementById('nuevaurlimagenicono')).value = '';
      (<HTMLInputElement> document.getElementById('nuevaurlimagenportada')).value = '';
      (<HTMLInputElement> document.getElementById('nuevocolor')).value = '';
    }
  }
