<div class="row">
	<app-side-bar></app-side-bar>
    <div class="col-sm-9">
		<h1 style="text-align: center;">Listado de Archivos</h1>
		<div class="row">
			<div class="col-md-11 col-md-offset-11">
				<a routerLink="/saled/newarchivo"><button type="button" class="btn btn-md btn-primary">Añadir</button></a>
				&nbsp;&nbsp;&nbsp;<a><button type="button" class="btn btn-md btn-success" (click)="refrescararchivos()">Refrescar</button></a>
			</div>
      <div class="col-md-4">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Buscar..." [(ngModel)]="listFilter">
              <span class="input-group-btn">
              </span>
        </div>
      </div>
    </div>
    <br>
		<div *ngIf="archivos" class="table-responsive">
			<table class="table">
				<tr>
					<td>
						Nombre del archivo
					</td>
					<td>
						Tipo
					</td>
					<td>
						Descripción
					</td>
					<td>
            Editar/Eliminar
          </td>
				</tr>
				<tr *ngFor = 'let archivo of archivosFiltered; let i = index'>
					<td>{{archivo.nombre}}</td>
					<td>{{archivo.tipoarchivo}}</td>
					<td>{{archivo.descripcion}}</td>
					<td><a><img src="/assets/img/delete.png" [routerLink]="['/newarchivo',archivo.idarchivo]" > </a><a><img src="/assets/img/edit.png" (click)="eliminarArchivo(archivo)"></a></td>
				 </tr>
			</table>
      </div>
      <div *ngIf="!archivos">
        <div>
          <div class="alert alert-info">
            <strong>¡No se encontraron archivos!</strong> Aun no se han registrado archivos en la base de datos.
          </div>
        </div>
      </div>
	</div><!--/.main-->
</div>
