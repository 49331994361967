import { tap, catchError, map } from 'rxjs/operators';
import { baseURL } from './baseUrl';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { Usuario } from '../modulos/user.model';

@Injectable({
    providedIn: 'root'
  })
  
  export class AdministuserService {
  
    headers: HttpHeaders;
    
  
  constructor(private http: HttpClient) { }
  /*Obtener los pedidos del back*/
  
  pedirusuario(): Observable<any>{
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.get<Usuario[]>(baseURL + '/administracion/listarusuarios', {headers: this.headers, observe: 'response'}).
      pipe(
        map((res: HttpResponse<any>) => {
          let jsonObject: any;
          if (res.status === 204) {
            //codigo invalido
            jsonObject = false;
          } else if (res.status === 200){
            jsonObject = res.body;
          }
          return [{ status: res.status, json: jsonObject}];
        }),
        catchError(this.handleError)
      );
  }
  
  getUser(id: number): Observable<Usuario> {
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.get<Usuario>(baseURL + `usuario/buscarusuarioid?idusuario=${id.toString()}`, {headers: this.headers})
    .pipe(
      catchError(this.handleError));
  }
  
  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
  }