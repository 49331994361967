import { Component, OnInit } from '@angular/core';
import { Coleccion } from 'src/app/modulos/coleccion.model';
import { ColeccionService } from 'src/app/servicios/coleccion.service';
import { ProductService } from 'src/app/servicios/product.service';
import { Categoria } from 'src/app/modulos/categoria.model';
import { ActivatedRoute } from '@angular/router';
import { AdministlibService } from 'src/app/servicios/administlib.service';
import { VideoService } from 'src/app/servicios/videos.service';
import { Video } from 'src/app/modulos/video.model';
import { Libroingreso } from 'src/app/modulos/libroingreso.model';
import { AdministkitService } from 'src/app/servicios/administkit.service';
import { Servicio } from 'src/app/modulos/servicio.model';
import { Librosadmin } from 'src/app/modulos/librosadmin.model';
import { Archivo } from 'src/app/modulos/archivo.model';
import { ArchivoService } from 'src/app/servicios/archivo.service';

@Component({
  selector: 'app-newkit',
  templateUrl: './newkit.component.html',
  styleUrls: ['./newkit.component.scss']
})
export class NewkitComponent implements OnInit {

  colecciones: Coleccion[];
  libros: Libroingreso[];
  categorias: Categoria[];
  grupovideos: String[];
  archivos: Archivo[];
  kit: Servicio;
  idcoleccionSeleccionada = 0;
  idcategoriaSeleccionada = 0;
  idlibroSeleccionado = 0;
  idvideoSelecionado = 0;
  idkitSeleccionado = '';
  tipoSeleccionado: number;
  errorMessage = '';
  idColeccion = 0;
  IdCategoria = 0;
  idLibro = 0;
  idVideo = 0;
  idKit = 0;
  existekit = false;
  eliminado = false;
  libs: Libroingreso[] = [];
  isChekedBook: boolean; //= false;
  isChekedArchive = false;
  isChekedVideo = false;
  idsLibros: number[] = [];
  idsArchivos: number[] = [];
  idsVideos: number[] = [];
  archivosseleccionados: Archivo[] = [];
  librosseleccionados: Libroingreso[] = [];
  grupovideosseleccionados: String[] = [];

  constructor(private coleccionServicio: ColeccionService,
    private route: ActivatedRoute,
    private administlibServicio: AdministlibService,
    private archivoservice: ArchivoService,
    private videoServicio: VideoService,
    private administkitServicio: AdministkitService) { }

  modifiedkit: Servicio = {
    idservicio: 0,
    nombre: '',
    tiposervicio: '',
    precio: 0,
    descripcion: '',
    codigoservicio: '',
    habilitado: true,
    idlibros: [],
    idvideos: [],
    idarchivos: []
  }

  // get accessor
  get value(): any {
    return this.isChekedBook;
  }

  // set accessor including call the onchange callback
  set value(value: any) {
    this.isChekedBook = value;
  }

  ngOnInit(): void {
    const param = this.route.snapshot.paramMap.get('id');
    if (param) {
      const id = +param;
      //this.getKit(id);
      this.idKit = +param;
      this.existekit = true;
    }
    this.obtenerColecciones();
    this.obtenerArchivos();
    this.obtenerNombreVideos();
  }

  obtenerColecciones() {
    this.coleccionServicio.getAllColeccion().subscribe(
      res => {
        this.colecciones = res;
      },
      err => console.log(err)
    );
  }

  obtenerCategorias(id: number) {
    this.coleccionServicio.getCategorias(id).subscribe(
      res => {
        this.categorias = res;
      },
      err => console.log(err)
    );
  }
  obtenerLibros(id: number) {
    this.administlibServicio.getBooksCat(id).subscribe(
      res => {
        this.libs = res;
      },
      err => console.log(err)
    );
  }
  obtenerArchivos() {
    this.archivoservice.getArchivos().subscribe(
      res => {
        if (res[0].json != undefined) {
          this.archivos = res[0].json;
        }
      },
      err => console.log(err)
    );
  }

  obtenerNombreVideos() {
    this.videoServicio.getgrupovideos().subscribe(
      res => {
        this.grupovideos = res;
      },
      err => console.log(err)
    );
  }

  onChangecoleccion(id) {
    this.idcoleccionSeleccionada = id;
    this.obtenerCategorias(id);
  }

  onChangecategoria(id) {
    if (id != 0) {
      this.idcategoriaSeleccionada = id;
      this.obtenerLibros(id);
    } else if (id == 0) {
      console.log(id);
    }
  }

  selecionlibro(libro: Libroingreso) {
    this.librosseleccionados.push(libro);
    console.log(libro);

  }

  selecionarchivo(archivo: Archivo) {
    this.archivosseleccionados.push(archivo);
  }

  selecionvideo(video: String) {
    this.grupovideosseleccionados.push(video);
  }

  quitarlibro(indice: number) {
    this.librosseleccionados.splice(indice, 1);
    console.log(indice);

  }

  quitararchivo(indice: number) {
    this.archivosseleccionados.splice(indice, 1);
  }

  quitarvideo(indice: number) {
    this.grupovideosseleccionados.splice(indice, 1);
  }

  seleccionlibro(idLibro: number, ischeked: boolean) {
    if (ischeked) {
      this.idsLibros.push(idLibro);
    } else {
      this.idsLibros.slice(idLibro);
    }
    console.log(this.isChekedBook);

    console.log(ischeked);

    console.log(this.idsLibros);

  }

  seleccionArchivo(idArchivo: number, ischeked: boolean) {
    if (ischeked) {
      this.idsArchivos.push(idArchivo);
    }
  }



  ingresarkit(kit: Servicio) {
    this.administkitServicio.ingresarkit(kit).subscribe(
      res => {
        if (res[0].json != undefined) {
          this.eliminado = res[0].json;
          if (this.eliminado === true) {
            alert("Kit ingresado con éxito.");
            this.eliminado = false;
          } else {
            alert("No se ha podido ingresar el kit");
          }
        }
      }
    )
  }

  guardarCambios() {
    if ((<HTMLInputElement>document.getElementById('nombrekit')).value !== '' &&
      (<HTMLInputElement>document.getElementById('preciokit')).value !== '' &&
      (<HTMLInputElement>document.getElementById('descripcionkit')).value !== '') {
      this.modifiedkit.nombre = (<HTMLInputElement>document.getElementById('nombrekit')).value;
      this.modifiedkit.precio = (Number)((<HTMLInputElement>document.getElementById('preciokit')).value);
      this.modifiedkit.descripcion = (<HTMLInputElement>document.getElementById('descripcionkit')).value;
      this.modifiedkit.idlibros.length = 0;
      this.modifiedkit.idarchivos.length = 0;
      this.modifiedkit.idvideos.length = 0;
      if (this.librosseleccionados.length !== 0 || this.grupovideosseleccionados.length !== 0 || this.archivosseleccionados.length !== 0) {
        this.librosseleccionados.forEach(libro => {
          this.modifiedkit.idlibros.push(libro.idlibro)
        });

        this.archivosseleccionados.forEach(archivo => {
          this.modifiedkit.idarchivos.push(archivo.idarchivo)
        });

        this.grupovideosseleccionados.forEach(video => {
          this.administkitServicio.getvideosid(video).subscribe(
            res => {
              if (res !== undefined) {
                res.forEach(id => this.modifiedkit.idvideos.push(id));
                this.modifiedkit.tiposervicio = 'KIT';
                this.modifiedkit.codigoservicio = '';
                //Llamar al servicio de ingresar nueva coleccion
                console.log(this.modifiedkit);
                if (this.modifiedkit !== undefined){
                  this.ingresarkit(this.modifiedkit);
                } else {
                  alert('Algo salió mal por favor intenta de nuevo');
                }
              }
            }
          );
        });
      } else {
        alert("Por favor elija elementos que pertenecerán al KIT.");
      }
    } else {
      alert("Por favor llene los campos.");
    }
  }
}
