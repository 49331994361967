import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Archivo } from 'src/app/modulos/archivo.model';
import { ArchivoService } from 'src/app/servicios/archivo.service';

@Component({
  selector: 'app-newarchivo',
  templateUrl: './newarchivo.component.html',
  styleUrls: ['./newarchivo.component.scss']
})
export class NewarchivoComponent implements OnInit {

  idArchivo = 0;
  existearchivo = false;
  eliminado = false;
  archivo: Archivo;
  errorMessage = '';

  constructor(private archivoServicio: ArchivoService,
    private route: ActivatedRoute) { }

    modifiedarchivo: Archivo = {
      idarchivo: 0,
      nombre: '',
      descripcion: '',
      urlimagen: '',
      urlarchivo: '',
      tipoarchivo: '',
      habilitado: true,
      precio: 0
      }

  ngOnInit(): void {
    const param = this.route.snapshot.paramMap.get('id');
    if (param) {
      const id = +param;
      this.getArchivo(id);
      this.idArchivo = +param;
      this.existearchivo = true;
      console.log(this.archivo);

    }

  }

  getArchivo(id: number) {
    this.archivoServicio.getArchivo(id).subscribe(
      archivo => this.archivo = archivo[0]?.json,
      err => this.errorMessage = err
    );
    console.log(this.archivo);
  }

  modificarArchivo(archivo: Archivo){
    this.archivoServicio.modificarArchivo(archivo).subscribe(
      res => {
        if(res[0].json != undefined){
          this.eliminado = res[0].json;
          if(this.eliminado === true){
            alert("Archivo modificado con éxito.");
            this.eliminado = false;
          }else{
            alert("No se ha podido modificar");
          }
        }
      }
    )
  }

  ingresarvideo(archivo: Archivo){
    this.archivoServicio.ingresarArchivo(archivo).subscribe(
      res => {
        if(res[0].json != undefined){
          this.eliminado = res[0].json;
          if(this.eliminado === true){
            alert("Archivo ingresado con éxito.");
            this.eliminado = false;
          }else{
            alert("No se ha podido ingresar el archivo");
          }
        }
      }
    )
  }

  guardarCambios(){
     if((<HTMLInputElement> document.getElementById('nombrearchivonew')).value !== '' &&
      (<HTMLInputElement> document.getElementById('descarchivonew')).value !== '' &&
      (<HTMLInputElement> document.getElementById('tipoarchivonew')).value !== '' &&
    (<HTMLInputElement> document.getElementById('urlarchivonew')).value !== '' ){
    this.modifiedarchivo.nombre = (<HTMLInputElement> document.getElementById('nombrearchivonew')).value;
    this.modifiedarchivo.tipoarchivo = (<HTMLInputElement> document.getElementById('tipoarchivonew')).value;
    this.modifiedarchivo.descripcion = (<HTMLInputElement> document.getElementById('descarchivonew')).value;
    this.modifiedarchivo.urlarchivo = (<HTMLInputElement> document.getElementById('urlarchivonew')).value;
     if(this.idArchivo != 0){
          this.modifiedarchivo.idarchivo = this.idArchivo;
          this.modificarArchivo(this.modifiedarchivo);
          console.log(this.modifiedarchivo);

        }else{
          //Llamar al servicio de ingresar nueva coleccion
          //console.log(this.modifiedarchivo);
          this.ingresarvideo(this.modifiedarchivo);

        }
        }else{
          alert("Por favor ingrese los campos del libro");
      }
  }

}
