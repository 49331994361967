<div class="row">
    <app-side-bar></app-side-bar>
    <div class="col-sm-9">
		<h1 style="text-align: center;">Ingresar/Modificar Informacion del Archivo</h1>

		<div class="panel panel-default">
			<div class="panel-heading">Formulario de información
			</div>
			<div class="panel-body">
				<div class="col-md-9">
					<form role="form">
						<hr>
						<div class="form-group">
							<label>Nombre</label>
								<input *ngIf="existearchivo" class="form-control" id="nombrearchivonew" placeholder="Ingrese el nombre del archivo" [value]="archivo?.nombre">
								<input *ngIf="!existearchivo" class="form-control" id="nombrearchivonew" placeholder="Ingrese el nombre del archivo">
								<hr>
								<label>Descripción</label>
								<textarea *ngIf="existearchivo" class="form-control" id="descarchivonew" rows="4" [value]="archivo?.descripcion"></textarea>
								<textarea *ngIf="!existearchivo" class="form-control" id="descarchivonew" rows="4" ></textarea>
								<br><hr>
								<label>Tipo Archivo</label>
								<input *ngIf="existearchivo" class="form-control" id="tipoarchivonew" placeholder="Ingrese la URL del archivo" [value]="archivo?.tipoarchivo">
								<input *ngIf="!existearchivo" class="form-control" id="tipoarchivonew" placeholder="Ingrese el tipo de archivo Ej: Plan Microcurricular, Bit de Inteligencia, etc.">
								<br><hr>
								<label>URL Archivo</label>
								<input *ngIf="existearchivo" class="form-control" id="urlarchivonew" placeholder="Ingrese la URL del archivo" [value]="archivo?.urlarchivo">
								<input *ngIf="!existearchivo" class="form-control" id="urlarchivonew" placeholder="Ingrese la URL del archivo">
						</div>


					<div class="row">
						<div class="col-md-3 col-md-offset-3"></div>
							<div class="col-md-3 col-md-offset-3"><button type="button" class="btn btn-lg btn-danger" (click)="guardarCambios()" routerLink="/saled/archivos">Guardar cambios</button></div>
					</div>
					</form>
				</div>
			</div>
</div>
