import { ProfesorGuard } from './guardianes/profesor.guard';
import { AuthGuard } from './guardianes/auth.guard';
import { MisRecursosComponent } from './components/mis-recursos/mis-recursos.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';
import { CartComponent } from './components/cart/cart.component';
import { CatagComponent } from './components/catag/catag.component';
import { CatalogoComponent } from './components/catalogo/catalogo.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ContactanosComponent } from './components/contactanos/contactanos.component';
import { DboardComponent } from './components/dboard/dboard.component';
import { GraciasComponent } from './components/gracias/gracias.component';
import { HomeComponent } from './components/home/home.component';
import { KitRecuComponent } from './components/kit-recu/kit-recu.component';
import { NosotrosComponent } from './components/nosotros/nosotros.component';
import { ProductComponent } from './components/product/product.component';
import { TiendaComponent } from './components/tienda/tienda.component';
import { MisArchivosComponent } from './components/mis-archivos/mis-archivos.component';
import { MisVideosComponent } from './components/mis-videos/mis-videos.component';
import { PuntosDeVentaComponent } from './components/puntos-de-venta/puntos-de-venta.component';
import { PruebaComponent } from './components/prueba/prueba.component';


const routes: Routes = [
  {
    path: 'product/:id', component: ProductComponent
  },
  {
    path: 'cart', component: CartComponent
  },
  {
    path: 'checkout', component: CheckoutComponent
  },
  {
    path: 'gracias', component: GraciasComponent
  },
  {
    path: 'contactanos', component: ContactanosComponent
  },
  {
    path: 'kit-recu', component: KitRecuComponent
  },
  {
    path: 'nosotros', component: NosotrosComponent
  },
  {
    path: 'tienda', component: TiendaComponent
  },
  {
    path: 'dboard', component: DboardComponent
  },
  {
    path: 'catalogo/:id', component: CatalogoComponent
  },
  {
    path: 'misrecursos', component: MisRecursosComponent, canActivate: [AuthGuard]
  },
  {
    path: 'misarchivos', component: MisArchivosComponent, canActivate: [AuthGuard, ProfesorGuard]
  },
  {
    path: 'misvideos', component: MisVideosComponent, canActivate: [AuthGuard, ProfesorGuard]
  },
  {
    path: 'puntos-de-venta', component: PuntosDeVentaComponent
  },
  {
    path: 'prueba', component: PruebaComponent
  },
  {
    path: 'saled',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: '', component: HomeComponent, pathMatch: 'full'
  },
  {
    path: 'catag/:id', component: CatagComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
