import { tap, catchError, map } from 'rxjs/operators';
import { baseURL } from './baseUrl';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { Libro } from '../modulos/libro.model';
import { Librosadmin } from './../modulos/librosadmin.model';
import { Libroingreso } from './../modulos/libroingreso.model';

/*const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };*/

@Injectable({
  providedIn: 'root'
})

export class AdministlibService {

  headers: HttpHeaders;


  constructor(private http: HttpClient) { }
  /*Obtener los libros/productos del back*/

  pedirlibs(): Observable<any> {
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.get<Librosadmin[]>(baseURL + 'administracion/listarlibrosadmin', { headers: this.headers, observe: 'response' }).
      pipe(
        map((res: HttpResponse<any>) => {
          let jsonObject: any;
          if (res.status === 204) {
            //codigo invalido
            jsonObject = false;
          } else if (res.status === 200) {
            jsonObject = res.body;
          }
          return [{ status: res.status, json: jsonObject }];
        }),
        catchError(this.handleError)
      );
  }

  getBook(id: number): Observable<Libroingreso> {
    return this.http.post<Libroingreso>(baseURL + `ServicioGenerales/buscarlibroadmin?idlibro=${id.toString()}`, null);
  }

  getBooksCat(id: number): Observable<Libroingreso[]> {
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.get<Libroingreso[]>(baseURL + `CRUDLibro/librosporcategoria?idcategoria=${id.toString()}`, { headers: this.headers })
      .pipe(
        //tap(data => console.log("All:" + JSON.stringify(data))),
        catchError(this.handleError));

  }

  eliminarBook(libro: Librosadmin) {
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<any>(baseURL + 'CRUDLibro/eliminarlibro', libro, { headers: this.headers, observe: 'response' }).
      pipe(
        map((res: HttpResponse<any>) => {
          let jsonObject: any;
          if (res.status === 204) {
            //codigo invalido
            jsonObject = false;
          } else if (res.status === 200) {
            jsonObject = res.body;
          }
          return [{ status: res.status, json: jsonObject }];
        }),
        catchError(this.handleError)
      );
  }

  ingresarBook(libro: Libroingreso) {
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<any>(baseURL + 'CRUDLibro/ingresarlibro', libro, { headers: this.headers, observe: 'response' }).
      pipe(
        map((res: HttpResponse<any>) => {
          let jsonObject: any;
          if (res.status === 204) {
            //codigo invalido
            jsonObject = false;
          } else if (res.status === 200) {
            jsonObject = res.body;
          }
          return [{ status: res.status, json: jsonObject }];
        }),
        catchError(this.handleError)
      );
  }

  modificarBook(libro: Libroingreso) {
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<any>(baseURL + 'CRUDLibro/modificarlibro', libro, { headers: this.headers, observe: 'response' }).
      pipe(
        map((res: HttpResponse<any>) => {
          let jsonObject: any;
          if (res.status === 204) {
            //codigo invalido
            jsonObject = false;
          } else if (res.status === 200) {
            jsonObject = res.body;
          }
          return [{ status: res.status, json: jsonObject }];
        }),
        catchError(this.handleError)
      );
  }


  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
