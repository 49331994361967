<div class="container">
  <div class="row">
    <div class="col-med-12 col-lg-12">
      <img src="/assets/img/banner.jpeg" class="img-responsive" alt="Responsive image">
    </div>
  </div>
  <br>
  <br>
  <div class="row">
    <div class="col-lg-6">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Ingrese código de activación" [(ngModel)]="codigo">
        <span class="input-group-btn">
          <button class="btn btn-primary" type="button" (click)="activarRecursos(this.codigo)">Ingresar</button>
        </span>
      </div><!-- /input-group -->
    </div><!-- /.col-lg-6 -->
  </div>
  <div class="row">
    <h2 style="text-align: center;">Mis Libros</h2>
  </div>
  <div class="row" *ngIf="books">
    <div class="col-md-4 col-sm-4" *ngFor="let book of books; let i = index">
      <div class="thumbnail" >
        <div style="text-align: center;">
          <p><strong>{{book?.titulo}}</strong></p>
        </div>
        <img [src]="book?.urlimagen" alt="{{book?.titulo}}" width="200px" class="card-image" style="cursor: pointer;">
        <div class="caption">
          <p><strong>Autores: </strong>{{book?.autores}}</p>
        </div>
        <div class="buttons">
          <!-- <a [href]="book?.Urllibropdf" class="btn btn-default" role="button">Leer</a> -->
          <button (click)="pasarUrlLibro(book?.urllibropdf)">Leer</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="books.length === 0">
    <div class="alert alert-warning">
      <strong>¡Nota!</strong> Si has adquirido nuestros libros ingresa el código de activación enviado a tu correo.
    </div>
    <div class="alert alert-info">
      <strong>¡No se encontraron libros!</strong> Adquiere uno de nuestros libros en <a class="alert-link" routerLink='/tienda'>tienda</a>.
    </div>
  </div>
  <div class="row" *ngIf="this.imgsrc !== ''">
    <div class="col-md-12">
      <ngx-extended-pdf-viewer
        [src]="this.imgsrc"
        [useBrowserLocale]="true"
        [height]="'90vh'"
        language="es-ES"
        [contextMenuAllowed]="false"
        [showDownloadButton]="false"
        [showPrintButton]="false"
        [showOpenFileButton]="false"
        [showPresentationModeButton]="true"
        [zoom]="'page-fit'"
        [hidden]="this.imgsrc === ''">
      </ngx-extended-pdf-viewer>
    </div>
  </div>
</div>
<br>
<br>
