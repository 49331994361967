import { Component, OnInit } from '@angular/core';
import { Librosadmin } from 'src/app/modulos/librosadmin.model';
import { AdministlibService } from 'src/app/servicios/administlib.service';

@Component({
  selector: 'app-dboard',
  templateUrl: './dboard.component.html',
  styleUrls: ['./dboard.component.scss']
})
export class DboardComponent implements OnInit {


  libs: Librosadmin [];

  constructor(private administlibServicio: AdministlibService) { }

  ngOnInit(): void {
    
  }

}
