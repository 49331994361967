<div class="row">
	<app-side-bar></app-side-bar>
    <div class="col-sm-9">
        <h1 style="text-align: center;">Ingreso de nuevo libro</h1>
		<div class="panel panel-default">
			<div class="panel-heading">Formulario de información</div>
			<div class="panel-body">
				<div class="col-md-9">
					<form >
						<div class="form-group">
							<label for="coleccion">Seleccione la Colección</label>
							<select class="form-control" id="coleccion" (change)="onChangecoleccion($event.target.value)">
								<option hidden selected>Seleccione una Colección</option>
								<option *ngFor = 'let coleccion of colecciones' [value]="coleccion?.idcolecion" >{{coleccion.nombre}}</option>
							</select>
						</div>
						<hr>
						<div class="form-group">
							<label for="categoria">Ingrese la Categoría</label>
							<select class="form-control" id="categoria" (change)="onChangecategoria($event.target.value)">
								<option hidden selected>Seleccione una Categoría</option>
								<option *ngFor = 'let categoria of categorias' [value]="categoria?.idcategoria">{{categoria.nombre}}</option>
							</select>
						</div>
						<hr>
						<div class="form-group">
							<label>Título</label>
							<input *ngIf="existelibro" class="form-control" id="nuevotitulo" placeholder="Ingrese el título del libro" [value]="libro?.titulo">
							<input *ngIf="!existelibro" class="form-control" id="nuevotitulo" placeholder="Ingrese el título del libro">
							<label>Autor(es)</label>
							<input *ngIf="existelibro" class="form-control" id="nuevoautor" placeholder="Ingrese los nombres del (los) autor(es)" [value]="libro?.autores">
							<input *ngIf="!existelibro" class="form-control" id="nuevoautor" placeholder="Ingrese los nombres del (los) autor(es)">
							<label>Sinopsis</label>
									<textarea *ngIf="existelibro" class="form-control" id="nuevasinopsis" rows="4" [value]="libro?.sinopsis"></textarea>
									<textarea *ngIf="!existelibro" class="form-control" id="nuevasinopsis" rows="4" ></textarea>
							<label>Rango edad</label>
							<input *ngIf="existelibro" class="form-control" id="nuevorango" placeholder="Ingrese el rango de edad (ej.: 2 a 3 años)" [value]="libro?.rangoedad">
							<input *ngIf="!existelibro" class="form-control" id="nuevorango" placeholder="Ingrese el rango de edad (ej.: 2 a 3 años)">
							<label>Tipo</label>
								<select *ngIf="existelibro" class="form-control" id= "nuevotipo" [value]="libro?.tipo">
									<option hidden selected>Seleccione una opción</option>
									<option>Fisico</option>
									<option>Digital</option>
									<option>Físico/Digital</option>
								</select>
								<select *ngIf="!existelibro" class="form-control" id= "nuevotipo">
									<option hidden selected>Seleccione una opción</option>
									<option>Fisico</option>
									<option>Digital</option>
									<option>Físico/Digital</option>
								</select>
							<label>Precio</label>
							<input *ngIf="existelibro" class="form-control" id="nuevoprecio" placeholder="Ingrese el precio" [value]="libro?.precio">
							<input *ngIf="!existelibro" class="form-control" id="nuevoprecio" placeholder="Ingrese el precio">
							<h4>Ingrese la URL de la imagen del libro</h4>
							<input *ngIf="existelibro" class="form-control" id="nuevaurlImg" placeholder="Ingrese la URL Ej.: " [value]="libro?.urlimagen">
							<input *ngIf="!existelibro" class="form-control" id="nuevaurlImg" placeholder="Ingrese la URL Ej.: https://documetossalazareditores.s3.us-east-2.amazonaws.com/Libros/">
							<h4>Seleccione la URL del pdf del libro</h4>
							<input *ngIf="existelibro" class="form-control" id="nuevaurlPdf" placeholder="Ingrese la URL Ej.: " [value]="libro?.urllibropdfcompleto">
              <input *ngIf="!existelibro" class="form-control" id="nuevaurlPdf" placeholder="Ingrese la URL Ej.: https://documetossalazareditores.s3.us-east-2.amazonaws.com/Libros/" >
              <h4>Seleccione la URL del pdf del libro recortado</h4>
							<input *ngIf="existelibro" class="form-control" id="nuevaurlPdfrecortado" placeholder="Ingrese la URL Ej.: " [value]="libro?.urllibropdf">
							<input *ngIf="!existelibro" class="form-control" id="nuevaurlPdfrecortado" placeholder="Ingrese la URL Ej.: https://documetossalazareditores.s3.us-east-2.amazonaws.com/Libros/" >
							</div>


					<div class="row">
						<div class="col-md-3 col-md-offset-3"></div>
						<div class="col-md-3 col-md-offset-3"><button type="button" class="btn btn-lg btn-danger" (click)="guardarCambios()" routerLink="/saled/books">Guardar cambios</button></div>
					 </div>
					</form>
				</div>
			</div>
		</div>
