<div class="row">
    <app-side-bar></app-side-bar>
    <div class="col-sm-9">
		<h1 *ngIf="!existecoleccion" style="text-align: center;">Ingreso de nueva Colección</h1>
		<h1 *ngIf="existecoleccion" style="text-align: center;">Modificar Colección</h1>
		<div class="panel panel-default">
			<div class="panel-heading">Formulario de información
			</div>
			<div class="panel-body">
				<div class="col-md-9">
					<form role="form">
						<hr>
						<div class="form-group">
							<label>Nombre Colección</label>
							<input *ngIf="!existecoleccion" class="form-control" id="nuevonombre" placeholder="Ingrese el título de la Colección">
							<input *ngIf="existecoleccion" class="form-control" id="nuevonombre" [value]="coleccion?.nombre">
							<label>{{modifiedcoleccion?.nombre}}</label>
							<label>Descripción</label>
									<textarea *ngIf="!existecoleccion" class="form-control" rows="3" id="nuevadescripcion"></textarea>
									<textarea *ngIf="existecoleccion" class="form-control" rows="3" id="nuevadescripcion" [value]="coleccion?.descripcion"></textarea>
							</div>
							<br>
							<h4>Ingrese la url de la imagen</h4>
							<input *ngIf="!existecoleccion" class="form-control" id="nuevaurl" placeholder="Ingrese la url de imagen de colección">
							<input *ngIf="existecoleccion" class="form-control" id="nuevaurl" [value]="coleccion?.urlimagen">
                            <br>
							<br>
							<h4>Ingrese la url del catalogo</h4>
							<input *ngIf="!existecoleccion" class="form-control" id="nuevaurlcatalogo" placeholder="Ingrese la url del catalogo">
							<input *ngIf="existecoleccion" class="form-control" id="nuevaurlcatalogo" [value]="coleccion?.urlpdfcatalogo">
							<br>
					<div class="row">
						<div class="col-md-3 col-md-offset-3"></div>
						<div><button *ngIf="existecoleccion" type="button" class="btn btn-lg btn-success" (click)="guardarCambios()">Guardar cambios</button>
							 <button *ngIf="!existecoleccion" type="button" class="btn btn-lg btn-success" (click)="guardarCambios()" routerLink="/saled/newcat">Ingresar coleccion</button>
							<button *ngIf="existecoleccion" type="button" class="btn btn-lg btn-danger" (click)="eliminarColeccion(coleccion)" routerLink="/saled/dashboard">Eliminar Colección</button>
							</div>
						</div>
					</form>
				</div>
			</div>




</div>
