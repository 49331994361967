import { MisRecursosService } from './servicios/mis-recursos.service';
import { RegistroService } from './servicios/registro.service';
import { LoginService } from './servicios/login.service';
import { ReadingJsonFilesService } from './servicios/reading-json-file.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CartComponent } from './components/cart/cart.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { HomeComponent } from './components/home/home.component';
import { ProductComponent } from './components/product/product.component';
import { GraciasComponent } from './components/gracias/gracias.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { LoginComponent } from './components/login/login.component';
import { NosotrosComponent } from './components/nosotros/nosotros.component';
import { ContactanosComponent } from './components/contactanos/contactanos.component';
import { KitRecuComponent } from './components/kit-recu/kit-recu.component';
import { TiendaComponent } from './components/tienda/tienda.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RegisterComponent } from './components/register/register.component';
import { IntitucionesComponent } from './components/intituciones/intituciones.component';
import { CatalogoComponent } from './components/catalogo/catalogo.component';
import { ProductService } from './servicios/product.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ColeccionService } from './servicios/coleccion.service';
import { CatagComponent } from './components/catag/catag.component';
import { DboardComponent } from './components/dboard/dboard.component';
import { MisRecursosComponent } from './components/mis-recursos/mis-recursos.component';
import { AdminModule } from './components/admin/admin.module';
import { VideoService } from './servicios/videos.service';
import { ArchivoService } from './servicios/archivo.service';

import { MisArchivosComponent } from './components/mis-archivos/mis-archivos.component';
import { MisVideosComponent } from './components/mis-videos/mis-videos.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaginatorModule } from './components/paginator/paginator.module';
import { VgBufferingModule, VgControlsModule, VgCoreModule, VgOverlayPlayModule } from 'ngx-videogular';
import { PuntosDeVentaComponent } from './components/puntos-de-venta/puntos-de-venta.component';
import { PruebaComponent } from './components/prueba/prueba.component';




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    CartComponent,
    CheckoutComponent,
    HomeComponent,
    ProductComponent,
    GraciasComponent,
    CarouselComponent,
    LoginComponent,
    NosotrosComponent,
    ContactanosComponent,
    KitRecuComponent,
    TiendaComponent,
    RegisterComponent,
    IntitucionesComponent,
    CatalogoComponent,
    CatagComponent,
    DboardComponent,
    MisRecursosComponent,
    MisArchivosComponent,
    MisVideosComponent,
    PuntosDeVentaComponent,
    PruebaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    AdminModule,
    BrowserAnimationsModule,
    PaginatorModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ],
  providers: [ReadingJsonFilesService, ProductService, LoginService, RegistroService, ColeccionService, VideoService, ArchivoService ], 
  bootstrap: [AppComponent]
})
export class AppModule { }
platformBrowserDynamic().bootstrapModule(AppModule);
