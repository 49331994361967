<div class="row">
    <app-side-bar></app-side-bar>
       <div class="col-sm-9">
            <!--/.row-->

            <div class="row">
                <div class="col-lg-12">
                    <h1 class="page-header" style="text-align: center;">Panel Administrativo</h1>
                </div>
            </div><!--/.row-->

            <div class="panel panel-container">
                <div class="row">

                        <img src="/assets/img/slider01.jpg" class="img-responsive" style="width: 80%; display: block; margin: auto;">
                </div>
            </div>

            <h1 style="text-align: center;">Colecciones</h1>
            <div class="container">
                <div class="col-md-4 col-sm-4" *ngFor="let coleccion of colecciones; let i = index">
                    <div class="thumbnail" >
                    <div style="text-align: center;">
                        <p><strong>{{coleccion.nombre}}</strong></p>
                    </div>
                    <img [src]="coleccion.urlimagen" alt="{{coleccion.nombre}}" width="200px" class="card-image" style="cursor: pointer;">
                    <div class="buttons">
                        <a [routerLink]="['/newcol',coleccion.idcolecion]" class="btn btn-default" role="button">Editar colección</a>
                    </div>
                    </div>
                </div>
            </div>
            <!--/.main-->


            <div class="col-sm-12" style="margin-top: 60px;">
            <p class="back-link">Desarrollado por <a href="https://www.facebook.com/shebas.bahamonde/">Sindetel</a></p>
        </div>
</div>

