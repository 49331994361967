import { Component, OnInit } from '@angular/core';
import { ColeccionService } from 'src/app/servicios/coleccion.service';
import { Coleccion } from 'src/app/modulos/coleccion.model';
import { ProductService } from 'src/app/servicios/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Servicio } from 'src/app/modulos/servicio.model';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { log } from 'console';

@Component({
  selector: 'app-newcol',
  templateUrl: './newcol.component.html',
  styleUrls: ['./newcol.component.scss']
})
export class NewcolComponent implements OnInit {

  servicios: Servicio[] = [];

  id: 0;
  idcoleccion = 0;
  errorMessage = '';
  coleccion: Coleccion;
  existecoleccion = false;
  ingresado = false;
  modificado = false;
  eliminado = false;
  modifiedcoleccion: Coleccion =
  {
    idcolecion: 0,
    nombre: '',
    descripcion: '',
    urlimagen: '',
    urlvideo: '',
    urlpdfcatalogo: '',
    categorias: []
   };

  constructor(private productService: ProductService,
    public coleccionService: ColeccionService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router) { }


  ngOnInit(): void {


    const param = this.route.snapshot.paramMap.get('id');
    if (param) {
      const id = +param;
      this.getColeccion(id);
      this.idcoleccion = +param;

    }
  }
    getColeccion(id: number) {
      this.coleccionService.getColeccion(id).subscribe({
        next: coleccion => this.coleccion = coleccion,
        error: err => this.errorMessage = err
      });
      this.existecoleccion = true;
      console.log(this.coleccion);
    }

    guardarCambios(){
      if((<HTMLInputElement> document.getElementById('nuevonombre')).value !== '' &&
      (<HTMLInputElement> document.getElementById('nuevadescripcion')).value !== '' &&
      (<HTMLInputElement> document.getElementById('nuevaurl')).value !== '' &&
      (<HTMLInputElement> document.getElementById('nuevaurlcatalogo')).value !== ''){
        this.modifiedcoleccion.nombre = (<HTMLInputElement> document.getElementById('nuevonombre')).value;
        this.modifiedcoleccion.descripcion = (<HTMLInputElement> document.getElementById('nuevadescripcion')).value;
        this.modifiedcoleccion.urlimagen = (<HTMLInputElement> document.getElementById('nuevaurl')).value;
        this.modifiedcoleccion.urlpdfcatalogo = (<HTMLInputElement> document.getElementById('nuevaurlcatalogo')).value;

        if(this.existecoleccion){
          this.modifiedcoleccion.idcolecion = this.idcoleccion;
          this.modificarcoleccion(this.modifiedcoleccion);
          console.log(this.modifiedcoleccion);

        }else{
          //Llamar al servicio de ingresar nueva coleccion
          this.ingresarnueva(this.modifiedcoleccion);

        }
      }else{
        alert("Por favor ingrese los valores de la nueva colección");
      }

      //this.userForm.reset();
    }
    ingresarnueva(coleccion: Coleccion){
     this.coleccionService.NuevaColeccion(coleccion).subscribe(
       res => {
        if(res[0].json != undefined){
          this.ingresado = res[0].json;
          console.log(this.ingresado);
          if(this.ingresado === true){
            alert("Se ha ingresado la coleccion con éxito.");
            this.ingresado = false;
          }else{
            alert("No se ha ingresado la colección");
          }
        }
       }
     )
    }
    modificarcoleccion(coleccion: Coleccion){
      this.coleccionService.ModificarColeccion(coleccion).subscribe(
        res => {
         if(res[0].json != undefined){
           this.modificado = res[0].json;
           if(this.modificado === true){
            alert("Se ha modificado la coleccion con éxito.");
            this.modificado = false;
          }else{
            alert("No se ha modificado la colección");
          }
         }
        }
      )
     }

     eliminarColeccion(coleccion: Coleccion){
       console.log(coleccion);

      this.coleccionService.eliminarColeccion(coleccion).subscribe(
        res => {
         if(res[0].json != undefined){
           this.eliminado = res[0].json;
           if(this.eliminado === true){
            alert("Se ha eliminado la coleccion con éxito.");
            this.eliminado = false;
          }else{
            alert("No se ha eliminado la colección");
          }
         }
        }
      )
     }
}
