<div class="row">
    <app-side-bar></app-side-bar>
    <div class="col-sm-9">
        <h1 style="text-align: center;">Modificar datos del Usuario</h1>
		<div class="panel panel-default">
			<div class="panel-heading">Formulario de información</div>
			<div class="panel-body">
				<div class="col-md-9">
					<form role="form">
						<div class="form-group">

                            <label>Nombres</label>
							<input class="form-control" id="nombres" [value]="usuario?.nombres">
							<label>Apellidos</label>
                            <input class="form-control" id="apellidos" [value]="usuario?.apellidos">
                            <label>Correo</label>
							<input class="form-control" id="correo" [value]="usuario?.correo">
                            <label>Dirección</label>
							<input class="form-control" id="direccion" [value]="usuario?.direccion">
                            <label>Teléfono</label>
							<input class="form-control" id="telefonouser" [value]="usuario?.telefono">
                            <label>Fecha de nacimiento</label>
							<input type="date" class="form-control" id="birthdateuser" [value]="usuario?.fechanacimiento">
                            <label>Tipo de usuario</label>
                            <select class="form-control" id="tipouser" [value]="usuario?.tipo"> <!--falta este metodo (change)="onChangecoleccion($event.target.value)-->
                            <option hidden selected>Seleccione una opción</option>
                                <option>Estudiante</option>
                                <option>Docente</option>
                                <option>Particular</option>
                            </select>
                            <label>Seleccione el nivel</label>
                            <select class="form-control" id="niveluser" [value]="usuario?.nivel"> <!--falta este metodo (change)="onChangecoleccion($event.target.value)-->
                                <option hidden selected>Seleccione una opción</option>
                                <option>Inicial</option>
                                <option>General básica</option>
                                <option>Bachillerato</option>
                            </select>
                        </div>
                        <div>
                            <label>Recursos Asignados</label>
                            <table>
                                <tr *ngFor = 'let item of books'>{{item.titulo}}</tr>
                                <!--<tr *ngFor="item of books">{{item.titulo}}</tr>-->
                            </table>
                        </div>


					<div class="row">
						<div class="col-md-3 col-md-offset-3"></div>
						<div class="col-md-3 col-md-offset-3"><button type="button" class="btn btn-lg btn-danger" (click)="guardar()" routerLink="/saled/usuarios">Guardar cambios</button></div>
					 </div>
					</form>
				</div>
			</div>

</div>
