import { Video } from './../modulos/video.model';
import { baseURL } from './baseUrl';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap} from 'rxjs/operators';
import { Libroingreso } from '../modulos/libroingreso.model';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  
  @Injectable({
    providedIn: 'root'
  })
  export class VideoService {
  
    headers: HttpHeaders;

    /*private SERVER_URL = environment.SERVER_URL;*/
    constructor(private http: HttpClient) { }
    getVideos(): Observable<any>{
        this.headers = new HttpHeaders ({
          "Content-Type": "application/json",
          Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
        });
        return this.http.get<Video[]>(baseURL + 'CRUDVideo/listarvideos', {headers: this.headers, observe: 'response'}).
          pipe(
            map((res: HttpResponse<any>) => {
              let jsonObject: any;
              if (res.status === 204) {
                //codigo invalido
                jsonObject = false;
              } else if (res.status === 200){
                jsonObject = res.body;
              }
              return [{ status: res.status, json: jsonObject}];
            }),
            catchError(this.handleError)
          );
      }

      eliminarVideo(video: Video)
  {
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<any>(baseURL + 'CRUDVideo/eliminarvideo', video, {headers: this.headers, observe: 'response'}).
    pipe(
      map((res: HttpResponse<any>) => {
        let jsonObject: any;
        if (res.status === 204) {
          //codigo invalido
          jsonObject = false;
        } else if (res.status === 200){
          jsonObject = res.body;
        }
        return [{ status: res.status, json: jsonObject}];
      }),
      catchError(this.handleError)
    );
  }

  ingresarVideo(video: Video)
  {
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<any>(baseURL + 'CRUDVideo/ingresovideo', video, {headers: this.headers, observe: 'response'}).
    pipe(
      map((res: HttpResponse<any>) => {
        let jsonObject: any;
        if (res.status === 204) {
          //codigo invalido
          jsonObject = false;
        } else if (res.status === 200){
          jsonObject = res.body;
        }
        return [{ status: res.status, json: jsonObject}];
      }),
      catchError(this.handleError)
    );
  }

  modificarVideo(video: Video)
  {
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<any>(baseURL + 'CRUDVideo/modificarvideo', video, {headers: this.headers, observe: 'response'}).
    pipe(
      map((res: HttpResponse<any>) => {
        let jsonObject: any;
        if (res.status === 204) {
          //codigo invalido
          jsonObject = false;
        } else if (res.status === 200){
          jsonObject = res.body;
        }
        return [{ status: res.status, json: jsonObject}];
      }),
      catchError(this.handleError)
    );
  }

  getVideo(id: number): Observable<Video> {
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.get<Video>(baseURL + `CRUDVideo/buscarvideoid?idvideo=${id.toString()}`, {headers: this.headers})
    .pipe(
      //tap(data => console.log("All:" + JSON.stringify(data))),
      catchError(this.handleError));
    
  }
    getgrupovideos(){
      this.headers = new HttpHeaders ({
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
      });
      return this.http.get<String[]>(baseURL + `CRUDVideo/listarlibrosconvideo`, {headers: this.headers})
      .pipe(
        //tap(data => console.log("All:" + JSON.stringify(data))),
        catchError(this.handleError));
      
    }
  
      
  
      private handleError(err: HttpErrorResponse) {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          errorMessage = `An error occurred: ${err.error.message}`;
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
      }
  }
  