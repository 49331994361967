<div class="container">
  <div class="row">
    <div class="col-med-12 col-lg-12">
      <img src="/assets/img/banner.jpeg" class="img-responsive" alt="Responsive image">
    </div>
  </div>
  <br>
  <br>
  <div class="row">
    <h2 style="text-align: center;">Mis Archivos</h2>
  </div>
  <div class="row" *ngIf="archivos">
    <div class="col-md-4 col-sm-4" *ngFor="let archivo of archivos; let i = index">
      <div class="thumbnail" >
        <div style="text-align: center;">
          <p><strong>{{archivo?.nombre}}</strong></p>
        </div>
        <img src="/assets/img/google-docs.png" alt="{{archivo?.nombre}}" width="200px" class="card-image" style="cursor: pointer;">
        <div class="caption">
          <p><strong>Descripción: </strong>{{archivo?.descripcion}}</p>
        </div>
        <div class="buttons">
          <!-- <a [href]="book?.Urllibropdf" class="btn btn-default" role="button">Leer</a> -->
          <button (click)="pasarUrlLibro(archivo?.urlarchivo)">Leer</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="this.pdfsrc === ''">
    <h4 class="text-primary" style="text-align: center;">Selecciona un archivo para leer</h4>
  </div>
  <div class="row" *ngIf="this.pdfsrc !== ''">
    <div class="col-md-12">
      <ngx-extended-pdf-viewer
        [src]="this.pdfsrc"
        [useBrowserLocale]="true"
        [height]="'90vh'"
        language="es-ES"
        [contextMenuAllowed]="false"
        [showDownloadButton]="true"
        [showPrintButton]="false"
        [showOpenFileButton]="false"
        [showPresentationModeButton]="true"
        [zoom]="'page-fit'"
        [hidden]="this.pdfsrc === ''">
      </ngx-extended-pdf-viewer>
    </div>
  </div>
</div>
<br>
<br>
