import { Libro } from './../../modulos/libro.model';
import { Router } from '@angular/router';
import { MisRecursosService } from './../../servicios/mis-recursos.service';
import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './mis-recursos.component.html',
  styleUrls: ['./mis-recursos.component.scss']
})
export class MisRecursosComponent implements OnInit {

  codigo = '';
  books: Libro[] = [];
  imgsrc = '';
  constructor(private misrecursos: MisRecursosService, private route: Router) { }

  ngOnInit(): void {
    this.getLibros();
  }

  activarRecursos(codigo: string){
    if (codigo !== ''){
      this.misrecursos.activarServicios(codigo).subscribe({
        next: response => {
          console.log('activación: ' + response[0].json);
          if(response[0].json != undefined){
            if(response[0].json){
              this.getLibros();
              alert('Código ingresado exitosamente');
            }else{
              alert('El Código ingresado no es válido');
            }
          }
        },
        error: err => console.log(err)
      });
    }else {
      alert('Por favor ingrese un código');
    }
  }

  getLibros(){
    this.misrecursos.getLibrosAutorizados().subscribe({
      next: recursos => {
        this.books = recursos;
      },
      error: err => console.log(err)
    });
  }

  pasarUrlLibro(imgsrc: string){
    this.imgsrc = imgsrc;
  }
}
