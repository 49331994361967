import { CarritoService } from './../../servicios/carrito.service';
import { ReadingJsonFilesService } from './../../servicios/reading-json-file.service';
import { Libro } from './../../modulos/libro.model';
import { Component, Input, OnInit } from '@angular/core';
import { ProductService } from 'src/app/servicios/product.service';

@Component({
  selector: 'app-tienda',
  templateUrl: './tienda.component.html',
  styleUrls: ['./tienda.component.scss']
})
export class TiendaComponent implements OnInit {

  books: Libro[] = [];
  filteredBooks: Libro[];
  errorMessage = '';
  carritoLleno: Libro[] = [];
  indice: number;
  private book: Libro = {
    idlibro: 0,
    titulo: '',
    autores: '',
    sinopsis: '',
    tipo: '',
    precio: 0,
    urlimagen: '',
    urllibropdf: '',
    destacado: false,
    rangoedad: '',
    categoria: 0,
    coleccion: 0
  };
  pageOfItems: Array<any>;
  _listFilter: string;
  get listFilter(): string {
    return this._listFilter;
  }
  set listFilter(value: string) {
    this._listFilter = value;
    this.filteredBooks = this.listFilter ? this.performFilter(this.listFilter) : this.books;
  }

  constructor(private productService: ProductService, private jsonFIle: ReadingJsonFilesService, private carrito: CarritoService) { }

  ngOnInit(){
    this.productService.getAllBooks().subscribe({
      next: books => {
        this.books = books;
        this.filteredBooks = this.books;
        //console.log(this.books);
      },
      error: err => this.errorMessage = err
    });
    /*this.books = this.jsonFIle.getLibros();
    console.log(this.books);*/
    //this.carrito.sendCarrito(this.carritoLleno);
  }

  agregarAlCarrito(idlibro: number, tipo: string){
    // this.book = libro;
    this.productService.getBook(idlibro).subscribe({
      next: product => {
        if (product !== undefined){
          this.book = product;
          this.book.tipo = tipo;
          this.carritoLleno.push(this.book);
          this.carrito.sendLibroCarrito(this.carritoLleno);
          //console.log(this.book);
        }
      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    });
    //this.carrito.addItem(libro, tipo);
  }

  performFilter(filterBy: string): Libro[] {
    filterBy = filterBy.toLocaleLowerCase();
    return this.books.filter((product: Libro) =>
          product.titulo.toLocaleLowerCase().indexOf(filterBy) !== -1);
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

}
