<div style="background-image: url(../../../assets/img/ilustracion_magic_english.jpg); background-repeat: no-repeat; background-position: center;">
    <br><h1 style="text-align: center; font-family: cursive;">Puntos de venta</h1><br>
    <div class="container">
        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="false">
            <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingOne">
                <h4 class="panel-title">
                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <h2 class="fa fa-map-marker fa-2x">&nbsp;Costa</h2>
                </a>
                </h4>
            </div>
            <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">
                    <ul class="list-group">
                        <li class="list-group-item list-group-item-info"><a href="#">Manta</a></li>
                        <li class="list-group-item list-group-item-info"><a href="#">Guayaquil</a></li>
                        <li class="list-group-item list-group-item-info"><a href="#">Portoviejo</a></li>
                        <li class="list-group-item list-group-item-info"><a href="#">Esmeraldas</a></li>
                        <li class="list-group-item list-group-item-info"><a href="#">Chone</a></li>
                    </ul>
                </div>
            </div>
            </div>
            <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingTwo">
                <h4 class="panel-title">
                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <h2 class="fa fa-map-marker fa-2x">&nbsp;Sierra</h2>
                </a>
                </h4>
            </div>
            <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                <div class="panel-body">
                    <ul class="list-group">
                        <li class="list-group-item list-group-item-info"><a href="#">Quito</a></li>
                        <li class="list-group-item list-group-item-info"><a href="#">Cuenca</a></li>
                        <li class="list-group-item list-group-item-info"><a href="#">Riobamba</a></li>
                        <li class="list-group-item list-group-item-info"><a href="#">Loja</a></li>
                        <li class="list-group-item list-group-item-info"><a href="#">Ibarra</a></li>
                    </ul>
                </div>
            </div>
            </div>
            <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingThree">
                <h4 class="panel-title">
                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <h2 class="fa fa-map-marker fa-2x">&nbsp;Oriente</h2>
                </a>
                </h4>
            </div>
            <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                <div class="panel-body">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item list-group-item-info"><a href="#">Puyo</a></li>
                        <li class="list-group-item list-group-item-info"><a href="#">Lago Agrio</a></li>
                        <li class="list-group-item list-group-item-info"><a href="#">Macas</a></li>
                        <li class="list-group-item list-group-item-info"><a href="#">Tena</a></li>
                        <li class="list-group-item list-group-item-info"><a href="#">Pastaza</a></li>
                    </ul>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>