import { Observable, throwError } from 'rxjs';
import { Contactanos } from './../modulos/contactanos.model';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseURL } from './baseUrl';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactarService {

  headers: HttpHeaders;
  contactanos: Contactanos;
  mensaje: Contactanos = {
    Nombreremitente: ' ',
    Correoremitente: ' ',
    Mensajebody: ' '
  }
  message: string;
  constructor(private http: HttpClient) { }

  setCorreo(mensaje: Contactanos){
    this.mensaje = mensaje;
    // let body = new HttpParams()
    // .set('CorreoRemitente', this.mensaje.Correoremitente)
    // .set('Mensajebody', this.mensaje.Mensajebody)
    // .set('Nombreremitente', this.mensaje.Nombreremitente)
    // ;
    let body = {
      CorreoRemitente: mensaje.Correoremitente,
      Mensajebody: mensaje.Mensajebody,
      Nombreremitente: mensaje.Nombreremitente
    };
    console.log(body);
    this.headers = new HttpHeaders({
      //"Content-Type": "application/x-www-form-urlencoded",
      "Content-Type": "application/json",
    });
    return this.http.post<any>(
      baseURL + 'ServicioGenerales/enviarCorreoContactanos',
      body,
      {
        headers: this.headers
      }).
      pipe(
        map((res: HttpResponse<any>) => {
          let jsonObject: any;
          if (res.status === 204) {
            this.message = 'no se encontró la institución';
          } else if (res.status === 500){
            this.message = 'Algo salió mal!';
          } else if (res.status === 201){
            jsonObject = res.body;
          } else{
            this.message = 'Ocurrió un error inesperado';
          }
          return [{ status: res.status, json: jsonObject}];
        }),
        catchError(this.handleError)
      );
  }

  // validarCorreo(correo: string){
  //   this.usuario.Correo = correo;
  //   let body = new HttpParams()
  //   .set('Correo', correo.toString());
  //   this.headers = new HttpHeaders({
  //     "Content-Type": "application/x-www-form-urlencoded",
  //     //"Content-Type": "application/json",
  //   });
  //   return this.http.post(baseURL + 'registro/registroInstitucion', body.toString(), {headers: this.headers}).
  //     pipe(
  //       tap(data => console.log("All:" + JSON.stringify(data))),
  //       catchError(this.handleError)
  //     );
  // }

  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
