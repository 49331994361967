

<nav class="navbar navbar-default" id="navbar">
  <div class="container-fluid">
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" routerLink="/" style="margin-top: -5px;"><img src="/assets/img/logo.png" class="img-responsive" style="max-width: 300%; max-height: 205%;"></a>
    </div>

    <!-- Collect the nav links, forms, and other content for toggling -->
    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
      <ul class="nav navbar-nav">
        <li class="active"><a routerLink="/" style="font-family: 'Times New Roman', Times, serif; font-size: large; color: #30a5ff;">Inicio<span class="sr-only">(current)</span></a></li>
        <li class="dropdown">
          <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" style="font-family: 'Times New Roman', Times, serif; font-size: large; color: #30a5ff">Catálogo&nbsp;&nbsp;<span class="caret"></span></a>
          <ul class="dropdown-menu">
            <li *ngFor = 'let coleccion of colecciones'><a [routerLink]="['/catalogo',coleccion?.idcolecion]" style="font-family: 'Times New Roman', Times, serif; font-weight: bold; color: #30a5ff">{{coleccion?.nombre}}</a></li>
            <!--<li><a routerLink="/catalogo">Leo León</a></li>-->
          </ul>
        </li>
        <li><a routerLink="/tienda" style="font-family: 'Times New Roman', Times, serif; font-size: large; color: #30a5ff">Tienda</a></li>
        <li><a routerLink="/kit-recu" style="font-family: 'Times New Roman', Times, serif; font-size: large; color: #30a5ff">Kit de recursos digitales</a></li>
        <li><a routerLink="/nosotros" style="font-family: 'Times New Roman', Times, serif; font-size: large; color: #30a5ff">Nosotros</a></li>
        <li><a routerLink="/contactanos" style="font-family: 'Times New Roman', Times, serif; font-size: large; color: #30a5ff">Contactos</a></li>
        <li *ngIf="isLoggedInAdmin" style="background-color: #30a5ff;"><a routerLink="/dashboard" style="font-family: 'Times New Roman', Times, serif; font-weight: bold;">Administracion</a></li>
        <li *ngIf="isLoggedIn"><a routerLink="/misrecursos"  style="color: cornflowerblue;">Mis Libros</a></li>
        <li *ngIf="isLoggedIn && loggedInUser.tipo === 'Docente'"><a routerLink="/misvideos"  style="color: cornflowerblue;">Mis Videos</a></li>
        <li *ngIf="isLoggedIn && loggedInUser.tipo === 'Docente'"><a routerLink="/misarchivos"  style="color: cornflowerblue;">Mis Archivos</a></li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li *ngIf="!isLoggedIn && !isLoggedInAdmin"><button type="button" class="btn btn-default navbar-btn" style="margin-right: 10px; font-family: Snell Roundhand, cursive; font-weight: bold;" data-toggle="modal" data-target="#myModal">Ingresar</button></li>
        <app-login (usuario)="getLogginStateUser($event)" (admin)="getLogginStateAdmin($event)"></app-login>
        <li *ngIf="!isLoggedIn && !isLoggedInAdmin"><button type="button" class="btn btn-default navbar-btn" data-toggle="modal" data-target="#registerModal" style="font-family: Snell Roundhand, cursive; font-weight: bold;">Registro</button></li>
        <app-register></app-register>
        <li *ngIf="isLoggedIn || isLoggedInAdmin"><button type="button" class="btn btn-default navbar-btn" (click)="cerrarSesion()">Cerrar Sesión</button></li>
        <li class="dropdown">
          <a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
            <i class="fa fa-shopping-cart"><span class="qty"> {{items}}</span></i>
          </a>
          <div class="cart-dropdown">
            <div class="cart-list">
              <div class="product-widget" *ngFor="let item of carritoLleno?.Libros; let ind = index">
                <div class="product-img">
                  <img [src]="item.urlimagen" [alt]="item.titulo">
                </div>
                <div class="product-body">
                  <h3 class="product-name">{{item.titulo}}</h3>
                  <h4 class="product-price"><span class="qty">1x</span>{{item.precio | currency}}  <span *ngIf="item.tipo === 'Fisico'" class="label label-primary">{{item.tipo}}</span><span *ngIf="item.tipo === 'Digital'" class="label label-success">{{item.tipo}}</span></h4>
                </div>
                <button class="delete" (click)="deleteItem(ind, 'libro')"><i class="fa fa-close"></i></button>
              </div>
              <div class="product-widget" *ngFor="let item of carritoLleno?.Kits; let ind = index">
                <div class="product-img">
                  <img src="/assets/img/cajakit.png" [alt]="item.nombre">
                </div>
                <div class="product-body">
                  <h3 class="product-name">{{item.nombre}}</h3>
                  <h4 class="product-price"><span class="qty">1x</span>{{item.precio | currency}}  <span class="label label-default">kit</span></h4>
                </div>
                <button class="delete" (click)="deleteItem(ind, 'kit')"><i class="fa fa-close"></i></button>
              </div>
            </div>

            <div class="cart-summary">
              <small>{{items}} Item(s) seleccionados</small>
              <h5>SUBTOTAL: ${{subtotal | number}}</h5>
            </div>
            <div class="row" #paypal [hidden]="isLoggedInAdmin === false && isLoggedIn === false"></div>
            <div class="cart-btns" *ngIf='isLoggedIn === false && isLoggedInAdmin === false'>
              <a (click)="comprarAlert()">Comprar</a>
              <div class="alert alert-danger" role="alert"><i class="fa fa-info-circle"></i> Nota: Corrobora tu lista de compras, no aceptamos cambios ni devoluciones!</div>
            </div>
            <!-- <div class="cart-btns">
              <a [routerLink]="['/cart']">Ver carrito</a>
              <a (click)="enviarCarrito()">Ver carrito</a>
            </div> -->
          </div>
        </li>
        <!-- /Cart -->
      </ul>
    </div><!-- /.navbar-collapse -->
  </div><!-- /.container-fluid -->
</nav>
