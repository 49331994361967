<div class="row">
    <app-side-bar></app-side-bar>
    <div class="col-sm-9">
		<h1 style="text-align: center;">Ingresar/Modificar Categoría</h1>

		<div class="panel panel-default">
			<div class="panel-heading">Formulario de información
			</div>
			<div class="panel-body">
				<div class="col-md-9">
					<form role="form">
						<hr>
						<div class="form-group">

                            <label>Seleccione la Colección de la nueva Categoría</label>
							<select class="form-control" (change)="onChangecoleccion($event.target.value)">
                                <option hidden selected>Seleccione una Colección</option>
                                <option *ngFor = 'let coleccion of colecciones' [value]="coleccion?.idcolecion">{{coleccion.nombre}}</option>
							</select>
							<br>
							<label>Seleccione una Categoría</label>
							<select class="form-control" (change)="onChangecategoria($event.target.value)" placeholder="Selecione una Categoria">
                                <option *ngFor = 'let categoria of categorias' [value]="categoria?.idcategoria">{{categoria.nombre}}</option>
								<option hidden selected> Elija una categoría</option>
								<option value="0"> Nueva categoría</option>
							</select>
							<label>Nombre Categoría</label>
							<input class="form-control" id="nuevonombre" placeholder="Ingrese el título de la Categoría">

							<label>Descripción</label>
									<textarea class="form-control" rows="3" id="nuevadescripcion"></textarea>

							</div>
							<br>
							<br>
							<h4>Ingrese la url de la imagen Principal (Icono) </h4>
							<input class="form-control" id="nuevaurlimagenicono" placeholder="Ingrese la url de imagen del icono de la categoria">

                            <br>
							<br>
							<h4>Ingrese la url de la imagen de Secundaria (Portada)</h4>
							<input class="form-control" id="nuevaurlimagenportada" placeholder="Ingrese la url de imagen de portada de la categoría">

                            <br>
                            <h4>Ingrese el color de la categoria</h4>
							<input class="form-control" id="nuevocolor" placeholder="Ingrese el color de la categoria (Ej: #FFFFF)">

                            <br>

					<div class="row">
						<div class="col-md-3 col-md-offset-3"></div>
						<div><button type="button" class="btn btn-lg btn-success" (click)="guardarCambios()">Guardar cambios</button>
							<button type="button" class="btn btn-lg btn-danger" (click)="eliminarCategoria()" routerLink="/saled/dashboard">Eliminar Categoría</button>
							</div>
						</div>
					</form>
				</div>
			</div>
</div>
