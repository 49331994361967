<form [formGroup]='institucionForm' (mouseout)="addInstitucion()">
<div class="form-group">
  <label for="names">Nombre Intitución</label>
  <input type="text" class="form-control" id="names" formControlName="nombre" placeholder="Ej: Unidad Educativa Leon">
</div>
<div class="form-group">
  <label for="provincia">Provincia</label>
  <select class="form-control" id="provincia" (click)="getProvincias()" [(ngModel)] = "provinciaSeleccionada" formControlName='provincia'>
    <option *ngFor="let provincia of provincias" >{{ provincia }}</option>
  </select>
</div>
<div class="form-group">
  <label for="canton">Cantón</label>
  <select class="form-control" id="canton" (click)="getCantones()" [(ngModel)] = "cantonSeleccionado" formControlName='canton'>
    <option *ngFor="let canton of cantones">{{ canton }}</option>
  </select>
</div>
<div class="form-group">
  <label for="parroquia">Parroquia</label>
  <select class="form-control" id="parroquia" (click)="getParroquias()" [(ngModel)]="parroquiaSeleccionada" formControlName='parroquia'>
    <option *ngFor="let parroquia of parroquias">{{ parroquia }}</option>
  </select>
</div>
<!--<div class="form-group">
  <button class="btn btn-primary" (click)="addInstitucion()">Comprobar Insitucion</button>
</div>-->
</form>
