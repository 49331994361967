import { Librosadmin } from './../../../modulos/librosadmin.model';
import { Component, OnInit, Output } from '@angular/core';
import { Coleccion } from 'src/app/modulos/coleccion.model';
import { ColeccionService } from 'src/app/servicios/coleccion.service';
import { ProductService } from 'src/app/servicios/product.service';
import { Categoria } from 'src/app/modulos/categoria.model';
import { EventEmitter, SimpleChanges } from '@angular/core';
import { ReadingJsonFilesService } from './../../../servicios/reading-json-file.service';
import { Libro } from 'src/app/modulos/libro.model';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AdministlibService } from 'src/app/servicios/administlib.service';
import { Libroingreso } from 'src/app/modulos/libroingreso.model';

@Component({
  selector: 'app-newbook',
  templateUrl: './newbook.component.html',
  styleUrls: ['./newbook.component.scss']
})
export class NewbookComponent implements OnInit {

  colecciones: Coleccion[];
  libro: Libroingreso;
  categorias: Categoria[];
  idcoleccionSeleccionada = 0;
  idcategoriaSeleccionada = 0;
  tipoSeleccionado: number;
  errorMessage = '';
  idColeccion = 0;
  idLibro = 0;
  existelibro = false;
  eliminado = false;



  @Output() libronew = new EventEmitter<Libro>();
  /*libroForm = new FormGroup({
    Coleccion: new FormControl(''),
    Categoria: new FormControl(''),
    Titulo: new FormControl(''),
    Autor: new FormControl(''),
    Tipo: new FormControl(''),
    Precio: new FormControl(''),
    Imagen: new FormControl(''),
    Pdf: new FormControl(''),
  });*/

  constructor(private coleccionServicio: ColeccionService,
              private productServicio: ProductService,
              private route: ActivatedRoute,
              private administlibServicio: AdministlibService,
  ) { }


  //idlibro = 0;
  modifiedlib: Libroingreso = {
    idlibro: 0,
    titulo: '',
    autores: '',
    sinopsis: '',
    tipo: '',
    destacado: false,
    rangoedad: '',
    urlimagen: '',
    urllibropdf: '',
    urllibropdfcompleto: '',
    precio: 0,
    idcategoria: 0,
    habilitado: true
  }

  ngOnInit(): void {
    const param = this.route.snapshot.paramMap.get('id');
    if (param) {
      const id = +param;
      this.getBook(id);
      this.idLibro = +param;
      this.existelibro = true;
    }
    this.obtenerColecciones();
  }

  getBook(id: number) {
    this.administlibServicio.getBook(id).subscribe({
      next: libro => this.libro = libro,
      error: err => this.errorMessage = err
    });
    //this.existecoleccion = true;
    //console.log(this.usuario);
  }

  obtenerColecciones() {
    this.coleccionServicio.getAllColeccion().subscribe(
      res => {
        this.colecciones = res;
      },
      err => console.log(err)
    );
  }

  obtenerCategorias(id: number) {
    this.coleccionServicio.getCategorias(id).subscribe(
      res => {
        this.categorias = res;
      },
      err => console.log(err)
    );
  }

  addLibro() {
    //let libronew = this.libroForm.getRawValue() as Libro;
    //console.log(libronew);
    // this.registroService.setInstitucion(institucion).subscribe({
    //   next: response => {
    //     institucion = response[0].json;
    //     this.institucion.emit(institucion);
    //     console.log(institucion);
    //   },
    //   error: err => this.erorMessage = err
    // });
    //this.libronew.emit(libronew);
  }
  onChangecoleccion(id) {
    this.idcoleccionSeleccionada = id;
    this.obtenerCategorias(id);
  }

  onChangecategoria(id) {
    if (id !== 0) {
      this.idcategoriaSeleccionada = id;
    } else if (id === 0) {
      console.log(id);
    }
  }
  modificarlibro(libro: Libroingreso) {
    this.administlibServicio.modificarBook(libro).subscribe(
      res => {
        if (res[0].json !== undefined) {
          this.eliminado = res[0].json;
          if (this.eliminado === true) {
            alert("Libro modificado con éxito.");
            this.eliminado = false;
          } else {
            alert("No se ha podido modificar");
          }
        }
      }
    )
  }

  ingresarlibro(libro: Libroingreso) {
    this.administlibServicio.ingresarBook(libro).subscribe(
      res => {
        if (res[0].json !== undefined) {
          this.eliminado = res[0].json;
          if (this.eliminado === true) {
            alert("Libro ingresado con éxito.");
            this.eliminado = false;
          } else {
            alert("No se ha podido ingresar el libro");
          }
        }
      }
    )
  }

  guardarCambios() {
    if (this.idcategoriaSeleccionada === 0) {
      alert("Por favor seleccione la categoría del libro.");
    } else {
      if ((<HTMLInputElement>document.getElementById('nuevotitulo')).value !== '' &&
        (<HTMLInputElement>document.getElementById('nuevoautor')).value !== '' &&
        (<HTMLInputElement>document.getElementById('nuevasinopsis')).value !== '' &&
        (<HTMLInputElement>document.getElementById('nuevorango')).value !== '' &&
        (<HTMLInputElement>document.getElementById('nuevotipo')).value !== '' &&
        (<HTMLInputElement>document.getElementById('nuevoprecio')).value !== '' &&
        (<HTMLInputElement>document.getElementById('nuevaurlImg')).value !== '' &&
        (<HTMLInputElement>document.getElementById('nuevaurlPdf')).value !== '') {
        this.modifiedlib.titulo = (<HTMLInputElement>document.getElementById('nuevotitulo')).value;
        this.modifiedlib.autores = (<HTMLInputElement>document.getElementById('nuevoautor')).value;
        this.modifiedlib.sinopsis = (<HTMLInputElement>document.getElementById('nuevasinopsis')).value;
        this.modifiedlib.rangoedad = (<HTMLInputElement>document.getElementById('nuevorango')).value;
        this.modifiedlib.tipo = (<HTMLInputElement>document.getElementById('nuevotipo')).value;
        this.modifiedlib.precio = (Number)((<HTMLInputElement>document.getElementById('nuevoprecio')).value);
        this.modifiedlib.urlimagen = (<HTMLInputElement>document.getElementById('nuevaurlImg')).value;
        this.modifiedlib.urllibropdf = (<HTMLInputElement>document.getElementById('nuevaurlPdf')).value;
        this.modifiedlib.urllibropdfcompleto = (<HTMLInputElement>document.getElementById('nuevaurlPdfrecortado')).value;
        this.modifiedlib.idcategoria = this.idcategoriaSeleccionada;

        if (this.idLibro !== 0) {
          this.modifiedlib.idlibro = this.idLibro;
          this.modificarlibro(this.modifiedlib);
          console.log(this.modifiedlib);

        } else {
          //Llamar al servicio de ingresar nueva coleccion
          console.log(this.modifiedlib);
          this.ingresarlibro(this.modifiedlib);
        }
      } else {
        alert("Por favor ingrese los valores de la nueva colección");
      }
    }
  }


}


