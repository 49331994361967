<h1 style="text-align: center;">Listado de libros</h1>
		<div class="row">
			<div class="col-md-11 col-md-offset-11">
				<a href="newlib.html"><button type="button" class="btn btn-md btn-primary">Añadir</button></a>
			</div>
		</div>
		<div class="table-responsive">
			<table class="table">
				<tr>
					<td>
						Título
					</td>
					<td>
						Autor(es)
					</td>
					<td>
						Categotría
					</td>
					<td>
						Colección
					</td>
					<td>
						Precio
					</td>
					<td>
						Tipo
					</td>
					<td>
						Rango de edad
					</td>
					<td>
						Editar/Eliminar
					</td>
                </tr>
                <tr *ngFor = 'let lib of libs'>
                   <td>{{lib.Titulo}}</td>
                   <td>{{lib.Autores}}</td>
                   <td>{{lib.Cat}}</td>
                   <td>{{lib.Col}}</td> 
                   <td>{{lib.Precio}}</td>
                   <td>{{lib.Tipo}}</td>
                   <td>{{lib.Rangoedad}}</td>
                   <td><a href=""><img src="/assets/img/edit.png"> <a href=""><img src="/assets/img/delete.png"></a> </a></td>
                </tr>
			</table>
		  </div>