import { Admin } from './../../modulos/admin.model';
import { Usuario } from './../../modulos/user.model';
import { Account } from './../../modulos/account.model';
import { Subscription } from 'rxjs';
import { CarritoService } from './../../servicios/carrito.service';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Coleccion } from 'src/app/modulos/coleccion.model';
import { ColeccionService } from 'src/app/servicios/coleccion.service';
import { LoginService } from 'src/app/servicios/login.service';
import { Libro } from 'src/app/modulos/libro.model';
import { error } from 'console';
import { Carrito } from 'src/app/modulos/carrito.model';

declare let paypal;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isLoggedIn = false;
  isLoggedInAdmin = false;
  loggedInUser: Usuario;
  loggedInAdmin: Admin;
  mostrarRegistro: boolean;
  permitirRegistro: boolean;
  descripcion: 'comprar de recursos en Salazar editores';
  ordenPaypal: any;

  items: number;
  //carritoLleno: Libro[] = [];
  carritoLleno: Carrito = {
    Libros: [],
    Kits: []
  };
  subtotal = 0;
  colecciones: Coleccion [];
  subscription: Subscription;
  // producto = {
  //   descripcion: 'prueba 1',
  //   precio: 10.50,
  // };
  @ViewChild('paypal', {static: true}) paypalElement: ElementRef;


  constructor(private loginService: LoginService,
              private router: Router,
              private coleccionServicio: ColeccionService,
              private carrito: CarritoService) { }

  ngOnInit(): void {
    const user = this.loginService.accountValue;
    if (user){
      this.isLoggedIn = true;
    }
    this.subscription = this.carrito.getAllProducts().subscribe(response => {
      this.carritoLleno = response;
      this.items = this.carritoLleno.Libros.length + this.carritoLleno.Kits.length;
      this.sumarPrecios();
      //this.getDescripcion();
    },
    err => console.log(err)
    );
    this.coleccionServicio.getAllColeccion().subscribe(
      res => {
        this.colecciones = res;
        //console.log(this.colecciones);
      },
      err => console.log(err)
    );
    this.botonesPaypal();
  }

  deleteItem(indice: number, tipo: string){
    this.carrito.deleteItem(indice, tipo);
    this.items = this.carritoLleno.Libros.length + this.carritoLleno.Kits.length;
    this.sumarPrecios();
  }

  @HostListener('window:unload', [ '$event' ])
  unloadHandler(event) {
    alert("usted acaba de cerrar sesion");
    this.cerrarSesion();
  }

  /*@HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHandler(event) {
    alert("usted acaba de cerrar session");
    this.cerrarSesion();
  }*/


  sumarPrecios(){
    this.subtotal = 0;
    this.carritoLleno.Libros.forEach(item => {
      this.subtotal = this.subtotal + item.precio;
    });
    this.carritoLleno.Kits.forEach(item => {
      this.subtotal = this.subtotal + item.precio;
    });
  }

  /*getDescripcion(){
    this.carritoLleno.Libros.forEach( carrito => this.descripcion = this.descripcion + carrito.Titulo);
    this.carritoLleno.Kits.forEach( carrito => this.descripcion = this.descripcion + carrito.Nombre);
  }*/

  enviarCarrito(){
    this.router.navigateByUrl('/cart');
    this.subscription.unsubscribe();
  }

  botonesPaypal(){
    paypal.
    Buttons({
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              description: this.descripcion,
              amount: {
                currency_code: 'USD',
                value: this.subtotal
              }
            }
          ]
        });
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        this.ordenPaypal = order;
        console.log(order);
        if(order?.status === 'COMPLETED'){
          //alert('su pago a sido realizado con éxito!! Gracias por su compra :D');
          this.carrito.realizarPedido(order?.id, this.subtotal, order.create_time).subscribe({
            next: response => {
              alert('su pago a sido realizado con éxito!! Gracias por su compra :D');
              console.log(response);
            },
            error: err => console.log(err)
          });
          this.borrarCarrito();

        }else{
          alert('No se ha podido realizar tu pago');
        }
      },
      onError: err => {
        console.log(err);
      }
    })
    .render( this.paypalElement.nativeElement );
  }

  getLogginStateUser(user: Usuario){
    if (user){
      this.isLoggedIn = true;
      console.log('User: ' + this.isLoggedIn);

      this.loggedInUser = user;
    }
  }

  getLogginStateAdmin(admin: Admin){
    if (admin){
      this.isLoggedInAdmin = true;
      console.log('Admin:' + this.isLoggedIn);
      this.loggedInAdmin = admin;
    }
  }

  comprarAlert(){
    alert("Por favor regístrate o inicia sesión para comprar nuestros libros");
  }

  borrarCarrito(){
    this.carrito.borrarCarrito();
    this.subtotal = 0;
    this.items = 0;
  }
  cerrarSesion(){
    this.loginService.logout();
    this.isLoggedIn = false;
    this.isLoggedInAdmin = false;
  }
}
