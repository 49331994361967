import { baseURL } from './baseUrl';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Libro } from './../modulos/libro.model';
import { Injectable } from '@angular/core';
import { from, Observable, Subject, throwError } from 'rxjs';
import { Compra } from '../modulos/compra.model';
import { Usuario } from '../modulos/user.model';
import { map, catchError, tap } from 'rxjs/operators';
import { Carrito } from '../modulos/carrito.model';
import { Servicio } from '../modulos/servicio.model';

@Injectable({
  providedIn: 'root'
})
export class CarritoService {

  //carrito: Libro[] = [];
  carrito: Carrito = {
    Libros: [],
    Kits: []
  };
  usuario: Usuario;
  carritoCompra: Compra = {
    Fechacompra: '',
    NumeroComprobante: '',
    Usuario: null,
    Libros: [],
    Kits: [],
    Precio: 0
  };
  headers: HttpHeaders;
  private enviarLibroSubject = new Subject<Libro[]>();
  private enviarCarritoSubject = new Subject<Carrito>();
  //enviarLibroObservable = this.enviarLibroSubject.asObservable();

  constructor(private http: HttpClient) { }

  addItemLibro(libro: Libro, tipo: string){
    libro.tipo = tipo;
    this.carrito.Libros.push(libro);
    //console.log(this.carrito);
    //this.enviarLibroSubject.next(libro);

  }

  addItemKit(kit: Servicio){
    this.carrito.Kits.push(kit);
  }

  sendLibroCarrito(libros: Libro[]){
    this.carrito.Libros = libros;

    //this.enviarLibroSubject.next(libros);
    this.enviarCarritoSubject.next(this.carrito);
    //return this.carrito;
  }

  sendKitCarrito( kits: Servicio[]){
    this.carrito.Kits = kits;
    this.enviarCarritoSubject.next(this.carrito);
  }

  deleteItem(indice: number, tipo: string){
    //this.carrito.pop();
    if (tipo === 'libro'){
      this.carrito.Libros.splice(indice, 1);
    } else if(tipo === 'kit'){
      this.carrito.Kits.splice(indice, 1);
    }
  }

  getAllProducts(): Observable<Carrito>{
    return this.enviarCarritoSubject.asObservable();
  }

  /*registrarLibros(carrito: Libro[]){
    let carritoDeCompras: Compra = {
      Fechacompra: '',
      NumeroComprobante: '',
      Usuario: null,
      Libros: null,
      Kits: null,
      Precio: 0
    };
    carrito.forEach(carrito => {
      carritoDeCompras.Libros.push(carrito);
    })
    return carritoDeCompras;
  }*/

  /*registrarKits(){

  }*/

  realizarPedido(numeroOrden: string, precio: number, fechaCompra: string): Observable<any>{
    this.usuario = JSON.parse(sessionStorage.getItem('user'))?.datosusuario;
    this.carritoCompra.Usuario = this.usuario;
    this.carritoCompra.Libros = [];
    this.carritoCompra.Kits = [];
    if(this.carrito != undefined){
      this.carrito.Libros.forEach( carro => {

        this.carritoCompra.Libros.push(carro);
      })
      this.carrito.Kits.forEach( carro => {

        this.carritoCompra.Kits.push(carro);
      })
    }
    /*if(this.kits){
      this.registrarKits();
    }*/
    this.carritoCompra.NumeroComprobante = numeroOrden;
    this.carritoCompra.Precio = precio;
    this.carritoCompra.Fechacompra = fechaCompra;
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    console.log(JSON.parse(sessionStorage.getItem('user')).token);
    console.log(this.carritoCompra);
    return this.http.post(baseURL + 'usuario/pedido', this.carritoCompra, {headers: this.headers}).
      pipe(
        tap(
          orden => {
            console.log("All: " + JSON.stringify(orden));
          }
        ),
        catchError(this.handleError)
      );
  }

  borrarCarrito(){
    this.carrito.Libros.length = 0;
    this.carrito.Kits.length = 0;
  }

  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
