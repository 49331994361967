import { Component, OnInit } from '@angular/core';
import { Librosadmin } from 'src/app/modulos/librosadmin.model';
import { AdministlibService } from 'src/app/servicios/administlib.service';
import { Libro } from 'src/app/modulos/libro.model';
import { LoginService } from 'src/app/servicios/login.service';

@Component({
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.scss']
})
export class BooksComponent implements OnInit {


  libs: Librosadmin [] = [];
  eliminado = false;
  libsFiltered: Librosadmin [] = [];

  _listFilter: string;
  get listFilter(): string {
    return this._listFilter;
  }
  set listFilter(value: string) {
    this._listFilter = value;
    this.libsFiltered = this.listFilter ? this.performFilter(this.listFilter) : this.libs;
  }

  constructor(private administlibServicio: AdministlibService,
              private loginService: LoginService,
              ) { }

  ngOnInit(){
    this.listarlibros();
  }

  performFilter(filterBy: string): Librosadmin[] {
    filterBy = filterBy.toLocaleLowerCase();
    return this.libs.filter((lib: Librosadmin) =>
          lib.titulo.toLocaleLowerCase().indexOf(filterBy) !== -1);
  }

  refrescararchivos(){
    this.listarlibros();
  }

  listarlibros(){
    this.administlibServicio.pedirlibs().subscribe(
      res => {
        if(res[0].json != undefined){
          this.libs = res[0].json;
          this.libsFiltered = this.libs;
        }
        //console.log(JSON.stringify(this.libs));

      },
      err => console.log(err)
    )
  }
  eliminarBook(libro: Librosadmin){

    this.administlibServicio.eliminarBook(libro).subscribe(
      res => {
        if(res[0].json != undefined){
          this.eliminado = res[0].json;
          if(this.eliminado === true){
            alert("Libro eliminado con éxito.");
            this.eliminado = false;
            this.listarlibros();
          }else{
            alert("No se ha podido eliminar");
          }
        }
      }
    )
  }

}
