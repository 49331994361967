import { Archivo } from 'src/app/modulos/archivo.model';
import { Component, OnInit } from '@angular/core';
import { Categoria } from 'src/app/modulos/categoria.model';
import { Coleccion } from 'src/app/modulos/coleccion.model';
import { Libroingreso } from 'src/app/modulos/libroingreso.model';
import { Servicio } from 'src/app/modulos/servicio.model';
import { AdministkitService } from 'src/app/servicios/administkit.service';
import { AdministlibService } from 'src/app/servicios/administlib.service';
import { ColeccionService } from 'src/app/servicios/coleccion.service';
import { Asignar } from 'src/app/modulos/asignar.model';
import { Usuario } from 'src/app/modulos/user.model';
import { AdministuserService } from 'src/app/servicios/administuser.service';

@Component({
  selector: 'app-asignar',
  templateUrl: './asignar.component.html',
  styleUrls: ['./asignar.component.scss']
})
export class AsignarComponent implements OnInit {

  colecciones: Coleccion [];
  //libros: Libroingreso [];
  categorias: Categoria [];
  kits: Servicio [];
  idcoleccionSeleccionada = 0;
  idcategoriaSeleccionada = 0;
  idlibroSeleccionado = 0;
  idkitSeleccionado = '';
  tipoSeleccionado: number;
  errorMessage = '';
  idColeccion = 0;
  IdCategoria = 0;
  idLibro = 0;
  idKit = 0;
  idUsuario = 0;
  existekit = false;
  eliminado = false;
  libs: Libroingreso [] = [];
  idsLibros: number[] = [];
  idsArchivos: number[] = [];
  idsVideos: number[] = [];
  kitsSeleccionados: Servicio[]=[];
  librosseleccionados: Libroingreso []=[];
  usuariosseleccionados: Usuario []=[];
  servicios: Servicio[] = [];
  recasig: Asignar;
  usuarios: Usuario[] = [];

  constructor(private coleccionServicio: ColeccionService,
              private administlibServicio: AdministlibService,
              private administkitServicio: AdministkitService,
              private administuserServicio: AdministuserService) { }

    modifiedasignar: Asignar = {
      Usuarios: [],
      Librosdigitales: [],
      Kits: []
    };

  ngOnInit(): void {
    this.listarusuarios();
    this.obtenerColecciones();
    this.getKits();
  }

  obtenerColecciones(){
    this.coleccionServicio.getAllColeccion().subscribe(
      res => {
        this.colecciones = res;
      },
      err => console.log(err)
    );
    console.log(this.colecciones);
  }

  obtenerCategorias(id: number){
    this.coleccionServicio.getCategorias(id).subscribe(
      res => {
        this.categorias = res;
      },
      err => console.log(err)
    );
  }

  obtenerLibros(id: number){
    this.administlibServicio.getBooksCat(id).subscribe(
      res => {
        this.libs = res;
      },
      err => console.log(err)
    );
  }

  getKits(){
    this.administkitServicio.pedirkits().subscribe(
      res => {
        if (res[0].json !== undefined){
          this.servicios = res[0].json;
        }
      },
      err => console.log(err)
    );
  }

  onChangecoleccion(id){
    this.idcoleccionSeleccionada = id;
    this.obtenerCategorias(id);
  }

  onChangecategoria(id){
    if (id !== 0 ){
      this.idcategoriaSeleccionada = id;
      this.obtenerLibros(id);
    }else if (id === 0){
      console.log(id);
    }
  }

  selecionlibro(libro: Libroingreso){
    this.librosseleccionados.push(libro);
    console.log(libro);
  }

  selecionusuario(usuario: Usuario){
    this.usuariosseleccionados.push(usuario);
    console.log(usuario);
  }

  selecionkit(kit: Servicio){
    this.kitsSeleccionados.push(kit);
    console.log(kit);

  }

  listarusuarios(){
    this.administuserServicio.pedirusuario().subscribe(
      res => {
        this.usuarios = res[0].json;
        console.log(this.usuarios);

      },
      err => console.log(err)
    );

    /*
    this.administuserServicio.pedirusuario().subscribe(
      res => {
        if(res[0].json != undefined){
          this.usuarios = res[0].json;
        }
        //console.log(JSON.stringify(this.libs));

      },
      err => console.log(err)
    )*/
  }

  asignar(recasig: Asignar){
    this.administkitServicio.asignar(recasig).subscribe(
      res => {
        if(res[0].json != undefined){
          this.eliminado = res[0].json;
          if(this.eliminado === true){
            alert("Recursos asignados con éxito.");
            this.eliminado = false;
          }else{
            alert("No se ha podido asignar");
          }
        }
      }
    )
  }

  quitarusuario(indice: number){
    this.usuariosseleccionados.splice(indice, 1);
  }

  quitarlibro(indice: number){
    this.librosseleccionados.splice(indice, 1);
  }

  quitarKit(indice: number){
    this.kitsSeleccionados.splice(indice, 1);
  }

  guardarCambios(){
    this.modifiedasignar.Usuarios.length = 0;
    this.modifiedasignar.Librosdigitales.length = 0;
    this.modifiedasignar.Kits.length = 0;
    if (this.librosseleccionados.length !== 0 || this.usuariosseleccionados.length !== 0 || this.kitsSeleccionados.length !== 0)
    {
      this.librosseleccionados.forEach(libro => {
        this.modifiedasignar.Librosdigitales.push(libro);
      });

      this.kitsSeleccionados.forEach(archivo => {
        this.modifiedasignar.Kits.push(archivo);
      });

      this.usuariosseleccionados.forEach(usuario => {
        this.modifiedasignar.Usuarios.push(usuario);
      })
    }
    console.log(this.modifiedasignar);

    this.administkitServicio.asignar(this.modifiedasignar).subscribe(
      response => {
        console.log(response[0].status);
      },
      err => console.log(err)

    );

  }
}
