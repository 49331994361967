<!-- FOOTER -->
<footer id="footer" class="bg-dark text-center text-white">
    <!-- top footer -->
    <div class="section">
        <!-- container -->
        <div class="container">
            <!-- row -->
            <div class="row">
                <div class="col-sm-3 col-xs-6">
                    <div class="">
                        <h3 class="footer-title" style="color: rgb(93, 127, 221); font-family: Comic Sans MS, Comic Sans, cursive;">CONTACTOS</h3>
                        <ul class="list-unstyled mb-0">
                            <li><a class="nav-link" href="https://www.google.com.ec/maps/place/LIBRER%C3%8DA+Y+PAPELER%C3%8DA+MUNDO+EDUCATIVO/@-0.2881744,-78.5376176,18.86z/data=!4m5!3m4!1s0x91d5989bd8e69e1d:0x1aab393329acfb33!8m2!3d-0.2874696!4d-78.537542?hl=es" target="blank"><i class="fa fa-map-marker fa-2x"></i>&nbsp;&nbsp;&nbsp;Av. Pedro Vicente Maldonado S29-19 y entrada a San Cristobal</a></li>
                            <br>
                            <li><a class="nav-link" href="https://api.whatsapp.com/send/?phone=593994176258" target="blank"><i class="fa fa-whatsapp fa-2x"></i>&nbsp;&nbsp;&nbsp;099 417 6258
                                </a></li>
                            <br>
                            <li><a class="nav-link" href="https://api.whatsapp.com/send/?phone=593967776678" target="blank"><i class="fa fa-whatsapp fa-2x"></i>&nbsp;&nbsp;&nbsp;096 777 6678
                                </a></li>
                            <br>
                            <li><a class="nav-link" routerLink="/contactanos"><i class="fa fa-envelope-o fa-2x"></i>&nbsp;&nbsp;&nbsp;info@salazareditores.com</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-3 col-xs-6">
                    <div class="footer">
                        <h2 class="footer-title" style="color: rgb(93, 127, 221); font-family: Comic Sans MS, Comic Sans, cursive;">Enlaces Rápidos</h2>
                        <ul class="list-unstyled">
                            <li><a routerLink="/tienda"><h4>Tienda</h4></a></li><br>
                            <li><a routerLink="/contactanos"><h4>Contáctanos</h4></a></li><br>
                            <li><a routerLink="/nosotros"><h4>Nosotros</h4></a></li>
                            </ul>
                    </div>
                </div>

                <div class="clearfix visible-xs"></div>

                <div class="col-sm-3 col-xs-6">
                    <div class="footer">
                        <h2 class="footer-title" style="color: rgb(93, 127, 221); font-family: Comic Sans MS, Comic Sans, cursive;">Colecciones</h2>
                        <ul class="footer-links">
                            <li *ngFor = 'let coleccion of colecciones'><a [routerLink]="['/catalogo',coleccion?.idcolecion]"><h4>{{coleccion?.nombre}}</h4></a><br></li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-3 col-xs-6">
                    <div class="footer">
                        <h2 class="footer-title" style="color: rgb(93, 127, 221); font-family: Comic Sans MS, Comic Sans, cursive;">Nuestras redes sociales</h2>
                        <ul class="list-unstyled mb-0">
                            
                              <li>
                                &nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.instagram.com/salazareditores/" target="_blank"><i class="fa fa-instagram fa-3x"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.youtube.com/channel/UC_EjqdzLHBQMal51MyG7pDQ" target="_blank"><i class="fa fa-youtube fa-3x"></i></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.facebook.com/salazareditoresedu" target="_blank"><i class="fa fa-facebook fa-3x"></i></a>    
                              </li>
                        </ul>
                    </div>
                </div>
            </div>
            <h3 class="footer-title" ><img alt="Brand" src="/assets/img/logo001.png" width="550" height="315"></h3>
            <!-- /row -->
        </div>
        <!-- /container -->
    </div>
    <!-- /top footer -->
    
    <!-- bottom footer -->
    <div id="bottom-footer" class="section">
        <div class="container">
            <!-- row -->
            <div class="row">
                <div class="col-md-12 text-center">
                    <h4>Aceptamos</h4>
                    <ul class="footer-payments">
                        <i class="fa fa-cc-visa fa-3x"></i>&nbsp;
                        <i class="fa fa-cc-diners-club fa-3x"></i>&nbsp;
                        <i class="fa fa-cc-mastercard fa-3x"></i>&nbsp;
                        <i class="fa fa-cc-discover fa-3x"></i>&nbsp;
                        <i class="fa fa-cc-amex fa-3x"></i>&nbsp;
                    </ul>
                    <span class="copyright">
                        Copyright &copy;<script>document.write(new Date().getFullYear());</script> Todos los derechos reservados | Desarrollado por:<a href="https://api.whatsapp.com/send/?phone=593958919713" target="_blank"><h4 style="color: rgb(93, 127, 221);">Sindetel</h4></a>
                    </span>
                </div>
            </div>
                <!-- /row -->
        </div>
        <!-- /container -->
    </div>
    <!-- /bottom footer -->
</footer>
<!-- /FOOTER -->
