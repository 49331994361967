import { Archivo } from './../modulos/archivo.model';
import { baseURL } from './baseUrl';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap} from 'rxjs/operators';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  
  @Injectable({
    providedIn: 'root'
  })
  export class ArchivoService {
  
    headers: HttpHeaders;

    /*private SERVER_URL = environment.SERVER_URL;*/
    constructor(private http: HttpClient) { }
    getArchivos(): Observable<any>{
        this.headers = new HttpHeaders ({
          "Content-Type": "application/json",
          Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
        });
        return this.http.get<Archivo[]>(baseURL + 'CRUDArchivo/listararchivos', {headers: this.headers, observe: 'response'}).
          pipe(
            map((res: HttpResponse<any>) => {
              let jsonObject: any;
              if (res.status === 204) {
                //codigo invalido
                jsonObject = false;
              } else if (res.status === 200){
                jsonObject = res.body;
              }
              return [{ status: res.status, json: jsonObject}];
            }),
            catchError(this.handleError)
          );
      }

  eliminarArchivo(archivo: Archivo)
  {
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<any>(baseURL + 'CRUDArchivo/eliminararchivo', archivo, {headers: this.headers, observe: 'response'}).
    pipe(
      map((res: HttpResponse<any>) => {
        let jsonObject: any;
        if (res.status === 204) {
          //codigo invalido
          jsonObject = false;
        } else if (res.status === 200){
          jsonObject = res.body;
        }
        return [{ status: res.status, json: jsonObject}];
      }),
      catchError(this.handleError)
    );
  }

  ingresarArchivo(archivo: Archivo)
  {
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<any>(baseURL + 'CRUDArchivo/ingresoarchivo', archivo, {headers: this.headers, observe: 'response'}).
    pipe(
      map((res: HttpResponse<any>) => {
        let jsonObject: any;
        if (res.status === 204) {
          //codigo invalido
          jsonObject = false;
        } else if (res.status === 200){
          jsonObject = res.body;
        }
        return [{ status: res.status, json: jsonObject}];
      }),
      catchError(this.handleError)
    );
  }

  modificarArchivo(archivo: Archivo)
  {
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<any>(baseURL + 'CRUDArchivo/modificararchivo', archivo, {headers: this.headers, observe: 'response'}).
    pipe(
      map((res: HttpResponse<any>) => {
        let jsonObject: any;
        if (res.status === 204) {
          //codigo invalido
          jsonObject = false;
        } else if (res.status === 200){
          jsonObject = res.body;
        }
        return [{ status: res.status, json: jsonObject}];
      }),
      catchError(this.handleError)
    );
  }

  getArchivo(idarchivo: number): Observable<any>{
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<Archivo>(baseURL + `CRUDArchivo/buscararchivo?idarchivo=${idarchivo.toString()}`, null, {headers: this.headers, observe: 'response'}).
      pipe(
        map((res: HttpResponse<any>) => {
          let jsonObject: any;
          if (res.status === 204) {
            //codigo invalido
            jsonObject = false;
          } else if (res.status === 200){
            jsonObject = res.body;
          }
          return [{ status: res.status, json: jsonObject}];
        }),
        catchError(this.handleError)
      );
  }

  
      
  
      private handleError(err: HttpErrorResponse) {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          errorMessage = `An error occurred: ${err.error.message}`;
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
      }
  }
  