<div class="row">
  <app-side-bar></app-side-bar>
    <div class="col-sm-9">
		<h1 style="text-align: center;">Listado de Ordenes</h1>
        <div class="row">
        <div class="col-lg-3 col-md-3">
            <div class="input-group">
            <input type="text" class="form-control" placeholder="Buscar..." [(ngModel)]="listFilter">
                <span class="input-group-btn">
                </span>
            </div>
        </div>
        </div>
        <br>
        <div *ngIf="pedidos" class="table-responsive">
			<table class="table">
				<tr>
					<td>
						Usuario
					</td>
					<td>
						Número de pedido
					</td>
					<td>
						Detalle compra
					</td>
					<td>
						Precio
          </td>
          <td>
						Estado
					</td>
        </tr>
        <tr *ngFor = 'let pedido of pedidosFiltered'>
          <td>{{pedido.nombres}}</td>
          <td>{{pedido.numerocomprobante}}</td>
          <td>{{pedido.detallecompra}}</td>
          <td>{{pedido.precio}}</td>
          <td>{{pedido.estado}}</td>
          <td><a *ngIf="pedido.estado === 'PENDIENTE'"><img src="/assets/img/pendiente.png" (click)="entregarpedido(pedido)"></a> <a *ngIf="pedido.estado === 'ENTREGADO'"><img src="/assets/img/entregado.png"></a> </td>
       </tr>
			</table>
		  </div>
      <div *ngIf="!pedidos">
        <div>
          <div class="alert alert-info">
            <strong>¡No se encontraron pedidos!</strong> Aun no se han registrado pedidos en la base de datos.
          </div>
        </div>
      </div>

	<!--/.main-->
    </div>
</div>
