<div class="container">
  <div class="row">
    <h2>Mis videos</h2>
  </div>
  <div class="row" *ngIf="booksNames !== []">
    <div class="col-md-12" *ngFor="let bookName of booksNames; let indice = index">
      <h3><span class="label label-primary">{{bookName}}</span>&nbsp;&nbsp;<span class="btn btn-default" *ngIf="!loadVideosClicked" (click)="loadVideos(bookName)">Ver videos</span> <span class="btn btn-default" *ngIf="loadVideosClicked" (click)="hideVideos()">Ocultar Videos</span></h3>
    </div>
  </div>
  <div class="row" *ngIf="booksNames.length === 0">
    <div class="alert alert-warning">
      <strong>¡Nota!</strong> Si has adquirido nuestros videos ingresa el código de activación enviado a tu correo en <a class="alert-link" routerLink='/misrecursos'>Mis Libros</a>.
    </div>
    <div class="alert alert-info">
      <strong>¡No se encontraron videos!</strong> Adquiere uno de nuestros videos en <a class="alert-link" routerLink='/kit-recu'>Kit de recursos digitales</a>.
    </div>
  </div>
  <div class="player-wrapper" *ngIf="loadVideosClicked">
    <vg-player (onPlayerReady)="onPlayerReady($event)" class="col-md-6">
      <vg-overlay-play></vg-overlay-play>
      <vg-buffering></vg-buffering>

      <vg-scrub-bar>
        <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
        <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
      </vg-scrub-bar>

      <vg-controls>
        <vg-play-pause></vg-play-pause>
        <vg-playback-button></vg-playback-button>

        <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>



        <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>


        <vg-mute></vg-mute>
        <vg-volume></vg-volume>

        <vg-fullscreen></vg-fullscreen>
      </vg-controls>

      <video #media [vgMedia]="media" [src]="currentItem?.src" id="singleVideo" preload="auto" crossorigin>
      </video>
    </vg-player>
    <ul class="col-md-6 list-scrollable">
      <li class="playlist-item" *ngFor="let item of playlist; let $index = index"
        (click)="onClickPlaylistItem(item, $index)" [class.selected]="item === currentItem">
        {{ item.title }}
      </li>
    </ul>
  </div>

</div>
<br>
<br>
