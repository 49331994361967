import { Archivo } from './../modulos/archivo.model';
import { tap, catchError, map } from 'rxjs/operators';
import { CodigoActivacion } from './../modulos/codigo-activacion.model';
import { Usuario } from './../modulos/user.model';
import { baseURL } from './baseUrl';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { Libro } from '../modulos/libro.model';
import { Video } from '../modulos/video.model';

@Injectable({
  providedIn: 'root'
})
export class MisRecursosService {

  headers: HttpHeaders;
  usuario: Usuario;
  activacion: CodigoActivacion = {
    usuario: null,
    codigo: ''
  };

  constructor(private http: HttpClient) { }

  activarServicios(codigo: string): Observable<any>{
    this.usuario = JSON.parse(sessionStorage.getItem('user'))?.datosusuario;
    this.activacion.usuario = this.usuario;
    this.activacion.codigo = codigo;
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<any>(baseURL + 'usuario/activarservicio', this.activacion, {headers: this.headers, observe: 'response'}).
      pipe(
        map((res: HttpResponse<any>) => {
          let jsonObject: any;
          if (res.status === 204) {
            //codigo invalido
            jsonObject = false;
          } else if (res.status === 200){
            jsonObject = res.body;
          }
          return [{ status: res.status, json: jsonObject}];
        }),
        catchError(this.handleError)
      );
  }

  getLibrosAutorizados(){
    this.usuario = JSON.parse(sessionStorage.getItem('user'))?.datosusuario;
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<any>(baseURL + 'usuario/misrecursos', this.usuario,{headers: this.headers}).
      pipe(
        tap(
          response => {
            console.log("Libros retornados exitosamente");
            //console.log("All: " + JSON.stringify(response));
          }),
        catchError(this.handleError)
      );

  }

  getLibrosAutorizadosxUsuario(auxusuario: Usuario){
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<any>(baseURL + 'usuario/misrecursos', auxusuario,{headers: this.headers}).
      pipe(
        tap(
          response => {
            console.log("Libros retornados exitosamente");
            //console.log("All: " + JSON.stringify(response));
          }),
        catchError(this.handleError)
      );

  }

  getArchivosProfesor(): Observable<Archivo[]>{
    this.usuario = JSON.parse(sessionStorage.getItem('user'))?.datosusuario;
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<Archivo[]>(baseURL + 'usuario/misarchivos', this.usuario,{headers: this.headers})
      .pipe(
        tap(
          response => console.log('Archivos cargados exitosamente')
          //console.log("All: " + JSON.stringify(response))
        ),
        catchError(this.handleError)
      );
  }

  getVideosProfesor(){
    this.usuario = JSON.parse(sessionStorage.getItem('user'))?.datosusuario;
    this.headers = new HttpHeaders ({
      "Content-Type": "application/json",
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<Video[]>(baseURL + 'usuario/misvideos', this.usuario, {headers: this.headers})
      .pipe(
        tap(
          response => console.log('videos cargados')
          //console.log("All: " + JSON.stringify(response))
        ),
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
