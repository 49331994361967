import { Injectable } from '@angular/core';
import * as data from "../../assets/json/listadoCantones.json";
import * as prueba from "../../assets/json/jsonDePrueba.json";
import { Libro } from '../modulos/libro.model';
@Injectable()
export class ReadingJsonFilesService{

  datos: any = (data as any).default;
  provincias: string[];
  cantones: any[];
  parroquias: any[];
  datosPrueba:any = (prueba as any).default;

  constructor() {
    console.log('Reading local json files');
    //console.log(this.datos);
  }

  getCantones(provinciaSeleccionada: string) {
    this.cantones = this.datos.filter(provincia =>
      provincia.Provincia === provinciaSeleccionada
    );
    return this.cantones.map( catones =>
      catones.Cantón
    );
  }

  getProvincias(){
    return this.datos.map( provincia =>
      provincia.Provincia
    );
  }

  getParroquias(cantonSeleccionado: string, provinciaSeleccionada: string){
    this.parroquias = this.datos.filter(canton =>
      canton.Cantón === cantonSeleccionado && provinciaSeleccionada
    );
    return this.cantones.map( parroquia =>
      parroquia.Parroquia
    );
  }

  getLibros(): Libro[]{
    return this.datosPrueba as Libro[];
  }

}
