<div id="carousel-example-generic" class="carousel slide" data-ride="carousel">
  
  <!-- Wrapper for slides -->
  <div class="carousel-inner" role="listbox">
    <div class="item active">
      <img src="/assets/img/ilustracion_magicas.jpg" alt="Magicas travesuras" style="width: 73%; height: 100%; display: block; margin: auto;">
      <div class="carousel-caption">
        
      </div>
    </div>
    <div class="item">
      <img src="/assets/img/ilustracion_leo_leon.jpg" alt="Leo Leon" style="width: 73%; height: 100%; display: block; margin: auto;">
      <div class="carousel-caption">
        
      </div>
    </div>
    <div class="item">
      <img src="/assets/img/Foto.png" alt="slider02" style="width: 34.25%; height: 100%; display: block; margin: auto;">
      <div class="carousel-caption">
        
      </div>
    </div>
    <div class="item">
      <img src="/assets/img/recom8.jpg" alt="slider001" style="width: 34.25%; height: 100%; display: block; margin: auto;">
      <div class="carousel-caption">
        
      </div>
    </div>
    <div class="item">
      <img src="/assets/img/Preparatoria.jpg" alt="slider03" style="width: 34.25%; height: 100%; display: block; margin: auto;">
      <div class="carousel-caption">
        
      </div>
    </div>
    <div class="item">
      <img src="/assets/img/slider03.jpg" alt="slider04" style="width: 34.25%; height: 100%; display: block; margin: auto;">
      <div class="carousel-caption">
        
      </div>
    </div>
    <div class="item">
      <img src="/assets/img/slider02.jpg" alt="slider05" style="width: 34.25%; height: 100%; display: block; margin: auto;">
      <div class="carousel-caption">
        
      </div>
    </div>
    <div class="item">
      <img src="/assets/img/slider05.jpg" alt="slider05" style="width: 34.25%; height: 100%; display: block; margin: auto;">
      <div class="carousel-caption">
        
      </div>
    </div>
  </div><br>
  <!-- Indicators -->
  <div class="row" style="background-color: rgba(165, 165, 165, 0); opacity: 0.9;">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src="/assets/img/ilustracion_leo_leon.jpg" alt="slidesig" style="width:16%;  height: 9.5em; border-radius: 10px; border: 2px solid #ffffff;"  data-target="#carousel-example-generic" data-slide-to="1" class="active"id="c1">&nbsp;
    <img src="/assets/img/Foto.png" alt="slidesig" style="width:10%;  height: 8%; border-radius: 10px; border: 2px solid #ffffff;"  data-target="#carousel-example-generic" data-slide-to="2"id="c1">&nbsp;
    <img src="/assets/img/recom8.jpg" alt="slidesig" style="width:10%;  height: 8%; border-radius: 10px; border: 2px solid #ffffff;"  data-target="#carousel-example-generic" data-slide-to="3"id="c1">&nbsp;
    <img src="/assets/img/Preparatoria.jpg" alt="slidesig" style="width:10%;  height: 8%; border-radius: 10px; border: 2px solid #ffffff;"  data-target="#carousel-example-generic" data-slide-to="4"id="c1">&nbsp;
    <img src="/assets/img/slider03.jpg" alt="slidesig" style="width:10%;  height: 8%; border-radius: 10px; border: 2px solid #ffffff;"  data-target="#carousel-example-generic" data-slide-to="5"id="c1">&nbsp;
    <img src="/assets/img/slider02.jpg" alt="slidesig" style="width:10%;  height: 8%; border-radius: 10px; border: 2px solid #ffffff;"  data-target="#carousel-example-generic" data-slide-to="6"id="c1">&nbsp;
    <img src="/assets/img/slider05.jpg" alt="slidesig" style="width:10%;  height: 8%; border-radius: 10px; border: 2px solid #ffffff;"  data-target="#carousel-example-generic" data-slide-to="6"id="c1">&nbsp;
    <img src="/assets/img/ilustracion_magicas.jpg" alt="slidesig" style="width:16%;  height: 9.5em; border-radius: 10px; border: 2px solid #ffffff;"  data-target="#carousel-example-generic" data-slide-to="0" class="active"id="c1">
  </div>
  <!-- Controls -->
  <a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev" style="width: 5%;">
    <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next" style="width: 5%;">
    <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
