<div class="row">
	<app-side-bar></app-side-bar>
    <div class="col-sm-9">
        <h1 style="text-align: center;">Ingreso de nuevo Kit</h1>
		<div class="panel panel-default">
			<div class="panel-heading">Formulario de información</div>
			<div class="panel-body">
				<div class="col-md-9">
					<form role="form">

						<div class="form-group">
							<label>Nombre kit</label>
							<input class="form-control" id="nombrekit" placeholder="Ingrese el título del kit">
							<label>Precio</label>
							<input class="form-control"  id="preciokit" placeholder="Ingrese el precio">
              <label>Descripción</label>
							<textarea class="form-control" rows="3" id="descripcionkit"></textarea>
            </div>
            <hr>
            <h3>Elegir los libros que pertenecen al nuevo kit:</h3>
						<br>
						<label>Seleccione una Colección</label>
							<select class="form-control" (change)="onChangecoleccion($event.target.value)">
                <option hidden selected>Seleccione una Colección</option>
                <option *ngFor = 'let coleccion of colecciones' [value]="coleccion?.idcolecion">{{coleccion.nombre}}</option>
							</select>
						<br>
						<label>Seleccione una Categoría</label>
							<select class="form-control" (change)="onChangecategoria($event.target.value)" placeholder="Selecione una Categoria">
                <option *ngFor = 'let categoria of categorias' [value]="categoria?.idcategoria">{{categoria.nombre}}</option>
								<option hidden selected> Elija una categoría</option>
							</select>
						<br>
						<label>Seleccione los libros del kit</label>
							<select class="form-control"  placeholder="Selecione un Libro">
                <option *ngFor = 'let lib of libs' [value]="lib?.idlibro" (click)="selecionlibro(lib)">{{lib.titulo}}</option>
								<option hidden selected> Elija un Libro</option>
							</select>
						<hr>
            <h3>Elegir los recursos(archivos) que pertenecen al nuevo kit:</h3>
						<br>
							<select class="form-control" placeholder="Selecione un Video">
                <option *ngFor = 'let archivo of archivos' [value]="archivo?.idarchivo" (click)="selecionarchivo(archivo)">{{archivo.nombre}}</option>
								<option hidden selected> Elija un Archivo</option>
							</select>
						<br>
						<hr>
            <h3>Elegir el grupo de videos que pertenecen al kit:</h3>
							<select class="form-control" placeholder="Selecione un Video">
                <option *ngFor = 'let video of grupovideos' [value]="video" (click)="selecionvideo(video)">{{video}}</option>
								<option hidden selected> Elija una grupo de videos</option>
							</select>
						<br>
						<hr>
						<h3>Tabla de contenido del kit</h3>
						<div class="table-responsive">
							<table class="table" >
								<tr>
									<td>
										Nombre
									</td>
									<td>
										Tipo
									</td>
									<td>
										Quitar
									</td>
								</tr>
								<tr *ngFor = 'let lib of librosseleccionados; let ind = index;'>
									<td>{{lib.titulo}}</td>
									<td>Libro</td>
									<td><a><img src="/assets/img/edit.png" (click)="quitarlibro(ind)"> </a></td>
								 </tr>
								 <tr *ngFor = 'let archivo of archivosseleccionados; let ind = index;'>
									<td>{{archivo.nombre}}</td>
									<td>Archivo</td>
									<td><a><img src="/assets/img/edit.png" (click)="quitararchivo(ind)"> </a></td>
								 </tr>
								 <tr *ngFor = 'let video of grupovideosseleccionados; let ind = index;'>
									<td>{{video}}</td>
									<td>Grupo videos</td>
									<td><a><img src="/assets/img/edit.png" (click)="quitarvideo(ind)"> </a></td>
								 </tr>
							</table>
						</div>

					<div class="row">
						<div class="col-md-3 col-md-offset-3"></div>
						<div class="col-md-3 col-md-offset-3"><button type="button" class="btn btn-lg btn-danger" (click)="guardarCambios()" routerLink="/saled/kits">Guardar cambios</button></div>
					 </div>
					</form>
				</div>
			</div>
		</div>
