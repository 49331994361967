import { UsuariosComponent } from './usuarios/usuarios.component';
import { AdminGuard } from './../../guardianes/admin.guard';
import { AuthGuard } from './../../guardianes/auth.guard';
import { BooksComponent } from './books/books.component';
import { KitsComponent } from './kits/kits.component';
import { OrdenesComponent } from './ordenes/ordenes.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { NewbookComponent } from './newbook/newbook.component';
import { NewkitComponent } from './newkit/newkit.component';
import { NewcolComponent } from './newcol/newcol.component';
import { NewcatComponent } from './newcat/newcat.component';
import { NewuserComponent } from './newuser/newuser.component';
import { VideosComponent } from './videos/videos.component';
import { ArchivosComponent } from './archivos/archivos.component';
import { NewvideoComponent } from './newvideo/newvideo.component';
import { NewarchivoComponent } from './newarchivo/newarchivo.component';
import { AsignarComponent } from './asignar/asignar.component';

const routes: Routes = [
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'ordenes', component: OrdenesComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'kits', component: KitsComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'books', component: BooksComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'usuarios', component: UsuariosComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'admin', component: AdminComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'newcol', component: NewcolComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'newcol/:id', component: NewcolComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'newkit', component: NewkitComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'newcat', component: NewcatComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'newcat/:id', component: NewcatComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'newuser', component: NewuserComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'newuser/:id', component: NewuserComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'newvideo', component: NewvideoComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'newvideo/:id', component: NewvideoComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'newarchivo', component: NewarchivoComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'newarchivo/:id', component: NewarchivoComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'newbook', component: NewbookComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'videos', component: VideosComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'archivos', component: ArchivosComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'asignar', component: AsignarComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'newbook/:id', component: NewbookComponent, canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: '', redirectTo: 'admin', canActivate: [AuthGuard, AdminGuard], pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
