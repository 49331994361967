<div class="container">
<div class="row">
  <div class="col-sm-11">
    <div class="form-group">
      <br>
        <input type="text" class="form-control" placeholder="Buscar por titulo..." [(ngModel)]="listFilter">
      </div>   
  </div>
  <div class="col-sm-1">
    
      <a style="text-decoration: none;" class="fa fa-search fa-2x"></a>
  </div>
  <!-- /.col-lg-6 -->
  <!-- <div class="col-lg-9 col-md-9">
    <div class="dropdown">
      <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
        Filtrar
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
        <li><a href="#">Leo Leon</a></li>
        <li><a href="#">Mágicas travesuras</a></li>
        <li><a href="#">Otra coleccion</a></li>
        <li role="separator" class="divider"></li>
        <li><a href="#">Separated link</a></li>
      </ul>
    </div>
  </div> -->
</div><!-- /.row -->
<br>
<div class="row">
<div class="col" style="font-family: Comic Sans MS, Comic Sans, cursive;">
  <h2 style="text-align: center;">Listado de libros</h2>
  <div class="col-md-6" *ngFor="let bookOutstanding of filteredBooks; let ind = index">
    <div class="thumbnail" *ngIf="bookOutstanding.destacado">
      <div style="text-align: center; font-family: Comic Sans MS, Comic Sans, cursive;">
        <p style="font-family: Comic Sans MS, Comic Sans, cursive;">{{bookOutstanding.titulo}}</p>
      </div>
      <img [src]="bookOutstanding.urlimagen" alt="{{bookOutstanding.titulo}}" width="200px" class="card-image">
      <div class="caption">
        <p style="font-family: Comic Sans MS, Comic Sans, cursive;"><strong>Autores: </strong>{{bookOutstanding.autores}}</p>
        <p><strong>Tipo: </strong>{{bookOutstanding.tipo}}</p>
        <p><strong>Rango Edad: </strong>{{bookOutstanding.rangoedad}}</p>
        
        <h4 style="font-family: cursive;"><strong>Precio aaa: {{bookOutstanding.precio | currency}}</strong></h4>
        
      </div>
      <div class="buttons">
        <a [routerLink]="['/product',ind+1]" class="btn btn-default" role="button">Detalles</a>
        <h5>Añadir al carrito:</h5>
        <button class="btn btn-primary" type="button" (click)="agregarAlCarrito(bookOutstanding?.idlibro, 'Fisico')">Fisico</button>
        <button class="btn btn-success" type="button" (click)="agregarAlCarrito(bookOutstanding?.idlibro, 'Digital')">Digital</button>
      </div>
    </div>
</div>
</div>
<div class="col-md-12">
  
  <div class="col-lg-4 col-md-4" style="min-height: 530px;" *ngFor="let book of pageOfItems; let i = index">
    <div class="thumbnail" style="margin: 0;font-family: 'Inter', sans-serif; opacity: 0.9;">
      <div style="text-align: center;">
        <p><strong>{{book?.titulo}}</strong></p>
      </div>
      <img [src]="book.urlimagen" alt="{{book?.titulo}}" width="200px" class="card-image" style="max-height: 135px;">
      <div class="caption">
        <p><strong>Autores: </strong>{{book?.autores}}</p>
        <p><strong>Tipo: </strong>{{book?.tipo}}</p>
        <p><strong>Rango Edad: </strong>{{book?.rangoedad}}</p>
        
      </div>
      <div class="buttons">
        <a [routerLink]="['/product',book?.idlibro]" class="btn btn-default" role="button">Detalles</a>
        <h5>Añadir al carrito:</h5>
        <button class="btn btn-primary" type="button" (click)="agregarAlCarrito(book?.idlibro, 'Fisico')">Fisico</button>
        <button class="btn btn-success" type="button" (click)="agregarAlCarrito(book?.idlibro, 'Digital')">Digital</button>
      </div>
    </div>
  </div>
</div>
</div>
<div class="row" style="text-align: center;">
  <app-paginator [items]="filteredBooks" (changePage)="onChangePage($event)" [maxPages]="5" [pageSize]="6"></app-paginator>
</div>
</div>
<div class="red">
  <a class="fa fa-whatsapp" href="https://api.whatsapp.com/send/?phone=593967776678" target="_blank"></a>
</div>