//import { Component, OnInit } from '@angular/core';
import { Institucion } from './../../modulos/institucion.model';
import { Contactanos } from './../../modulos/contactanos.model';
import { ContactarService } from './../../servicios/contactar.servise';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ConfirmedValidator } from '../confirmed.validator';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-contactanos',
  templateUrl: './contactanos.component.html',
  styleUrls: ['./contactanos.component.scss']
})
export class ContactanosComponent implements OnInit {

  contactanos: Contactanos;
  userForm: FormGroup;
  erorMessage: string;

  
  constructor(private contactarService: ContactarService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      Nombreremitente: new FormControl('', [Validators.required]),
      Correoremitente: new FormControl('', [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      Mensajebody: new FormControl('', [Validators.required, Validators.minLength(8)]),
      });

 }
 get CorreoRemitente(){
  return this.userForm.get('Correoremitente');
}

get Nombreremitente(){
  return this.userForm.get('Nombreremitente');
}

get Mensajebody(){
  return this.userForm.get('Mensajebody');
}

get f() { return this.userForm.controls; }

enviar(){
  this.contactanos = this.userForm.getRawValue() as Contactanos;
  this.contactarService.setCorreo(this.contactanos).subscribe({
    next: response => {
        this.contactanos = response[0]?.json;
        //this.institucion = institucion;
        console.log(this.contactanos);
        alert('Su mensaje ha sido enviado. Gracias por comunicarte con Salazar Editores');
        this.userForm.reset();
    },
    error: err => {
      this.erorMessage = err;
      console.log(this.erorMessage);
      //return 0;
      
    }
  });
  
}

}

