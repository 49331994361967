import { Observable, throwError } from 'rxjs';
import { Institucion } from './../modulos/institucion.model';
import { Usuario } from './../modulos/user.model';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseURL } from './baseUrl';
import { catchError, map, tap } from 'rxjs/operators';
import { Servicio } from '../modulos/servicio.model';

@Injectable({
  providedIn: 'root'
})
export class RegistroService {

  headers: HttpHeaders;
  usuario: Usuario;
  institucion: Institucion = {
    idinstitucion: 0,
    nombre: ' ',
    provincia: ' ',
    canton: ' ',
    parroquia: ' '
  };
  message: string;
  constructor(private http: HttpClient) { }

  setUser(usuario: Usuario): Observable<any>{
    this.usuario = usuario;
    /*let body = new HttpParams()
      .set('nombres', usuario.nombres)
      .set('apellidos', usuario.apellidos)
      .set('correo', usuario.correo)
      .set('contrasena', usuario.contrasena)
      .set('direccion', usuario.direccion)
      .set('telefono', usuario.telefono)
      .set('fechanacimiento', usuario.fechanacimiento)
      .set('tipo', usuario.tipo)
      .set('habilitado', String(usuario.habilitado))
      .set('listaservicio', usuario.listaservicios)
      .set('nivel', usuario.nivel)
      .set('idinstitucion', usuario.idinstitucion.toString())
      ;*/
    let body = {
      nombres: usuario.nombres,
      apellidos: usuario.apellidos,
      correo: usuario.correo,
      contrasena: usuario.contrasena,
      direccion: usuario.direccion,
      telefono: usuario.telefono,
      fechanacimiento: usuario.fechanacimiento,
      tipo: usuario.tipo,
      habilitado: usuario.habilitado,
      listaservicio: usuario.listaservicios,
      nivel: usuario.nivel,
      idinstitucion: usuario.idinstitucion
    };
    console.log(body);

    this.headers = new HttpHeaders({
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'Content-Type': 'application/json',
    });
    return this.http.post(baseURL + 'registro/registroUsuario', body, {headers: this.headers}).
      pipe(
        map((res: HttpResponse<any>) => {
          let jsonObject: any;
          if (res.status === 302) {
            this.message = 'El correo ya ha sido utilizado por otro usuario';
          } else if (res.status === 500){
            this.message = 'Algo salió mal! intenta de nuevo';
          } else if (res.status === 200){
            jsonObject = res.body;
            this.message = 'Registro exitoso, por favor inicia sesión con el botón Ingresar!!';
          } else if (res.status === 204){
            this.message = 'Ocurrió un error inesperado';
          }
          alert(this.message);
          return [{ status: res.status, json: jsonObject}];
        }),
        catchError(this.handleError)
      );
  }

  setInstitucion(institucion: Institucion){
    this.institucion = institucion;
    /*let body = new HttpParams()
    .set('nombre', this.institucion.Nombre.toUpperCase())
    .set('provincia', this.institucion.Provincia)
    .set('canton', this.institucion.Canton)
    .set('parroquia', this.institucion.Parroquia)
    ;*/

    let body = {
      nombre: this.institucion?.nombre.toUpperCase(),
      provincia: this.institucion?.provincia,
      canton: this.institucion?.canton,
      parroquia: this.institucion?.parroquia
    };
    console.log(body);
    this.headers = new HttpHeaders({
      // "Content-Type": "application/x-www-form-urlencoded",
      'Content-Type': 'application/json',
    });
    return this.http.post<any>(
      baseURL + 'registro/registroInstitucion',
      body,
      {
        headers: this.headers,
        observe: 'events'
        //responseType: 'json'
      }).
      pipe(
        map((res: HttpResponse<any>) => {
          let jsonObject: any;
          if (res.status === 204) {
            this.message = 'no se encontró la institución';
          } else if (res.status === 500){
            this.message = 'Algo salió mal!';
          } else if (res.status === 201 || res.status === 200){
            jsonObject = res.body;
            this.message = 'Registro exitoso, por favor inicia sesión con el botón Ingresar!!';
          } else{
            this.message = 'Ocurrió un error inesperado';
          }
          console.log(this.message);
          return [{ status: res.status, json: jsonObject}];
        }),
        catchError(this.handleError)
      );
  }

  // validarCorreo(correo: string){
  //   this.usuario.Correo = correo;
  //   let body = new HttpParams()
  //   .set('Correo', correo.toString());
  //   this.headers = new HttpHeaders({
  //     "Content-Type": "application/x-www-form-urlencoded",
  //     //"Content-Type": "application/json",
  //   });
  //   return this.http.post(baseURL + 'registro/registroInstitucion', body.toString(), {headers: this.headers}).
  //     pipe(
  //       tap(data => console.log("All:" + JSON.stringify(data))),
  //       catchError(this.handleError)
  //     );
  // }

  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
//esto se añadió
  eliminarUser(usuario: Usuario): Observable<any>{
    this.usuario = usuario;
    console.log(usuario);
    this.headers = new HttpHeaders ({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<any>(baseURL + 'usuario/eliminarusuario', usuario, {headers: this.headers, observe: 'response'}).
      pipe(
        map((res: HttpResponse<any>) => {
          let jsonObject: any;
          if (res.status === 204) {
            //codigo invalido
            jsonObject = false;
          } else if (res.status === 200){
            jsonObject = res.body;
          }
          return [{ status: res.status, json: jsonObject}];
        }),
        catchError(this.handleError)
      );
  }

  modificarUsuario(usuario: Usuario): Observable<any>{
    this.headers = new HttpHeaders ({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
    });
    return this.http.post<Servicio[]>(baseURL + 'usuario/modificarusuario', usuario, {headers: this.headers, observe: 'response'}).
      pipe(
        map((res: HttpResponse<any>) => {
          let jsonObject: any;
          if (res.status === 204) {
            //codigo invalido
            jsonObject = false;
          } else if (res.status === 200){
            jsonObject = res.body;
          }
          return [{ status: res.status, json: jsonObject}];
        }),
        catchError(this.handleError)
      );
  }
}

