import { baseURL } from './baseUrl';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap} from 'rxjs/operators';
import { service } from 'aws-sdk/clients/health';
import { Servicio } from '../modulos/servicio.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class kitService {
  headers: HttpHeaders;
  /*private SERVER_URL = environment.SERVER_URL;*/
  constructor(private http: HttpClient) { }
    /*Obtener los libros/productos del back*/
    getAllkits(): Observable<Servicio[]>{
      return this.http.get<Servicio[]>(baseURL + 'ServicioGenerales/listarkits')
      .pipe(
        tap(
          data => console.log('Kits cargados exitosamente')
          //console.log("All:" + JSON.stringify(data))
        ),
        catchError(this.handleError)
      );
    }

    getkit(id: number): Observable<Servicio> {
      this.headers = new HttpHeaders ({
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
      });
      return this.http.post<Servicio>(baseURL + `AgregarServicios/buscarkitid?idservicio=${id.toString()}`, null, { headers: this.headers});

    }

    private handleError(err: HttpErrorResponse) {
      // in a real world app, we may send the server to some remote logging infrastructure
      // instead of just logging it to the console
      let errorMessage = '';
      if (err.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        errorMessage = `An error occurred: ${err.error.message}`;
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
      }
      console.error(errorMessage);
      return throwError(errorMessage);
    }
}
