import { Libro } from './../modulos/libro.model';
import { baseURL } from './baseUrl';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap} from 'rxjs/operators';
import { Coleccion } from '../modulos/coleccion.model';
import { Categoria } from '../modulos/categoria.model';
import { Servicio } from '../modulos/servicio.model';


/*const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};*/

@Injectable({
  providedIn: 'root'
})
export class ColeccionService {

  headers: HttpHeaders;

  /*private SERVER_URL = environment.SERVER_URL;*/
  constructor(private http: HttpClient) { }
    /*Obtener los libros/productos del back*/
    getAllColeccion(): Observable<Coleccion[]>{
      return this.http.get<Coleccion[]>(baseURL + 'ServicioGenerales/listarcolecciones')
      .pipe(
        tap(data =>
          // console.log("All:" + JSON.stringify(data))
          console.log("Todas las colecciones cargadas")
        ),
        catchError(this.handleError)
      );
    }

    getColeccion(id: number): Observable<Coleccion> {
      return this.http.post<Coleccion>(baseURL + `ServicioGenerales/buscarcoleccionid?idcoleccion=${id.toString()}`, null)
      .pipe(
        tap(data => console.log("Todos los datos llegaron")),//JSON.stringify(data)
        catchError(this.handleError));
      /*return this.getAllColeccion()
        .pipe(
          map((coleccion: Coleccion[]) => coleccion.find(p => p.Idcoleccion === id))
        );*/
    }

    getCategoria(id: number): Observable<Categoria> {
      return this.http.post<Categoria>(baseURL + `ServicioGenerales/buscarcategoriaid?idcategoria=${id.toString()}`, null)
      .pipe(
        tap(data => {
          // console.log("All:" + JSON.stringify(data))
          console.log("todas las categorias llegaron");
        }),
        catchError(this.handleError));

    }

    modificarCategoria(categoria: Categoria): Observable<any> {
      this.headers = new HttpHeaders ({
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
      });
      return this.http.post<any>(baseURL + `CRUDLibro/modificarcategoria`, categoria, {headers: this.headers, observe: 'response'})
      .pipe(
        tap(data => console.log("Response" + JSON.stringify(data))),
        catchError(this.handleError));
    }

    eliminarCategoria(categoria: Categoria): Observable<any> {
      this.headers = new HttpHeaders ({
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
      });
      return this.http.post<any>(baseURL + `CRUDLibro/eliminarcategoria`, categoria, {headers: this.headers, observe: 'response'})
      .pipe(
        tap(data => console.log("Response" + JSON.stringify(data))),
        catchError(this.handleError));
    }

    ingresarCategoria(categoria: Categoria): Observable<any> {
      this.headers = new HttpHeaders ({
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
      });
      return this.http.post<any>(baseURL + `CRUDLibro/ingresarcategoria`, categoria, {headers: this.headers, observe: 'response'})
      .pipe(
        tap(data => console.log("Response" + JSON.stringify(data))),
        catchError(this.handleError));
    }

    getCategorias(id: number): Observable<Categoria[]> {
      return this.http.post<Categoria[]>(baseURL + `ServicioGenerales/buscarcategoriaidcoleccion?idcoleccion=${id.toString()}`, null)
      .pipe(
        tap(data => {
          // console.log("All:" + JSON.stringify(data))
          console.log("Llegaron todas las categorias");
        }),
        catchError(this.handleError));
    }

    NuevaColeccion(coleccion: Coleccion): Observable<any>{
      this.headers = new HttpHeaders ({
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
      });
      return this.http.post<Servicio[]>(baseURL + 'CRUDLibro/ingresarcoleccion', coleccion ,{headers: this.headers, observe: 'response'}).
        pipe(
          map((res: HttpResponse<any>) => {
            let jsonObject: any;
            if (res.status === 204) {
              //codigo invalido
              jsonObject = false;
            } else if (res.status === 200){
              jsonObject = res.body;
            }
            return [{ status: res.status, json: jsonObject}];
          }),
          catchError(this.handleError)
        );
    }

    ModificarColeccion(coleccion: Coleccion): Observable<any>{
      this.headers = new HttpHeaders ({
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
      });
      return this.http.post<Servicio[]>(baseURL + 'CRUDLibro/modificarcoleccion', coleccion, {headers: this.headers, observe: 'response'}).
        pipe(
          map((res: HttpResponse<any>) => {
            let jsonObject: any;
            if (res.status === 204) {
              //codigo invalido
              jsonObject = false;
            } else if (res.status === 200){
              jsonObject = res.body;
            }
            return [{ status: res.status, json: jsonObject}];
          }),
          catchError(this.handleError)
        );
    }

    eliminarColeccion(coleccion: Coleccion): Observable<any>{
      this.headers = new HttpHeaders ({
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user'))?.token
      });
      return this.http.post<Servicio[]>(baseURL + 'CRUDLibro/eliminarcoleccion', coleccion, {headers: this.headers, observe: 'response'}).
        pipe(
          map((res: HttpResponse<any>) => {
            let jsonObject: any;
            if (res.status === 204) {
              //codigo invalido
              jsonObject = false;
            } else if (res.status === 200){
              jsonObject = res.body;
            }
            return [{ status: res.status, json: jsonObject}];
          }),
          catchError(this.handleError)
        );
    }



    private handleError(err: HttpErrorResponse) {
      // in a real world app, we may send the server to some remote logging infrastructure
      // instead of just logging it to the console
      let errorMessage = '';
      if (err.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        errorMessage = `An error occurred: ${err.error.message}`;
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
      }
      console.error(errorMessage);
      return throwError(errorMessage);
    }
}
