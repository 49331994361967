<!--encabezado de la coleccion-->
<div class="row" style= "margin-right: 8px; margin-left: 8px; margin-top: 1%; margin-bottom: 3%;">
  <!--
  <p style="color: rgb(62, 62, 95); text-shadow: 7px 4px 5px gray; font-family: Comic Sans MS, Comic Sans, cursive;">
    {{coleccion?.nombre}}
  </p>
  -->
  <img [src]= 'coleccion?.urlimagen' [alt]="coleccion?.nombre" style="width: 100%; height: 70%;">
  <div style= "margin-right: 15px; margin-left: 15px; margin-top: 1%;">
    <p style="font-size: x-large; text-align: justify; font-family: Comic Sans MS, Comic Sans, cursive">{{coleccion?.descripcion}}</p>
  </div>
    
    <br>
    <div class="col-md-12" style="margin-bottom: 5%; margin-top: 3%;">
              <h1 style="text-align: center; font-family: Comic Sans MS, Comic Sans, cursive">Categorías</h1>
              <section id="hero">
                <div class="hero-container" data-aos="zoom-in" data-aos-delay="100">
                    
                </div><br><br>
                <div class="col-md-4 col-sm-3" *ngFor="let categoria of coleccion?.categorias; let i = index">
                  <div class="thumbnail" style="opacity: 0.9; background-color: aliceblue;" >
                    <div style="text-align: center;">
                      <h4 style="font-family: Comic Sans MS, Comic Sans, cursive"><strong>{{categoria.nombre}}</strong></h4>
                    </div>
                    <img [src]="categoria.urlimagen" alt="{{categoria.nombre}}" width="200px" class="card-image" style="max-height: 135px;">
                    <div class="buttons">
                      <a [routerLink]="['/catag',categoria?.idcategoria]" class="btn btn-default btn-lg btn-block" role="button" [style.background-color]="categoria?.color"><strong>Mostrar libros</strong></a>
  
                    </div>
                  </div>
                </div>
              </section>
              
            </div>
          <br>
          <div class="container" style="margin-top: 3%;">
            <h1 style="text-align: center; margin-bottom: 1%; margin-top: 3%; font-family: Comic Sans MS, Comic Sans, cursive">Catálogo</h1>
            
            <div class="col-6">
              <ngx-extended-pdf-viewer
                        [src]="coleccion?.urlpdfcatalogo"
                        [height]="'900px'"
                        [useBrowserLocale]="true"
                        [textLayer]="false"
                        [showHandToolButton]="true"
                        [showDownloadButton]="false"
                        [showPrintButton]="false"
                        [showOpenFileButton]="false"
                        [showPresentationModeButton]="true">
                              </ngx-extended-pdf-viewer>
        
            </div>
                    
          </div>
          <br><br><br>
  </div>
  
  <div class="red">
    <a class="fa fa-whatsapp" href="https://api.whatsapp.com/send/?phone=593967776678" target="_blank"></a>
  </div>