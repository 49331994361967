<div class="row">
	<app-side-bar></app-side-bar>
    <div class="col-sm-9">
		<h1 style="text-align: center;">Listado de Videos</h1>
		<div class="row">
			<div class="col-md-11 col-md-offset-11">
				<a routerLink="/saled/newvideo"><button type="button" class="btn btn-md btn-primary">Añadir</button></a>
				&nbsp;&nbsp;&nbsp;<a><button type="button" class="btn btn-md btn-success" (click)="refrescararchivos()">Refrescar</button></a>
			</div>
        <div class="col-md-4">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Buscar..." [(ngModel)]="listFilter">
                <span class="input-group-btn">
                </span>
          </div>
        </div>
      </div>
      <br>
		<div class="table-responsive">
			<table class="table">
				<tr>
					<td>
						Nombre del video
					</td>
					<td>
						Nombre del libro
					</td>
					<td>
						Página
					</td>
					<td>
                        Editar/Eliminar
                    </td>
				</tr>
				<tr *ngFor = 'let video of videosFiltered; let i = index'>
					<td>{{video.nombre}}</td>
					<td>{{video.nombrelibro}}</td>
					<td>{{video.pagina}}</td>
					<td><a><img src="/assets/img/delete.png" [routerLink]="['/newvideo',video.idvideo]" > </a><a><img src="/assets/img/edit.png" (click)="eliminarVideo(video)"></a></td>
				 </tr>
			</table>
		  </div>
	</div><!--/.main-->
</div>
