import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/servicios/product.service';
import { ColeccionService } from 'src/app/servicios/coleccion.service';
import { Coleccion } from 'src/app/modulos/coleccion.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Categoria } from 'src/app/modulos/categoria.model';
import { AdministkitService } from 'src/app/servicios/administkit.service';
import { Servicio } from 'src/app/modulos/servicio.model';
import { Service } from 'aws-sdk';
 

@Component({
  templateUrl: './kits.component.html',
  styleUrls: ['./kits.component.scss']
})
export class KitsComponent implements OnInit {

  
  servicios: Servicio[] = [];
  eliminado = false;
  
  constructor(private administkitServicio: AdministkitService) { }
  
  ngOnInit(): void {
    this.getKits();
  }

  refrescararchivos(){
    this.getKits();
  }

  getKits(){
    this.administkitServicio.pedirkits().subscribe(
      res => {
        if(res[0].json != undefined){
          this.servicios = res[0].json;
        }
      },
      err => console.log(err)
    )
  }

  quitarkit(kit: Servicio){
    this.administkitServicio.eliminarKit(kit).subscribe(
      res => {
        if(res[0].json != undefined){
          this.eliminado = res[0].json;
          if(this.eliminado === true){
            alert("kit eliminado con éxito.");
            this.eliminado = false;
            this.getKits();
          }else{
            alert("No se ha podido eliminar");
          }
        }
      }
    )
  }
}
